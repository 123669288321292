import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {Injectable, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule, SETTINGS} from '@angular/fire/firestore';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InputTextModule} from 'primeng/inputtext';
import {PasswordModule} from 'primeng/password';
import {ButtonModule} from 'primeng/button';
import {DragDropModule} from 'primeng/dragdrop';
import {TooltipModule} from 'primeng/tooltip';
import {BlockUIModule} from 'primeng/blockui';
import {SpinnerModule} from 'primeng/spinner';
import {ToastModule} from 'primeng/toast';
import {PanelModule} from 'primeng/panel';
import {AppComponent} from './app.component';
import {DialogModule} from 'primeng/dialog';
import { PickListModule } from 'primeng/picklist';


import * as Hammer from 'hammerjs';
import {AppRoutingModule} from './app-routing.module';
import {SizeDetectorModule} from './common/size-detector/size-detector.module';
import {DepartmentsAndGroupsComponent} from './departments-and-groups/departments-and-groups.component';


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    // override hammerjs default configuration
    swipe: {direction: Hammer.DIRECTION_ALL},
  } as any;
}

@NgModule({
  declarations: [
    AppComponent,
    DepartmentsAndGroupsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.fs_conf),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    AngularFireFunctionsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    SpinnerModule,
    ToastModule,
    PanelModule,
    DragDropModule,
    TooltipModule,
    BlockUIModule,
    HammerModule,
    SizeDetectorModule,
    DialogModule,
    PickListModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: SETTINGS,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
