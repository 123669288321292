export class Ci18n {

  public static get lang(): string {
    this.tryInit();

    return this.allowedLangStr[this._langI];
  }
  public static readonly allowedLangStr: ReadonlyArray<string> = ['eng', 'ger'];
  private static _langI: number = undefined;
  private static _defaultLangI = 0; /*en: 0; ger: 1;*/
  private static readonly _allowedLangI: ReadonlyArray<number> = [0, 1];
  private static _textTable: ReadonlyArray<string> = [];

  private static readonly _engTable: ReadonlyArray<string> = [
    //#region 000-099
    /*000*/ 'Planboard',
    /*001*/ 'Profile',
    /*002*/ 'logout',
    /*003*/ 'Email Address',
    /*004*/ 'Password',
    /*005*/ 'Repeat Password',
    /*006*/ 'With your registration you agree with our ', // With your registration you agree with our ToS.
    /*007*/ 'terms and conditions',                                        // With your registration you agree with our ToS.
    /*008*/ '.',                                          // With your registration you agree with our ToS.
    /*009*/ 'Register Company Account',
    /*010*/ 'Reset Password',
    /*011*/ 'Back',
    /*012*/ 'Help',
    /*013*/ 'Please enter your email address',
    /*014*/ 'Please fill in all fields',
    /*015*/ 'Password must consist of at least 6 characters',
    /*016*/ 'Passwords do not match',
    /*017*/ 'The reset form has been sent to your email address. Please check your email inbox.',
    /*018*/ 'No account could be found for the stated email address.',
    /*019*/ 'This is not a valid email address',
    /*020*/ 'An unexpected error occurred.',
    /*021*/ 'Login',
    /*022*/ 'Register',
    /*023*/ 'Reset',
    /*024*/ 'Logout',
    /*025*/ 'User Data',
    /*026*/ 'Email Adr.',
    /*027*/ 'Change Email-Address',
    /*028*/ 'Change Password',
    /*029*/ 'Employee Data',
    /*030*/ 'Name',
    /*031*/ 'Employee ID',
    /*032*/ 'Department',
    /*033*/ 'Group',
    /*034*/ 'An unexpected error occurred. Please contact the administrator.',
    /*035*/ 'Edit',
    /*036*/ 'Cancel',
    /*037*/ 'Save',
    /*038*/ 'Old Password',
    /*039*/ 'New Password',
    /*040*/ 'New Email Address',
    /*041*/ 'Delete User',
    /*042*/ 'Delete user permanently?',
    /*043*/ 'No',
    /*044*/ 'Yes',
    /*045*/ 'OK',
    /*046*/ 'Email address is already taken',
    /*047*/ 'An error occurred',
    /*048*/ 'Error',
    /*049*/ 'Add Project',
    /*050*/ 'Search projects by name or number',
    /*051*/ 'Filter for reserved projects',
    /*052*/ 'Filter for projects with plan requirement',
    /*053*/ 'Filter out completed projects',
    /*054*/ 'Sort projects chronologically',
    /*055*/ 'Sort projects alphabetically',
    /*056*/ 'Sort projects by number',
    /*057*/ 'Note',
    /*058*/ 'Calendar Settings',
    /*059*/ 'Manage Layouts',
    /*060*/ 'Add New Employee',
    /*061*/ 'Number of employees',
    /*062*/ 'Select employees',  // plural
    /*063*/ 'Go one day back',
    /*064*/ 'Go one week back',
    /*065*/ 'Go one day forward',
    /*066*/ 'Go one week forward',
    /*067*/ 'Move employees to the left',
    /*068*/ 'Move employees to the right',
    /*069*/ 'Show less days',
    /*070*/ 'Show less weeks',
    /*071*/ 'Show more days',
    /*072*/ 'Show more weeks',
    /*073*/ 'Block-editing active',
    /*074*/ 'Single-editing active',
    /*075*/ 'Undo last change',
    /*076*/ 'Repeat last change',
    /*077*/ 'Jump to a specific date',
    /*078*/ 'Show four weeks',
    /*079*/ 'Jump to current week',
    /*080*/ 'Jump to current day',
    /*081*/ 'Show projects',
    /*082*/ 'Hide projects',
    /*083*/ 'column width',
    /*084*/ 'from',
    /*085*/ 'to',
    /*086*/ 'start of work',
    /*087*/ 'weekdays',
    /*088*/ 'font size',
    /*089*/ 'Number',
    /*090*/ 'Duration',
    /*091*/ 'Color',
    /*092*/ 'Category',
    /*093*/ 'Less Colors',
    /*094*/ 'More Colors',
    /*095*/ 'Project',
    /*096*/ 'note',  // project-note (wordwrap)
    /*097*/ 'Under Reserve',
    /*098*/ 'Edit Project',
    /*099*/ 'Under Reserve',
    //#endregion
    //#region 100-199
    /*100*/ 'Completed',
    /*101*/ 'Delete',
    /*102*/ 'Project Overview',
    /*103*/ 'Delete Project',
    /*104*/ 'delete project ',  // delete project XY permanently?
    /*105*/ ' permanently?',    // delete project XY permanently?
    /*106*/ 'Add Employee',
    /*107*/ 'Permissions of',   // permissions of XY
    /*108*/ 'this employee',    // permissions of this employee
    /*109*/ 'List of employees, which',     // Liste der Mitarbeiter, die XY einsehen oder bearbeiten kann
    /*110*/ 'this employee',             // Liste der Mitarbeiter, die dieser Mitarbeiter einsehen oder bearbeiten kann
    /*111*/ 'can read or edit',  // Liste der Mitarbeiter, die XY einsehen oder bearbeiten kann
    /*112*/ 'double request refused',  // plural
    /*113*/ 'employees selected', // plural
    /*114*/ 'unknown',
    /*115*/ 'read',
    /*116*/ 'edit',
    /*117*/ 'permissions to', // permissions to XY
    /*118*/ 'this employee',  // permissions to this employee
    /*119*/ 'list of employees, which can read or edit', // list of employees, which can see or edit XY
    /*120*/ 'this employee',                            // list of employees, which can see or edit this employee
    /*121*/ '',
    /*122*/ 'All employees',
    /*123*/ 'register as user',
    /*124*/ 'is user',
    /*125*/ 'Available',
    /*126*/ 'Selected',
    /*127*/ 'Search',
    /*128*/ 'Delete Employee',
    /*129*/ 'delete permanently?',
    /*130*/ 'Assign Project',
    /*131*/ 'start',
    /*132*/ 'end',
    /*133*/ 'all',
    /*134*/ 'total duration',
    /*135*/ 'add Day',
    /*136*/ 'Edit Project Assignments',
    /*137*/ 'from',
    /*138*/ 'to',
    /*139*/ 'No Assignment found',
    /*140*/ 'Show in calendar',
    /*141*/ 'edited',
    /*142*/ 'created',
    /*143*/ 'Select layout',
    /*144*/ 'Delete layout',
    /*145*/ 'Change layout name',
    /*146*/ 'Save layout name',
    /*147*/ 'Details',
    /*148*/ 'View',
    /*149*/ 'week-view',
    /*150*/ 'weeks',
    /*151*/ 'day-view',
    /*152*/ 'employees',
    /*153*/ 'Add layout',
    /*154*/ 'Change Date',
    /*155*/ 'Tips on Getting Started',
    /*156*/ 'That was the short introduction. If you have any questions or suggestions, please contact us at',
    /*157*/ 'or send an email to',
    /*158*/ 'During the test phase, the support is free for you! Our',
    /*159*/ 'FAQs',
    /*160*/ 'can also help with open questions.',
    /*161*/ 'You don\'t have right permissions for this employee',
    /*162*/ 'Assignments could not be removed',
    /*163*/ 'Assignments deleted',
    /*164*/ 'Successfully removed assignments!',
    /*165*/ 'Assignment could not be removed',
    /*166*/ 'Assignment Delete',
    /*167*/ 'Successfully removed assignment!',
    /*168*/ 'Undone',
    /*169*/ 'Reversed last change of assignment',
    /*170*/ 'Reversed last changes of assignments',
    /*171*/ 'Change restored',
    /*172*/ 'last change of assignment has been restored',
    /*173*/ 'Changes restored',
    /*174*/ 'last changes of assignments have been restored',
    /*175*/ 'An unexpected error occurred. Please try again later.',
    /*176*/ 'Assignment blocks will be edited collectively',
    /*177*/ 'Input could not be processed',
    /*178*/ 'Assignments will be edited separately',
    /*179*/ 'Name invalid',
    /*180*/ 'Maximum length is 20 characters',
    /*181*/ 'Layout Name Changed',
    /*182*/ 'Layout name changed to "', // Layout name changed to "XY"
    /*183*/ '"',                        // Layout name changed to "XY"
    /*184*/ 'Layout Deleted',
    /*185*/ 'Layout "',                     // Layout "XY" was removed permanently!
    /*186*/ '" was removed permanently!',   // Layout "XY" was removed permanently!
    /*187*/ 'Layout "',       // Layout "XY" was created
    /*188*/ '" was created',  // Layout "XY" was created
    /*189*/ 'Please enter a project name',
    /*190*/ 'Please enter a project number',
    /*191*/ 'Please enter the project duration',
    /*192*/ 'project name is too long',
    /*193*/ 'project number is too long',
    /*194*/ 'Project Added',
    /*195*/ 'Project "',                  // Project "XY" was created successfully
    /*196*/ '" was created successfully', // Project "XY" was created successfully
    /*197*/ 'Access Denied',
    /*198*/ 'Project is currently being edited by another user',
    /*199*/ 'edit permissions not received',
    //#endregion
    //#region 200-299
    /*200*/ 'Project is currently being edited',
    /*201*/ 'Project updated',
    /*202*/ 'Project "',                  // Project "XY" was successfully updated
    /*203*/ '" was successfully updated', // Project "XY" was successfully updated
    /*204*/ 'Project Deleted',
    /*205*/ 'Project has been deleted permanently!',
    /*206*/ '"Start" must not be scheduled after "End"',
    /*207*/ 'Assignments Updated',
    /*208*/ 'Project assignments have been changed',
    /*209*/ 'Assignment Updated',
    /*210*/ 'Project assignment has been changed',
    /*211*/ 'Assignments Added',
    /*212*/ 'Project assignments have been scheduled',
    /*213*/ 'Assignments Added',
    /*214*/ 'Project assignment has been scheduled',
    /*215*/ 'Please try again later',
    /*216*/ 'Please enter a name',
    /*217*/ 'Please enter an employee Id',
    /*218*/ 'Please enter an email address',
    /*219*/ 'Please enter a valid email address',
    /*220*/ 'The name is too long',
    /*221*/ 'The employee ID is to long',
    /*222*/ 'The department name is to long',
    /*223*/ 'The group name is to long',
    /*224*/ 'Employee Added',
    /*225*/ ' has been added successfully', // XY has been added successfully
    /*226*/ 'could not be loaded',
    /*227*/ 'Employee profile is currently being edited by another user',
    /*228*/ 'Email address of "',        // Email address of "XY" could not be determined
    /*229*/ '" could not be determined',  // Email address of "XY" could not be determined
    /*230*/ 'Employee not found',
    /*231*/ 'Employee Updated',
    /*232*/ ' has been successfully changed', // XY wurde erfolgreich geändert
    /*233*/ 'Deleting employee failed!',
    /*234*/ '1/4 Add New Employee',
    /*235*/ '2/4 Add New Project',
    /*236*/ '3/4 Assign Project To Employee',
    /*237*/ '4/4 Change Assignment',
    /*238*/ '1/3 Add New Project',
    /*239*/ '2/3 Assign Project To Employee',
    /*240*/ '3/3 Change Assignment',
    /*241*/ 'Login failed',
    /*242*/ 'Email address incorrect',
    /*243*/ 'Password incorrect',
    /*244*/ 'Too many requests on this account. Please contact the administrator.',
    /*245*/ 'Invalid email format',
    /*246*/ 'No employee profile available',
    /*247*/ 'An unexpected error occurred',
    /*248*/ 'employee profile not available',
    /*249*/ 'Logout failed',
    /*250*/ 'Password could not be changed',
    /*251*/ 'Email address could not be changed',
    /*252*/ 'An error occurred. Please contact the administrator.',
    /*253*/ 'Entry is outdated',
    /*254*/ 'Entries are outdated',
    /*255*/ 'Email address of the employee could not be determined',
    /*256*/ 'Email address is unknown',
    /*257*/ 'Email address could not be updated',
    /*258*/ 'Email address could not be deallocated',
    /*259*/ 'invalid input',
    /*260*/ 'Layout could not be deleted',
    /*261*/ 'Layout not available',
    /*262*/ 'Employee is not available',
    /*263*/ 'Employee unknown',
    /*264*/ 'Name already taken',
    /*265*/ 'Name is already taken',
    /*266*/ 'Only assignments by the same employee can be removed collectively',
    /*267*/ 'Only assignments by the same project can be removed collectively',
    /*268*/ 'Employee ID is already taken',
    /*269*/ 'Project can not be deleted as long as it is assigned to employees',
    /*270*/ 'Project is not available',
    /*271*/ 'Project could not be converted',
    /*272*/ 'Project not available',
    /*273*/ 'Project number is already taken',
    /*274*/ 'Assignment could not be created',
    /*275*/ 'Assignment is not available',
    /*276*/ 'Assignment not available',
    /*277*/ 'Assignments must not overlap',
    /*278*/ 'Assignments could not be created',
    /*279*/ 'Assignments could not be updated',
    /*280*/ 'Layout Added',
    /*281*/ 'Mo. - Fr.',
    /*282*/ 'Mo. - Sa.',
    /*283*/ 'Mo. - Su.',
    /*284*/ 'don\'t show again',
    /*285*/ 'd',
    /*286*/ 'h',
    /*287*/ 'min',
    /*288*/ 'ID',
    /*289*/ 'Close',
    /*290*/ 'Show Employees',
    /*291*/ 'Layouts',
    /*292*/ 'days',
    /*293*/ 'Mo-Su',
    /*294*/ 'Mo-Sa',
    /*295*/ 'Mo-Fr',
    /*296*/ 'edited',
    /*297*/ 'layout',
    /*298*/ 'Sick',
    /*299*/ 'Leave',
    //#endregion
    //#region 300-399
    /*300*/ 'Activate License',
    /*301*/ 'Activate',
    /*302*/ 'Company',
    /*303*/ 'VAT No.',
    /*304*/ 'Street',
    /*305*/ 'City',
    /*306*/ 'ZIP Code',
    /*307*/ 'State',
    /*308*/ 'Card',
    /*309*/ 'IBAN',
    /*310*/ 'SEPA',
    /*311*/ 'License Activated',
    /*312*/ 'Your license has been successfully activated.',
    /*313*/ 'Payment Method',
    /*314*/ 'Annual Subscription',
    /*315*/ 'Price Cacluclator',
    /*316*/ 'users',
    /*317*/ 'Your Price is:',
    /*318*/ 'month',
    /*319*/ 'Employee can not be deleted as long as the employee is assigned to projects.',
    /*320*/ 'Employee can not be deleted because the employee is a user.',
    /*321*/ 'Admin profile can not be deleted',
    /*322*/ 'Open dayview',
    /*323*/ 'Open weekview',
    /*324*/ 'Start is invalid',
    /*325*/ 'End is invalid',
    /*326*/ 'Assignment is currently being edited by another user',
    /*327*/ 'Scheduler',
    /*328*/ 'Scheduler can view projects and add new employees',
    /*329*/ 'own profile',
    /*330*/ 'activate',
    /*331*/ 'List of deactivated employees',
    /*332*/ 'Deactivated Employees',
    /*333*/ 'Edit start and end individually',
    /*334*/ 'Edit start and end in parallel',
    /*335*/ 'Disable employee',
    /*336*/ 'Deactivate',
    /*337*/ 'Block and take "',
    /*338*/ '" out of the planboard?',
    /*339*/ 'Deactivating employee failed!',
    /*340*/ 'Your account has been deactivated!',
    /*341*/ 'Project Archive',
    /*342*/ 'Archive Projects',
    /*343*/ 'Archive',
    /*344*/ 'Archive is empty',
    /*345*/ 'Restore',
    /*346*/ 'Overview',
    /*347*/ 'Project Archived',
    /*348*/ 'Project "',                          // Project "XY"  has been successfully archived.
    /*349*/ '" has been successfully archived.',  // Project "XY"  has been successfully archived.
    /*350*/ 'Filter for completed projects',
    /*351*/ 'Functionality not guaranteed!',
    /*352*/ 'Using our WebApp may be restricted with this Internet browser. For best use, we recommend using Chrome or Firefox.',
    /*353*/ 'Boss',
    /*354*/ 'Register Employee Account',
    /*355*/ 'Company name',
    /*356*/ 'First and last name',
    /*357*/ 'phone number',
    /*358*/ 'GDPR compliant',
    /*359*/ 'Secure transmission',
    /*360*/ 'Encrypted data',
    /*361*/ 'License expired!',
    /*362*/ 'Your license for the Digital Planning Board has expired. Purchase a license to edit your data.',
    /*363*/ 'License expires!',
    /*364*/ 'Your trial expires in ',
    /*365*/ ' days!',
    /*366*/ 'Your trial expires tomorrow!',
    /*367*/ 'Your trial expires today!',
    /*368*/ 'First Steps',
    /*369*/ 'Add employee',
    /*370*/ 'Add project',
    /*371*/ 'Create assignment',
    /*372*/ 'Move assignment',
    /*373*/ 'Delete assignment',
    /*374*/ 'Change assignment',
    /*375*/ 'Create assignment block',
    /*376*/ 'Move assignment block',
    /*377*/ 'Please provide an e-mail address that has been assigned to an employee',
    /*378*/ 'This e-mail address has not jet been assigned to an employee',
    /*379*/ 'Trial',
    /*380*/ 'Your trial is valid for ',
    /*381*/ ' days.',
    /*382*/ 'under reserve',
    /*383*/ 'Report',
    /*384*/ 'Zeit',
    /*385*/ 'Detaillierte Zeit',
    /*386*/ 'Diese Woche',
    /*387*/ 'Letzte Woche',
    /*388*/ 'Dieser Monat',
    /*389*/ 'Letzter Monat',
    /*390*/ 'Benutzerdefiniert',
    /*391*/ 'Zeitraum',
    /*392*/ 'Suche detaillierte Zeit',
    /*393*/ 'Stunden insgesamt',
    /*394*/ 'Alle Projekte',
    /*395*/ 'Hours excl. breaks',
    /*396*/ 'Projects',

    //#endregion
  ];
  private static readonly _gerTable: ReadonlyArray<string> = [
    //#region 000-099
    /*000*/ 'Plantafel',
    /*001*/ 'Profil',
    /*002*/ 'abmelden',
    /*003*/ 'E-Mail-Adresse',
    /*004*/ 'Passwort',
    /*005*/ 'Passwort wiederholen',
    /*006*/ 'Mit Ihrer Anmeldung erklären Sie sich mit unseren ', // Mit Ihrer Anmeldung erklären Sie sich mit unseren AGB einverstanden.
    /*007*/ 'AGB',                                                // Mit Ihrer Anmeldung erklären Sie sich mit unseren AGB einverstanden.
    /*008*/ ' einverstanden.',                                    // Mit Ihrer Anmeldung erklären Sie sich mit unseren AGB einverstanden.
    /*009*/ 'Unternehmensaccount registrieren',
    /*010*/ 'Passwort zurücksetzen',
    /*011*/ 'zurück',
    /*012*/ 'Hilfe',
    /*013*/ 'Bitte geben Sie Ihre E-Mail-Adresse an',
    /*014*/ 'Bitte füllen Sie alle Felder aus',
    /*015*/ 'Passwort muss mindestens 6 Zeichen enthalten',
    /*016*/ 'Passwörter stimmen nicht überein',
    /*017*/ 'Das Rücksetzungsformular wurde an Ihre E-Mail-Adresse versendet. Bitte überprüfen Sie Ihr E-Mail-Postfach.',
    /*018*/ 'Unter der angegeben E-Mail-Adresse konnte kein Account gefunden werden.',
    /*019*/ 'Das ist keine gültige E-Mail-Adresse',
    /*020*/ 'Ein unerwarteter Fehler ist aufgetreten.',
    /*021*/ 'Login',
    /*022*/ 'Registrieren',
    /*023*/ 'Zurücksetzen',
    /*024*/ 'Logout',
    /*025*/ 'User Daten',
    /*026*/ 'E-Mail-Adr.',
    /*027*/ 'E-Mail-Adresse ändern',
    /*028*/ 'Passwort ändern',
    /*029*/ 'Mitarbeiter Daten',
    /*030*/ 'Name',
    /*031*/ 'Personalnr.',
    /*032*/ 'Abteilung',
    /*033*/ 'Gruppe',
    /*034*/ 'Ein unerwarteter Fehler ist aufgetreten. Wenden Sie sich an Ihren Administrator.',
    /*035*/ 'Bearbeiten',
    /*036*/ 'Abbrechen',
    /*037*/ 'Speichern',
    /*038*/ 'altes Passwort',
    /*039*/ 'neues Passwort',
    /*040*/ 'neue E-Mail-Adresse',
    /*041*/ 'User löschen',
    /*042*/ 'User unwiderruflich löschen?',
    /*043*/ 'Nein',
    /*044*/ 'Ja',
    /*045*/ 'OK',
    /*046*/ 'E-Mail-Adresse ist bereits vergeben',
    /*047*/ 'Ein Fehler ist aufgetreten',
    /*048*/ 'Fehler',
    /*049*/ 'Projekt hinzufügen',
    /*050*/ 'Projekte nach Projektname oder -nummer durchsuchen',
    /*051*/ 'Nach Vorbehalt filtern',
    /*052*/ 'Nach Projekten mit Planbedarf filtern',
    /*053*/ 'Beendete Projekte ausfiltern',
    /*054*/ 'Projekte chronologisch sortieren',
    /*055*/ 'Projekte alphabetisch sortieren',
    /*056*/ 'Projekte nach Projektkennziffer sortieren',
    /*057*/ 'Notiz',
    /*058*/ 'Kalendereinstellungen',
    /*059*/ 'Layouts verwalten',
    /*060*/ 'Neuen Mitarbeiter hinzufügen',
    /*061*/ 'Anzahl der Mitarbeiter',
    /*062*/ 'Mitarbeiter auswählen',  // plural
    /*063*/ 'Ein Tag zurück',
    /*064*/ 'Eine Woche zurück',
    /*065*/ 'Ein Tag weiter',
    /*066*/ 'Eine Woche weiter',
    /*067*/ 'Mitarbeiter nach links verschieben',
    /*068*/ 'Mitarbeiter nach rechts verschieben',
    /*069*/ 'Weniger Tage anzeigen',
    /*070*/ 'Weniger Wochen anzeigen',
    /*071*/ 'Mehr Tage anzeigen',
    /*072*/ 'Mehr Wochen anzeigen',
    /*073*/ 'Blockbearbeitung aktiv',
    /*074*/ 'Einzelbearbeitung aktiv',
    /*075*/ 'Letzte Änderung rückgängig machen',
    /*076*/ 'Letzte Änderung wiederholen',
    /*077*/ 'Zu einem bestimmten Datum springen',
    /*078*/ 'Vier Wochen anzeigen',
    /*079*/ 'Zur aktuellen Woche springen',
    /*080*/ 'Zum Heutigen Tag springen',
    /*081*/ 'Projekte einblenden',
    /*082*/ 'Projekte ausblenden',
    /*083*/ 'Spaltenbreite',
    /*084*/ 'von',
    /*085*/ 'bis',
    /*086*/ 'Regelarbeitsbeginn',
    /*087*/ 'Wochentage',
    /*088*/ 'Schriftgröße',
    /*089*/ 'Kennziffer',
    /*090*/ 'Dauer',
    /*091*/ 'Farbe',
    /*092*/ 'Kategorie',
    /*093*/ 'weniger Farben',
    /*094*/ 'mehr Farben',
    /*095*/ 'Projekt',
    /*096*/ 'notiz',  // Projekt-notiz (Zeilenumbruch)
    /*097*/ 'unter Vorbehalt anlegen',
    /*098*/ 'Projekt bearbeiten',
    /*099*/ 'unter Vorbehalt angelegt',
    //#endregion
    //#region 100-199
    /*100*/ 'abgeschlossen',
    /*101*/ 'Löschen',
    /*102*/ 'Projektübersicht',
    /*103*/ 'Projekt löschen',
    /*104*/ 'Projekt ', // Projekt XY unwiderruflich löschen?
    /*105*/ ' unwiderruflich löschen?', // Projekt XY unwiderruflich löschen?
    /*106*/ 'Mitarbeiter hinzufügen',
    /*107*/ 'Berechtigungen von', // Berechtigungen von XY
    /*108*/ 'diesem Mitarbeiter', // Berechtigungen von diesem Mitarbeiter
    /*109*/ 'Liste der Mitarbeiter, die',     // Liste der Mitarbeiter, die XY einsehen oder bearbeiten kann
    /*110*/ 'dieser Mitarbeiter',             // Liste der Mitarbeiter, die dieser Mitarbeiter einsehen oder bearbeiten kann
    /*111*/ 'einsehen oder bearbeiten kann',  // Liste der Mitarbeiter, die XY einsehen oder bearbeiten kann
    /*112*/ 'Doppelte Anfrage verweigert',  // plural
    /*113*/ 'Mitarbeiter ausgewählt', // plural
    /*114*/ 'unbekannt',
    /*115*/ 'einsehen',
    /*116*/ 'bearbeiten',
    /*117*/ 'Berechtigungen auf', // Berechtigungen auf XY
    /*118*/ 'diesen Mitarbeiter', // Berechtigungen auf diesen Mitarbeiter
    /*119*/ 'Liste der Mitarbeiter, welche',
    /*120*/ 'diesen Mitarbeiter',
    /*121*/ 'einsehen oder bearbeiten können',
    /*122*/ 'Alle Mitarbeiter',
    /*123*/ 'als User anmelden',
    /*124*/ 'Ist als User angemeldet',
    /*125*/ 'Verfügbar',
    /*126*/ 'Angezeigt',
    /*127*/ 'Suche',
    /*128*/ 'Mitarbeiter löschen',
    /*129*/ 'unwiderruflich löschen?',
    /*130*/ 'Projekt zuweisen',
    /*131*/ 'Start',
    /*132*/ 'Ende',
    /*133*/ 'Alle',
    /*134*/ 'Gesamtdauer',
    /*135*/ 'Tag hinzufügen',
    /*136*/ 'Projektzuweisungen bearbeiten',
    /*137*/ 'Von',
    /*138*/ 'Bis',
    /*139*/ 'Keine Zuweisung gefunden',
    /*140*/ 'Im Kalender anzeigen',
    /*141*/ 'editiert',
    /*142*/ 'erstellt',
    /*143*/ 'Layout auswählen',
    /*144*/ 'Layout löschen',
    /*145*/ 'Layoutname ändern',
    /*146*/ 'Layoutname speichern',
    /*147*/ 'Details',
    /*148*/ 'Ansicht',
    /*149*/ 'Wochenansicht',
    /*150*/ 'Wochen',
    /*151*/ 'Tagesansicht',
    /*152*/ 'Mitarbeiter',
    /*153*/ 'Layout hinzufügen',
    /*154*/ 'Datum ändern',
    /*155*/ 'Tipps zum Einstieg',
    /*156*/ 'Das wars schon mit der kurzen Einführung. Falls Sie Fragen oder Anregungen haben erreichen Sie uns unter',
    /*157*/ 'oder senden eine Mail an',
    /*158*/ 'Während der Testphase ist der Support für Sie kostenlos! Auch unsere',
    /*159*/ 'FAQs',
    /*160*/ 'können bei offenen Fragen weiterhelfen.',
    /*161*/ 'Sie haben keine Schreibrechte auf diesen Mitarbeiter',
    /*162*/ 'Zuweisungen konnten nicht entfernt werden',
    /*163*/ 'Zuweisungen gelöscht',
    /*164*/ 'Zuweisungen wurden erfolgreich entfernt!',
    /*165*/ 'Zuweisung konnte nicht entfernt werden',
    /*166*/ 'Zuweisung gelöscht',
    /*167*/ 'Zuweisung wurde erfolgreich entfernt!',
    /*168*/ 'Rückgängig gemacht',
    /*169*/ 'Letzte Änderung der Zuweisung wurde rückgängig gemacht',
    /*170*/ 'Letzte Änderungen der Zuweisungen wurden rückgängig gemacht',
    /*171*/ 'Änderung wiederhergestellt',
    /*172*/ 'Letzte Änderung der Zuweisung wurde wiederhergestellt',
    /*173*/ 'Änderungen wiederhergestellt',
    /*174*/ 'Letzte Änderungen der Zuweisungen wurden wiederhergestellt',
    /*175*/ 'Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es erneut.',
    /*176*/ 'Zuweisungsblöcke werden gemeinsam bearbeitet',
    /*177*/ 'Eingabe konnte nicht verarbeitet werden',
    /*178*/ 'Zuweisungen werden einzeln bearbeitet',
    /*179*/ 'Name ungültig',
    /*180*/ 'Maximallänge beträgt 20 Zeichen',
    /*181*/ 'Layoutname umbenannt',
    /*182*/ 'Layout wurde in "',  // Layout wurde in "XY" umbenannt
    /*183*/ '" umbenannt',        // Layout wurde in "XY" umbenannt
    /*184*/ 'Layout gelöscht',
    /*185*/ 'Layout "',                           // Layout "XY" wurde unwiderruflich entfernt!
    /*186*/ '" wurde unwiderruflich entfernt!',   // Layout "XY" wurde unwiderruflich entfernt!
    /*187*/ 'Layout "',         // Layout "XY" wurde angelegt
    /*188*/ '" wurde angelegt', // Layout "XY" wurde angelegt
    /*189*/ 'Bitte geben Sie einen Projektnamen an',
    /*190*/ 'Bitte geben Sie eine Projektkennziffer an',
    /*191*/ 'Bitte geben Sie die Bearbeitungsdauer an',
    /*192*/ 'Projektname ist zu lang',
    /*193*/ 'Projektkennziffer ist zu lang',
    /*194*/ 'Projekt hinzugefügt',
    /*195*/ 'Projekt "',                        // Projekt "XY" wurde erfolgreich hinzugefügt
    /*196*/ '" wurde erfolgreich hinzugefügt',  // Projekt "XY" wurde erfolgreich hinzugefügt
    /*197*/ 'Zugriff verweigert',
    /*198*/ 'Projekt wird momentan von einem anderen User bearbeitet',
    /*199*/ 'Bearbeitungsrechte nicht erhalten',
    //#endregion
    //#region 200-299
    /*200*/ 'Projekt wird momentan bearbeitet',
    /*201*/ 'Projekt aktualisiert',
    /*202*/ 'Projekt "',                    // Projekt "XY" wurde erfolgreich geändert
    /*203*/ '" wurde erfolgreich geändert', // Projekt "XY" wurde erfolgreich geändert
    /*204*/ 'Projekt gelöscht',
    /*205*/ 'Projekt wurde unwiderruflich gelöscht!',
    /*206*/ '"Start" darf nicht nach "Ende" terminiert werden',
    /*207*/ 'Zuweisungen aktualisiert',
    /*208*/ 'Projektzuweisungen wurden geändert',
    /*209*/ 'Zuweisung aktualisiert',
    /*210*/ 'Projektzuweisung wurde geändert',
    /*211*/ 'Zuweisungen hinzugefügt',
    /*212*/ 'Projektzuweisungen wurden eingeplant',
    /*213*/ 'Zuweisung hinzugefügt',
    /*214*/ 'Projektzuweisung wurde eingeplant',
    /*215*/ 'Bitte versuchen Sie es später noch einmal',
    /*216*/ 'Bitte geben Sie einen Namen an',
    /*217*/ 'Bitte geben Sie eine Personalnummer an',
    /*218*/ 'Bitte geben Sie eine E-Mail-Adresse an',
    /*219*/ 'Bitte geben Sie eine gültige E-Mail-Adresse an',
    /*220*/ 'Der Name ist zu lang',
    /*221*/ 'Die Personalnummer ist zu lang',
    /*222*/ 'Der Abteilungsname ist zu lang',
    /*223*/ 'Der Gruppenname ist zu lang',
    /*224*/ 'Mitarbeiter hinzugefügt',
    /*225*/ ' wurde erfolgreich hinzugefügt', // XY wurde erfolgreich hinzugefügt
    /*226*/ 'konnte nicht geladen werden',
    /*227*/ 'Mitarbeiterprofil wird momentan von einem anderen User bearbeitet',
    /*228*/ 'E-Mail-Adresse von "',             // E-Mail-Adresse von "XY" konnte nicht ermittelt werden
    /*229*/ '" konnte nicht ermittelt werden',  // E-Mail-Adresse von "XY" konnte nicht ermittelt werden
    /*230*/ 'Mitarbeiter nicht gefunden',
    /*231*/ 'Mitarbeiter aktualisiert',
    /*232*/ ' wurde erfolgreich geändert', // XY wurde erfolgreich geändert
    /*233*/ 'Mitarbeiter Löschen fehlgeschlagen!',
    /*234*/ '1/4 Mitarbeiter anlegen',
    /*235*/ '2/4 Projekt anlegen',
    /*236*/ '3/4 Projekt Mitarbeiter zuweisen',
    /*237*/ '4/4 Zuweisung ändern',
    /*238*/ '1/3 Projekt anlegen',
    /*239*/ '2/3 Projekt Mitarbeiter zuweisen',
    /*240*/ '3/3 Zuweisung ändern',
    /*241*/ 'Login fehlgeschlagen',
    /*242*/ 'E-Mail-Adresse inkorrekt',
    /*243*/ 'Passwort inkorrekt',
    /*244*/ 'Zu viele Anfragen auf diesen Account. Wenden Sie sich an den Administrator.',
    /*245*/ 'E-Mail-Format ungültig',
    /*246*/ 'Kein Mitarbeiter-Profil vorhanden',
    /*247*/ 'Ein unerwarteter Fehler ist aufgetreten',
    /*248*/ 'Mitarbeiter-Profil konnte nicht gefunden werden',
    /*249*/ 'Logout fehlgeschlagen',
    /*250*/ 'Passwort konnte nicht geändert werden',
    /*251*/ 'E-Mail-Adresse konnte nicht geändert werden',
    /*252*/ 'Ein Fehler ist aufgetreten. Wenden Sie sich an Ihren Administrator.',
    /*253*/ 'Eintrag ist nicht mehr aktuell',
    /*254*/ 'Einträge sind nicht mehr aktuell',
    /*255*/ 'E-Mail-Adresse des Mitarbeiters konnte nicht ermittelt werden',
    /*256*/ 'E-Mail-Adresse ist nicht bekannt',
    /*257*/ 'E-Mail-Adresse konnte nicht aktualisiert werden',
    /*258*/ 'E-Mail-Adresse konnte nicht freigegebenen werden',
    /*259*/ 'fehlerhafte Eingabe',
    /*260*/ 'Layout konnte nicht gelöscht werden',
    /*261*/ 'Layout nicht gefunden',
    /*262*/ 'Mitarbeiter konnte nicht gefunden werden',
    /*263*/ 'Mitarbeiter nicht bekannt',
    /*264*/ 'Name bereits vergeben',
    /*265*/ 'Name ist bereits vergeben',
    /*266*/ 'Nur Zuweisungen desselben Mitarbeiters können zusammen entfernet werden',
    /*267*/ 'Nur Zuweisungen desselben Projekts können zusammen entfernet werden',
    /*268*/ 'Personalnummer ist bereits vergeben',
    /*269*/ 'Projekt kann nicht gelöscht werden, solange es Mitarbeitern zugewiesen ist',
    /*270*/ 'Projekt konnte nicht gefunden werden',
    /*271*/ 'Projekt konnte nicht umgewandelt werden',
    /*272*/ 'Projekt nicht gefunden',
    /*273*/ 'Projektkennziffer ist bereits vergeben',
    /*274*/ 'Zuweisung konnte nicht angelegt werden',
    /*275*/ 'Zuweisung konnte nicht gefunden werden',
    /*276*/ 'Zuweisung nicht gefunden',
    /*277*/ 'Zuweisungen dürfen sich nicht überschneiden',
    /*278*/ 'Zuweisungen konnte nicht angelegt werden',
    /*279*/ 'Zuweisungen konnten nicht aktualisiert werden',
    /*280*/ 'Layout hinzugefügt',
    /*281*/ 'Mo. - Fr.',
    /*282*/ 'Mo. - Sa.',
    /*283*/ 'Mo. - So.',
    /*284*/ 'nicht erneut anzeigen',
    /*285*/ 'd',
    /*286*/ 'h',
    /*287*/ 'min',
    /*288*/ 'Nummer',
    /*289*/ 'Schließen',
    /*290*/ 'Mitarbeiter anzeigen',
    /*291*/ 'Layouts',
    /*292*/ 'Tage',
    /*293*/ 'Mo-So',
    /*294*/ 'Mo-Sa',
    /*295*/ 'Mo-Fr',
    /*296*/ 'bearbeitet',
    /*297*/ 'Layout',
    /*298*/ 'Krank',
    /*299*/ 'Urlaub',
    //#endregion
    //#region 300-399
    /*300*/ 'Lizenz aktivieren',
    /*301*/ 'Aktivieren',
    /*302*/ 'Unternehmen',
    /*303*/ 'USt-IdNr',
    /*304*/ 'Straße',
    /*305*/ 'Stadt',
    /*306*/ 'PLZ',
    /*307*/ 'Land',
    /*308*/ 'Kreditkarte',
    /*309*/ 'IBAN',
    /*310*/ 'SEPA',
    /*311*/ 'Lizenz aktiviert',
    /*312*/ 'Ihre Lizenz wurde erfolgreich aktiviert.',
    /*313*/ 'Zahlungsmittel',
    /*314*/ 'Jahresabo',
    /*315*/ 'Preisberechnung',
    /*316*/ 'User',
    /*317*/ 'Ihre Gebühr beträgt:',
    /*318*/ 'Monat',
    /*319*/ 'Mitarbeiter kann nicht gelöscht werden, solange dieser Projekten zugewiesen ist.',
    /*320*/ 'Mitarbeiter kann nicht gelöscht werden, da dieser ein User ist.',
    /*321*/ 'Admin-Profil kann nicht gelöscht werden',
    /*322*/ 'Tagesansicht öffnen',
    /*323*/ 'Wochenansicht öffnen',
    /*324*/ 'Start ungültig',
    /*325*/ 'Ende ungültig',
    /*326*/ 'Zuweisung wird momentan von einem anderen User bearbeitet',
    /*327*/ 'Planer',
    /*328*/ 'Planer kann Projekte einsehen und neue Mitarbeiter hinzufügen',
    /*329*/ 'eigenes Profil',
    /*330*/ 'aktivieren',
    /*331*/ 'Liste der deaktivierten Mitarbeiter',
    /*332*/ 'Deaktivierte Mitarbeiter',
    /*333*/ 'Start und Ende einzeln bearbeiten',
    /*334*/ 'Start und Ende parallel bearbeiten',
    /*335*/ 'Mitarbeiter deaktivieren',
    /*336*/ 'Deaktivieren',
    /*337*/ '"',
    /*338*/ '" aus der Plantafel nehemen und sperren?',
    /*339*/ 'Mitarbeiter Deaktivieren fehlgeschlagen!',
    /*340*/ 'Ihr Account wurde deaktiviert!',
    /*341*/ 'Projektarchiv',
    /*342*/ 'Projekte archivieren',
    /*343*/ 'Archivieren',
    /*344*/ 'Archiv ist leer',
    /*345*/ 'Wiederherstellen',
    /*346*/ 'Übersicht',
    /*347*/ 'Projekt archiviert',
    /*348*/ 'Projekt "',                          // Projekt "XY"  wurde erfolgreich archiviert.
    /*349*/ '" wurde erfolgreich archiviert.',    // Projekt "XY"  wurde erfolgreich archiviert.
    /*350*/ 'Nach beendete Projekte filtern',
    /*351*/ 'Funktionalität nicht gewährleistet!',
    /*352*/ 'Die Nutzung unserer WebApp ist mit diesem Internet Browser unter Umständen eingeschränkt. Für eine optimale Nutzung empfehlen wir die Verwendung von Chrome oder Firefox.',
    /*353*/ 'Chef',
    /*354*/ 'Mitarbeiteraccount registrieren',
    /*355*/ 'Unternehmensname',
    /*356*/ 'Vor- und Nachname',
    /*357*/ 'Telefonnummer',
    /*358*/ 'DSGVO konform',
    /*359*/ 'Sichere Übertragung',
    /*360*/ 'Verschlüsselte Daten',
    /*361*/ 'Lizenz abgelaufen!',
    /*362*/ 'Ihre Lizenz für die Digitale Plantafel Ist abgelaufen. Erwerben Sie eine Lizenz, um Ihre Daten bearbeiten zu können.',
    /*363*/ 'Lizenz läuft aus!',
    /*364*/ 'Ihre Testversion läuft in ',
    /*365*/ ' Tagen aus!',
    /*366*/ 'Ihre Testversion läuft morgen aus!',
    /*367*/ 'Ihre Testversion läuft heute aus!',
    /*368*/ 'Erste Schritte',
    /*369*/ 'Mitarbeiter anlegen',
    /*370*/ 'Projekt anlegen',
    /*371*/ 'Zuweisung anlegen',
    /*372*/ 'Zuweisung verschieben',
    /*373*/ 'Zuweisung löschen',
    /*374*/ 'Zuweisung ändern',
    /*375*/ 'Zuweisungsblock erstellen',
    /*376*/ 'Zuweisungsblock verschieben',
    /*377*/ 'Bitte gegeben Sie eine E-Mail-Adresse an, die einem Mitarbeiter zugewiesen wurde',
    /*378*/ 'Die angegebene E-Mail-Adresse wurde keinem Mitarbeiter zugewiesen',
    /*379*/ 'Testversion',
    /*380*/ 'Ihre Testversion ist noch ',
    /*381*/ ' Tage gültig.',
    /*382*/ 'unter Vorbehalt',
    /*383*/ 'Report',
    /*384*/ 'Zeit',
    /*385*/ 'Detaillierte Zeit',
    /*386*/ 'Diese Woche',
    /*387*/ 'Letzte Woche',
    /*388*/ 'Dieser Monat',
    /*389*/ 'Letzter Monat',
    /*390*/ 'Benutzerdefiniert',
    /*391*/ 'Zeitraum',
    /*392*/ 'Suche detaillierte Zeit',
    /*393*/ 'Stunden insgesamt',
    /*394*/ 'Alle Projekte',
    /*395*/ 'Stunden abzgl. Pause',
    /*396*/ 'Projekte',
    //#endregion
  ];

  private static readonly _weekArrayEng: ReadonlyArray<string> = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  private static readonly _weekArrayGer: ReadonlyArray<string> = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

  private static readonly weekArrayEngNormalized: ReadonlyArray<string> = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  private static readonly weekArrayGerNormalized: ReadonlyArray<string> = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

  private static readonly calendarEng = {
    firstDayOfWeek: 0,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear',
  };
  private static readonly calendarGer = {
    firstDayOfWeek: 0,
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    today: 'Heute',
    clear: 'Löschen',
  };

  public static getTextByIndex(textI: number) {
    this.tryInit();

    if (!this._textTable[textI]) {
      return '???';
    }
    return this._textTable[textI];
  }

  public static translate(text: string): string {
    this.tryInit();

    if (this._langI === 1) {
      if (this._gerTable.indexOf(text) === -1) {
        console.debug('"' + text + '" unknown in Ci18n');
      }
      return text;
    }

    const tempI = this._gerTable.indexOf(text);
    if (tempI === -1) {
      console.debug('"' + text + '" unknown in Ci18n 2');
      return text;
    }

    const translatedtext = this._textTable[tempI];
    return ( translatedtext !== '' && !translatedtext ) ? text : translatedtext;
  }

  public static translateProjektName(name: string): string {
    if (name !== 'Krank' && name !== 'Urlaub') {
      return name;
    }

    return this.translate(name);
  }

  public static changeLanguage() {
    // save changed language to storage
    // reload page
  }

  public static getCalenderLocale() {
    this.tryInit();

    switch (this._langI) {
      case 0:
        return this.calendarEng;
        break;
      case 1:
        return this.calendarGer;
        break;
    }
  }

  public static getWeekArray() {
    this.tryInit();

    switch (this._langI) {
      case 0:
        return this._weekArrayEng;
        break;
      case 1:
        return this._weekArrayGer;
        break;
    }
  }

  public static getWeekArrayNormalized() {
    this.tryInit();

    switch (this._langI) {
      case 0:
        return this.weekArrayEngNormalized;
        break;
      case 1:
        return this.weekArrayGerNormalized;
        break;
    }
  }

  private static setLanguage(lang: string|number) {
    let langI: number;

    if ( typeof lang === 'string' ) {
      const tempI = this.allowedLangStr.indexOf(lang);
      if (tempI === -1) {
        return;
      }
      langI = this._allowedLangI[tempI];
    } else if ( typeof lang === 'number' ) {
      if (this._allowedLangI.indexOf(lang) === -1) {
        return;
      }
      langI = lang;
    } else {
      return;
    }

    if (this._langI === langI) {
      return;
    }

    // this._langI = langI;
    // TODO immer Deutsch bis Übersetzungen überarbeitet bzw zu ngx-translate
    this._langI = 1;
    this.setTextTable();
  }

  private static tryInit() {
    if (this._langI === undefined) {
      const lsLang = this.getStoredLangI();
      if (!!lsLang || lsLang === 0) {
        this.setLanguage(lsLang);
      } else {
        const browserLang = this.detectBrowserLanguage();
        this.changeToBroswerLanguage(browserLang);
      }
    }
  }

  private static getStoredLangI(): number {
    // try get lang from localstorage
    // return 0;
    return undefined;
  }

  private static detectBrowserLanguage(): string {
    const browserLang = navigator.language;

    if (this.isBroswerLanguageKnown(browserLang)) {
      return browserLang;
    }

    const browserLangList = navigator.languages;

    if (!browserLangList || !browserLangList.length) {
      return browserLang;
    }

    for (let i = 0; i < browserLangList.length; i++) {
      const tempLang = browserLangList[i];
      if (this.isBroswerLanguageKnown(tempLang)) {
        return tempLang;
      }
    }

    return browserLang;
  }

  private static isBroswerLanguageKnown(lang: string): boolean {
    lang = lang.toLowerCase();
    switch (lang) {
      case 'af': // Afrikaans
        return false;
      case 'hr': // Croatian
        return false;
      case 'el': // Greek
        return false;
      case 'pl': // Polish
        return false;
      case 'sx': // Sutu
        return false;
      case 'sq': // Albanian
        return false;
      case 'cs': // Czech
        return false;
      case 'gu': // Gujurati
        return false;
      case 'pt': // Portuguese
        return false;
      case 'sw': // Swahili
        return false;
      case 'ar': // Arabic (Standard)
        return false;
      case 'da': // Danish
        return false;
      case 'ht': // Haitian
        return false;
      case 'pt-br': // Portuguese (Brazil)
        return false;
      case 'sv': // Swedish
        return false;
      case 'ar-dz': // Arabic (Algeria)
        return false;
      case 'nl': // Dutch (Standard)
        return false;
      case 'he': // Hebrew
        return false;
      case 'pa': // Punjabi
        return false;
      case 'sv-fi': // Swedish (Finland)
        return false;
      case 'ar-bh': // Arabic (Bahrain)
        return false;
      case 'nl-be': // Dutch (Belgian)
        return false;
      case 'hi': // Hindi
        return false;
      case 'pa-in': // Punjabi (India)
        return false;
      case 'sv-sv': // Swedish (Sweden)
        return false;
      case 'ar-eg': // Arabic (Egypt)
        return false;
      case 'en': // English
        return true;
      case 'hu': // Hungarian
        return false;
      case 'pa-pk': // Punjabi (Pakistan)
        return false;
      case 'ta': // Tamil
        return false;
      case 'ar-iq': // Arabic (Iraq)
        return false;
      case 'en-au': // English (Australia)
        return true;
      case 'is': // Icelandic
        return false;
      case 'qu': // Quechua
        return false;
      case 'tt': // Tatar
        return false;
      case 'ar-jo': // Arabic (Jordan)
        return false;
      case 'en-bz': // English (Belize)
        return true;
      case 'id': // Indonesian
        return false;
      case 'rm': // Rhaeto-Romanic
        return false;
      case 'te': // Teluga
        return false;
      case 'ar-kw': // Arabic (Kuwait)
        return false;
      case 'en-ca': // English (Canada)
        return true;
      case 'iu': // Inuktitut
        return false;
      case 'ro': // Romanian
        return false;
      case 'th': // Thai
        return false;
      case 'ar-lb': // Arabic (Lebanon)
        return false;
      case 'en-ie': // English (Ireland)
        return true;
      case 'ga': // Irish
        return false;
      case 'ro-mo': // Romanian (Moldavia)
        return false;
      case 'tig': // Tigre
        return false;
      case 'ar-ly': // Arabic (Libya)
        return false;
      case 'en-jm': // English (Jamaica)
        return true;
      case 'it': // Italian (Standard)
        return false;
      case 'ru': // Russian
        return false;
      case 'ts': // Tsonga
        return false;
      case 'ar-ma': // Arabic (Morocco)
        return false;
      case 'en-nz': // English (New Zealand)
        return true;
      case 'it-ch': // Italian (Switzerland)
        return false;
      case 'ru-mo': // Russian (Moldavia)
        return false;
      case 'tn': // Tswana
        return false;
      case 'ar-om': // Arabic (Oman)
        return false;
      case 'en-ph': // English (Philippines)
        return true;
      case 'ja': // Japanese
        return false;
      case 'sz': // Sami (Lappish)
        return false;
      case 'tr': // Turkish
        return false;
      case 'ar-qa': // Arabic (Qatar)
        return false;
      case 'en-za': // English (South Africa)
        return true;
      case 'kn': // Kannada
        return false;
      case 'sg': // Sango
        return false;
      case 'tk': // Turkmen
        return false;
      case 'ar-sa': // Arabic (Saudi Arabia)
        return false;
      case 'en-tt': // English (Trinidad & Tobago)
        return true;
      case 'ks': // Kashmiri
        return false;
      case 'sa': // Sanskrit
        return false;
      case 'uk': // Ukrainian
        return false;
      case 'ar-sy': // Arabic (Syria)
        return false;
      case 'en-gb': // English (United Kingdom)
        return true;
      case 'kk': // Kazakh
        return false;
      case 'sc': // Sardinian
        return false;
      case 'hsb': // Upper Sorbian
        return false;
      case 'ar-tn': // Arabic (Tunisia)
        return false;
      case 'en-us': // English (United States)
        return true;
      case 'km': // Khmer
        return false;
      case 'gd': // Scots Gaelic
        return false;
      case 'ur': // Urdu
        return false;
      case 'ar-ae': // Arabic (U.A.E.)
        return false;
      case 'en-zw': // English (Zimbabwe)
        return true;
      case 'ky': // Kirghiz
        return false;
      case 'sd': // Sindhi
        return false;
      case 've': // Venda
        return false;
      case 'ar-ye': // Arabic (Yemen)
        return false;
      case 'eo': // Esperanto
        return false;
      case 'tlh': // Klingon
        return false;
      case 'si': // Singhalese
        return false;
      case 'vi': // Vietnamese
        return false;
      case 'ar': // Aragonese
        return false;
      case 'et': // Estonian
        return false;
      case 'ko': // Korean
        return false;
      case 'sr': // Serbian
        return false;
      case 'vo': // Volapuk
        return false;
      case 'hy': // Armenian
        return false;
      case 'fo': // Faeroese
        return false;
      case 'ko-kp': // Korean (North Korea)
        return false;
      case 'sk': // Slovak
        return false;
      case 'wa': // Walloon
        return false;
      case 'as': // Assamese
        return false;
      case 'fa': // Farsi
        return false;
      case 'ko-kr': // Korean (South Korea)
        return false;
      case 'sl': // Slovenian
        return false;
      case 'cy': // Welsh
        return false;
      case 'ast': // Asturian
        return false;
      case 'fj': // Fijian
        return false;
      case 'la': // Latin
        return false;
      case 'so': // Somani
        return false;
      case 'xh': // Xhosa
        return false;
      case 'az': // Azerbaijani
        return false;
      case 'fi': // Finnish
        return false;
      case 'lv': // Latvian
        return false;
      case 'sb': // Sorbian
        return false;
      case 'ji': // Yiddish
        return false;
      case 'eu': // Basque
        return false;
      case 'fr': // French (Standard)
        return false;
      case 'lt': // Lithuanian
        return false;
      case 'es': // Spanish
        return false;
      case 'zu': // Zulu
        return false;
      case 'bg': // Bulgarian
        return false;
      case 'fr-be': // French (Belgium)
        return false;
      case 'lb': // Luxembourgish
        return false;
      case 'es-ar': // Spanish (Argentina)
        return false;
      case 'be': // Belarusian
        return false;
      case 'fr-ca': // French (Canada)
        return false;
      case 'mk': // FYRO Macedonian
        return false;
      case 'es-bo': // Spanish (Bolivia)
        return false;
      case 'bn': // Bengali
        return false;
      case 'fr-fr': // French (France)
        return false;
      case 'ms': // Malay
        return false;
      case 'es-cl': // Spanish (Chile)
        return false;
      case 'bs': // Bosnian
        return false;
      case 'fr-lu': // French (Luxembourg)
        return false;
      case 'ml': // Malayalam
        return false;
      case 'es-co': // Spanish (Colombia)
        return false;
      case 'br': // Breton
        return false;
      case 'fr-mc': // French (Monaco)
        return false;
      case 'mt': // Maltese
        return false;
      case 'es-cr': // Spanish (Costa Rica)
        return false;
      case 'bg': // Bulgarian
        return false;
      case 'fr-ch': // French (Switzerland)
        return false;
      case 'mi': // Maori
        return false;
      case 'es-do': // Spanish (Dominican Republic)
        return false;
      case 'my': // Burmese
        return false;
      case 'fy': // Frisian
        return false;
      case 'mr': // Marathi
        return false;
      case 'es-ec': // Spanish (Ecuador)
        return false;
      case 'ca': // Catalan
        return false;
      case 'fur': // Friulian
        return false;
      case 'mo': // Moldavian
        return false;
      case 'es-sv': // Spanish (El Salvador)
        return false;
      case 'ch': // Chamorro
        return false;
      case 'gd': // Gaelic (Scots)
        return false;
      case 'nv': // Navajo
        return false;
      case 'es-gt': // Spanish (Guatemala)
        return false;
      case 'ce': // Chechen
        return false;
      case 'gd-ie': // Gaelic (Irish)
        return false;
      case 'ng': // Ndonga
        return false;
      case 'es-hn': // Spanish (Honduras)
        return false;
      case 'zh': // Chinese
        return false;
      case 'gl': // Galacian
        return false;
      case 'ne': // Nepali
        return false;
      case 'es-mx': // Spanish (Mexico)
        return false;
      case 'zh-hk': // Chinese (Hong Kong)
        return false;
      case 'ka': // Georgian
        return false;
      case 'no': // Norwegian
        return false;
      case 'es-ni': // Spanish (Nicaragua)
        return false;
      case 'zh-cn': // Chinese (PRC)
        return false;
      case 'de': // German (Standard)
        return true;
      case 'nb': // Norwegian (Bokmal)
        return false;
      case 'es-pa': // Spanish (Panama)
        return false;
      case 'zh-sg': // Chinese (Singapore)
        return false;
      case 'de-at': // German (Austria)
        return true;
      case 'nn': // Norwegian (Nynorsk)
        return false;
      case 'es-py': // Spanish (Paraguay)
        return false;
      case 'zh-tw': // Chinese (Taiwan)
        return false;
      case 'de-de': // German (Germany)
        return true;
      case 'oc': // Occitan
        return false;
      case 'es-pe': // Spanish (Peru)
        return false;
      case 'cv': // Chuvash
        return false;
      case 'de-li': // German (Liechtenstein)
        return true;
      case 'or': // Oriya
        return false;
      case 'es-pr': // Spanish (Puerto Rico)
        return false;
      case 'co': // Corsican
        return false;
      case 'de-lu': // German (Luxembourg)
        return true;
      case 'om': // Oromo
        return false;
      case 'es-es': // Spanish (Spain)
        return false;
      case 'cr': // Cree
        return false;
      case 'de-ch': // German (Switzerland)
        return true;
      case 'fa': // Persian
        return false;
      case 'es-uy': // Spanish (Uruguay)
        return false;
      case 'fa-ir': // Persian/Iran
        return false;
      case 'es-ve': // Spanish (Venezuela)
        return false;
      default:
        return false;
    }
  }

  private static changeToBroswerLanguage(lang: string) {
    lang = lang.toLowerCase();
    switch (lang) {
      case 'af': // Afrikaans
        this.setLanguage(this._defaultLangI);
        break;
      case 'hr': // Croatian
        this.setLanguage(this._defaultLangI);
        break;
      case 'el': // Greek
        this.setLanguage(this._defaultLangI);
        break;
      case 'pl': // Polish
        this.setLanguage(this._defaultLangI);
        break;
      case 'sx': // Sutu
        this.setLanguage(this._defaultLangI);
        break;
      case 'sq': // Albanian
        this.setLanguage(this._defaultLangI);
        break;
      case 'cs': // Czech
        this.setLanguage(this._defaultLangI);
        break;
      case 'gu': // Gujurati
        this.setLanguage(this._defaultLangI);
        break;
      case 'pt': // Portuguese
        this.setLanguage(this._defaultLangI);
        break;
      case 'sw': // Swahili
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar': // Arabic (Standard)
        this.setLanguage(this._defaultLangI);
        break;
      case 'da': // Danish
        this.setLanguage(this._defaultLangI);
        break;
      case 'ht': // Haitian
        this.setLanguage(this._defaultLangI);
        break;
      case 'pt-br': // Portuguese (Brazil)
        this.setLanguage(this._defaultLangI);
        break;
      case 'sv': // Swedish
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-dz': // Arabic (Algeria)
        this.setLanguage(this._defaultLangI);
        break;
      case 'nl': // Dutch (Standard)
        this.setLanguage(this._defaultLangI);
        break;
      case 'he': // Hebrew
        this.setLanguage(this._defaultLangI);
        break;
      case 'pa': // Punjabi
        this.setLanguage(this._defaultLangI);
        break;
      case 'sv-fi': // Swedish (Finland)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-bh': // Arabic (Bahrain)
        this.setLanguage(this._defaultLangI);
        break;
      case 'nl-be': // Dutch (Belgian)
        this.setLanguage(this._defaultLangI);
        break;
      case 'hi': // Hindi
        this.setLanguage(this._defaultLangI);
        break;
      case 'pa-in': // Punjabi (India)
        this.setLanguage(this._defaultLangI);
        break;
      case 'sv-sv': // Swedish (Sweden)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-eg': // Arabic (Egypt)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en': // English
        this.setLanguage(0);
        break;
      case 'hu': // Hungarian
        this.setLanguage(this._defaultLangI);
        break;
      case 'pa-pk': // Punjabi (Pakistan)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ta': // Tamil
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-iq': // Arabic (Iraq)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-au': // English (Australia)
        this.setLanguage(0);
        break;
      case 'is': // Icelandic
        this.setLanguage(this._defaultLangI);
        break;
      case 'qu': // Quechua
        this.setLanguage(this._defaultLangI);
        break;
      case 'tt': // Tatar
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-jo': // Arabic (Jordan)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-bz': // English (Belize)
        this.setLanguage(0);
        break;
      case 'id': // Indonesian
        this.setLanguage(this._defaultLangI);
        break;
      case 'rm': // Rhaeto-Romanic
        this.setLanguage(this._defaultLangI);
        break;
      case 'te': // Teluga
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-kw': // Arabic (Kuwait)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-ca': // English (Canada)
        this.setLanguage(0);
        break;
      case 'iu': // Inuktitut
        this.setLanguage(this._defaultLangI);
        break;
      case 'ro': // Romanian
        this.setLanguage(this._defaultLangI);
        break;
      case 'th': // Thai
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-lb': // Arabic (Lebanon)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-ie': // English (Ireland)
        this.setLanguage(0);
        break;
      case 'ga': // Irish
        this.setLanguage(this._defaultLangI);
        break;
      case 'ro-mo': // Romanian (Moldavia)
        this.setLanguage(this._defaultLangI);
        break;
      case 'tig': // Tigre
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-ly': // Arabic (Libya)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-jm': // English (Jamaica)
        this.setLanguage(0);
        break;
      case 'it': // Italian (Standard)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ru': // Russian
        this.setLanguage(this._defaultLangI);
        break;
      case 'ts': // Tsonga
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-ma': // Arabic (Morocco)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-nz': // English (New Zealand)
        this.setLanguage(0);
        break;
      case 'it-ch': // Italian (Switzerland)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ru-mo': // Russian (Moldavia)
        this.setLanguage(this._defaultLangI);
        break;
      case 'tn': // Tswana
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-om': // Arabic (Oman)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-ph': // English (Philippines)
        this.setLanguage(0);
        break;
      case 'ja': // Japanese
        this.setLanguage(this._defaultLangI);
        break;
      case 'sz': // Sami (Lappish)
        this.setLanguage(this._defaultLangI);
        break;
      case 'tr': // Turkish
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-qa': // Arabic (Qatar)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-za': // English (South Africa)
        this.setLanguage(0);
        break;
      case 'kn': // Kannada
        this.setLanguage(this._defaultLangI);
        break;
      case 'sg': // Sango
        this.setLanguage(this._defaultLangI);
        break;
      case 'tk': // Turkmen
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-sa': // Arabic (Saudi Arabia)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-tt': // English (Trinidad & Tobago)
        this.setLanguage(0);
        break;
      case 'ks': // Kashmiri
        this.setLanguage(this._defaultLangI);
        break;
      case 'sa': // Sanskrit
        this.setLanguage(this._defaultLangI);
        break;
      case 'uk': // Ukrainian
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-sy': // Arabic (Syria)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-gb': // English (United Kingdom)
        this.setLanguage(0);
        break;
      case 'kk': // Kazakh
        this.setLanguage(this._defaultLangI);
        break;
      case 'sc': // Sardinian
        this.setLanguage(this._defaultLangI);
        break;
      case 'hsb': // Upper Sorbian
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-tn': // Arabic (Tunisia)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-us': // English (United States)
        this.setLanguage(0);
        break;
      case 'km': // Khmer
        this.setLanguage(this._defaultLangI);
        break;
      case 'gd': // Scots Gaelic
        this.setLanguage(this._defaultLangI);
        break;
      case 'ur': // Urdu
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-ae': // Arabic (U.A.E.)
        this.setLanguage(this._defaultLangI);
        break;
      case 'en-zw': // English (Zimbabwe)
        this.setLanguage(0);
        break;
      case 'ky': // Kirghiz
        this.setLanguage(this._defaultLangI);
        break;
      case 'sd': // Sindhi
        this.setLanguage(this._defaultLangI);
        break;
      case 've': // Venda
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar-ye': // Arabic (Yemen)
        this.setLanguage(this._defaultLangI);
        break;
      case 'eo': // Esperanto
        this.setLanguage(this._defaultLangI);
        break;
      case 'tlh': // Klingon
        this.setLanguage(this._defaultLangI);
        break;
      case 'si': // Singhalese
        this.setLanguage(this._defaultLangI);
        break;
      case 'vi': // Vietnamese
        this.setLanguage(this._defaultLangI);
        break;
      case 'ar': // Aragonese
        this.setLanguage(this._defaultLangI);
        break;
      case 'et': // Estonian
        this.setLanguage(this._defaultLangI);
        break;
      case 'ko': // Korean
        this.setLanguage(this._defaultLangI);
        break;
      case 'sr': // Serbian
        this.setLanguage(this._defaultLangI);
        break;
      case 'vo': // Volapuk
        this.setLanguage(this._defaultLangI);
        break;
      case 'hy': // Armenian
        this.setLanguage(this._defaultLangI);
        break;
      case 'fo': // Faeroese
        this.setLanguage(this._defaultLangI);
        break;
      case 'ko-kp': // Korean (North Korea)
        this.setLanguage(this._defaultLangI);
        break;
      case 'sk': // Slovak
        this.setLanguage(this._defaultLangI);
        break;
      case 'wa': // Walloon
        this.setLanguage(this._defaultLangI);
        break;
      case 'as': // Assamese
        this.setLanguage(this._defaultLangI);
        break;
      case 'fa': // Farsi
        this.setLanguage(this._defaultLangI);
        break;
      case 'ko-kr': // Korean (South Korea)
        this.setLanguage(this._defaultLangI);
        break;
      case 'sl': // Slovenian
        this.setLanguage(this._defaultLangI);
        break;
      case 'cy': // Welsh
        this.setLanguage(this._defaultLangI);
        break;
      case 'ast': // Asturian
        this.setLanguage(this._defaultLangI);
        break;
      case 'fj': // Fijian
        this.setLanguage(this._defaultLangI);
        break;
      case 'la': // Latin
        this.setLanguage(this._defaultLangI);
        break;
      case 'so': // Somani
        this.setLanguage(this._defaultLangI);
        break;
      case 'xh': // Xhosa
        this.setLanguage(this._defaultLangI);
        break;
      case 'az': // Azerbaijani
        this.setLanguage(this._defaultLangI);
        break;
      case 'fi': // Finnish
        this.setLanguage(this._defaultLangI);
        break;
      case 'lv': // Latvian
        this.setLanguage(this._defaultLangI);
        break;
      case 'sb': // Sorbian
        this.setLanguage(this._defaultLangI);
        break;
      case 'ji': // Yiddish
        this.setLanguage(this._defaultLangI);
        break;
      case 'eu': // Basque
        this.setLanguage(this._defaultLangI);
        break;
      case 'fr': // French (Standard)
        this.setLanguage(this._defaultLangI);
        break;
      case 'lt': // Lithuanian
        this.setLanguage(this._defaultLangI);
        break;
      case 'es': // Spanish
        this.setLanguage(this._defaultLangI);
        break;
      case 'zu': // Zulu
        this.setLanguage(this._defaultLangI);
        break;
      case 'bg': // Bulgarian
        this.setLanguage(this._defaultLangI);
        break;
      case 'fr-be': // French (Belgium)
        this.setLanguage(this._defaultLangI);
        break;
      case 'lb': // Luxembourgish
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-ar': // Spanish (Argentina)
        this.setLanguage(this._defaultLangI);
        break;
      case 'be': // Belarusian
        this.setLanguage(this._defaultLangI);
        break;
      case 'fr-ca': // French (Canada)
        this.setLanguage(this._defaultLangI);
        break;
      case 'mk': // FYRO Macedonian
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-bo': // Spanish (Bolivia)
        this.setLanguage(this._defaultLangI);
        break;
      case 'bn': // Bengali
        this.setLanguage(this._defaultLangI);
        break;
      case 'fr-fr': // French (France)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ms': // Malay
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-cl': // Spanish (Chile)
        this.setLanguage(this._defaultLangI);
        break;
      case 'bs': // Bosnian
        this.setLanguage(this._defaultLangI);
        break;
      case 'fr-lu': // French (Luxembourg)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ml': // Malayalam
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-co': // Spanish (Colombia)
        this.setLanguage(this._defaultLangI);
        break;
      case 'br': // Breton
        this.setLanguage(this._defaultLangI);
        break;
      case 'fr-mc': // French (Monaco)
        this.setLanguage(this._defaultLangI);
        break;
      case 'mt': // Maltese
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-cr': // Spanish (Costa Rica)
        this.setLanguage(this._defaultLangI);
        break;
      case 'bg': // Bulgarian
        this.setLanguage(this._defaultLangI);
        break;
      case 'fr-ch': // French (Switzerland)
        this.setLanguage(this._defaultLangI);
        break;
      case 'mi': // Maori
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-do': // Spanish (Dominican Republic)
        this.setLanguage(this._defaultLangI);
        break;
      case 'my': // Burmese
        this.setLanguage(this._defaultLangI);
        break;
      case 'fy': // Frisian
        this.setLanguage(this._defaultLangI);
        break;
      case 'mr': // Marathi
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-ec': // Spanish (Ecuador)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ca': // Catalan
        this.setLanguage(this._defaultLangI);
        break;
      case 'fur': // Friulian
        this.setLanguage(this._defaultLangI);
        break;
      case 'mo': // Moldavian
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-sv': // Spanish (El Salvador)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ch': // Chamorro
        this.setLanguage(this._defaultLangI);
        break;
      case 'gd': // Gaelic (Scots)
        this.setLanguage(this._defaultLangI);
        break;
      case 'nv': // Navajo
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-gt': // Spanish (Guatemala)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ce': // Chechen
        this.setLanguage(this._defaultLangI);
        break;
      case 'gd-ie': // Gaelic (Irish)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ng': // Ndonga
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-hn': // Spanish (Honduras)
        this.setLanguage(this._defaultLangI);
        break;
      case 'zh': // Chinese
        this.setLanguage(this._defaultLangI);
        break;
      case 'gl': // Galacian
        this.setLanguage(this._defaultLangI);
        break;
      case 'ne': // Nepali
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-mx': // Spanish (Mexico)
        this.setLanguage(this._defaultLangI);
        break;
      case 'zh-hk': // Chinese (Hong Kong)
        this.setLanguage(this._defaultLangI);
        break;
      case 'ka': // Georgian
        this.setLanguage(this._defaultLangI);
        break;
      case 'no': // Norwegian
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-ni': // Spanish (Nicaragua)
        this.setLanguage(this._defaultLangI);
        break;
      case 'zh-cn': // Chinese (PRC)
        this.setLanguage(this._defaultLangI);
        break;
      case 'de': // German (Standard)
        this.setLanguage(1);
        break;
      case 'nb': // Norwegian (Bokmal)
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-pa': // Spanish (Panama)
        this.setLanguage(this._defaultLangI);
        break;
      case 'zh-sg': // Chinese (Singapore)
        this.setLanguage(this._defaultLangI);
        break;
      case 'de-at': // German (Austria)
        this.setLanguage(1);
        break;
      case 'nn': // Norwegian (Nynorsk)
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-py': // Spanish (Paraguay)
        this.setLanguage(this._defaultLangI);
        break;
      case 'zh-tw': // Chinese (Taiwan)
        this.setLanguage(this._defaultLangI);
        break;
      case 'de-de': // German (Germany)
        this.setLanguage(1);
        break;
      case 'oc': // Occitan
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-pe': // Spanish (Peru)
        this.setLanguage(this._defaultLangI);
        break;
      case 'cv': // Chuvash
        this.setLanguage(this._defaultLangI);
        break;
      case 'de-li': // German (Liechtenstein)
        this.setLanguage(1);
        break;
      case 'or': // Oriya
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-pr': // Spanish (Puerto Rico)
        this.setLanguage(this._defaultLangI);
        break;
      case 'co': // Corsican
        this.setLanguage(this._defaultLangI);
        break;
      case 'de-lu': // German (Luxembourg)
        this.setLanguage(1);
        break;
      case 'om': // Oromo
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-es': // Spanish (Spain)
        this.setLanguage(this._defaultLangI);
        break;
      case 'cr': // Cree
        this.setLanguage(this._defaultLangI);
        break;
      case 'de-ch': // German (Switzerland)
        this.setLanguage(1);
        break;
      case 'fa': // Persian
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-uy': // Spanish (Uruguay)
        this.setLanguage(this._defaultLangI);
        break;
      case 'fa-ir': // Persian/Iran
        this.setLanguage(this._defaultLangI);
        break;
      case 'es-ve': // Spanish (Venezuela)
        this.setLanguage(this._defaultLangI);
        break;
      default:
        this.setLanguage(this._defaultLangI);
        break;
    }
  }

  private static setTextTable() {
    this.tryInit();

    switch (this._langI) {
      case 0: // eng
        this._textTable = this._engTable;
        break;
      case 1: // ger
        this._textTable = this._gerTable;
        break;
    }
  }
}
