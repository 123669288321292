<app-size-detector></app-size-detector>
<div id="page">
  <div id="navBar">
    <img alt="Filitime Logo" src="assets/images/filitimelogo250.png" id="navLogo">
    <ng-container *ngIf="!(deviceIsMobile$ | async); else mobile">
      <ng-container *ngIf="isLoggedIn === false">
        <div class="selectedTab tab"><h4>FiliTime</h4></div>
      </ng-container>
      <ng-container *ngIf="isLoggedIn === true">
        <a *ngFor="let tab of menuEntries; let i = index" class="tab" [routerLink]="tab.route"
           [class.selectedTab]="isActive(tab?.route[0])">
          <h4>{{ tab.label }}</h4>
        </a>
        <div style="float: right;" class="tab unselectedTab" (click)="logOut()">
          <h4>
            <i class="fa fa-sign-out"></i>{{ translate('Logout') }}</h4>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #mobile>
      <ng-container *ngIf="isLoggedIn === false">
        <h3 class="tabName">FiliTime</h3>
      </ng-container>
      <ng-container *ngIf="isLoggedIn === true">
        <h3 class="tabName">{{ getTabName(tabIndex) }}</h3>
        <button id="navB" type="button" pButton class="ui-button-secondary" icon="fa fa-ellipsis-v" (click)="openMobileNavMenu()"></button>
        <div *ngIf="showMobileNavMenu" (click)="closeMobileNavMenu()" id="navListBoxCloser"></div>
        <div *ngIf="showMobileNavMenu" id="navListBox">
          <ng-container *ngFor="let tab of menuEntries; let i = index">
            <div *ngIf="tabIndex!==i" id="tabListItem">
              <h5 (click)="changeTab(i, tab)">{{ tab.label }}</h5>
            </div>
            <hr *ngIf="tabIndex!==i">
          </ng-container>
          <div id="tabListItem" style="float: right;"><h5 (click)="logOut()"><i class="fa fa-sign-out"></i>{{ translate('logout') }}</h5></div>
        </div>
      </ng-container>
    </ng-template>
  </div>
  <div id="nav-bar-reports" class="no-print" *ngIf="showNavBarReports">
    <ng-container *ngIf="userAuthService.isAdmin$ | async">
      <a *ngFor="let tab of reportMenuEntries; let i = index" class="tab" [routerLink]="tab.route"
         routerLinkActive="selectedTab" [routerLinkActiveOptions]="{exact: false}">
        <h4>{{ tab.label }}</h4>
      </a>
    </ng-container>
    <div class="print-button" (click)="print()">
      <i class="fa fa-print"></i>
     <span>Print</span>
    </div>
  </div>
  <div id="content" [class.reports]="showNavBarReports">
    <ng-container *ngIf="isLoggedIn === true" [ngSwitch]="tabIndex">
      <router-outlet></router-outlet>
    </ng-container>
    <ng-container *ngIf="isLoggedIn === false">
      <div id="loginFormHelper">
        <div style="text-align: right"*ngIf="!firstLogin && !registerNewClient && !resetPassword">
            <button pButton type="button" (click)="switchToRegisterUser()" label="{{translate('Unternehmensaccount registrieren')}}"></button>
            <br><button pButton type="button" style="margin-top: 6px" (click)="switchToRegisterEmployee()" label="{{translate('Mitarbeiteraccount registrieren')}}"></button>
        </div>
        <button pButton type="button" *ngIf="resetPassword || registerNewClient || firstLogin" (click)="switchToNormalLogin()" label="{{translate('zurück')}}"></button>
        <img alt="FiliTime Logo" src="assets\images\filitime_digtiale_plantafel_logo.png" id="logo">
        <form id="loginForm" #f="ngForm" (ngSubmit)="continue()" autocomplete="on">
          <input pInputText [(ngModel)]="email" type="email" style="width: 100%;" placeholder="{{translate('E-Mail-Adresse')}}" name="email" class="txt"
            [attr.disabled]="firstLogin?true:null" required autocomplete="on" />
          <br>
          <ng-container *ngIf="registerNewClient">
            <input pInputText type="text" [(ngModel)]="companyName" style="width: 100%;" placeholder="{{translate('Unternehmensname')}}" name="companyName" class="txt" />
            <br>
            <input pInputText type="text" [(ngModel)]="registrant" style="width: 100%;" placeholder="{{translate('Vor- und Nachname')}}" name="registrant" class="txt" />
            <br>
            <input pInputText type="text" [(ngModel)]="phoneNumber" style="width: 100%;" placeholder="{{translate('Telefonnummer')}}" name="phoneNumber" class="txt" />
            <br><br>
          </ng-container>
          <input type="password" pPassword [(ngModel)]="password" *ngIf="!resetPassword" type="password" style="width: 100%;" placeholder="{{translate('Passwort')}}"
            name="password" class="txt" required autocomplete="on" [feedback]="false"/>
          <br>
          <input type="password" pPassword [(ngModel)]="password2" *ngIf="firstLogin || registerNewClient" type="password" style="width: 100%;"
            placeholder="{{translate('Passwort wiederholen')}}" name="password2" class="txt" required autocomplete="on" [feedback]="false"/>
          <br *ngIf="firstLogin || registerNewClient">
          <br>
          <a *ngIf="!firstLogin && !registerNewClient && !resetPassword" (click)="switchToResetPassword()">
            <u style="cursor:pointer;">{{ translate('Passwort zurücksetzen') }}</u>
            <br><br>
          </a>
          <h5 *ngIf="!!errorMsg" style="color: red">{{ errorMsg }}</h5>
          <ng-container *ngIf="firstLogin || registerNewClient">
            <span id="agbtext">{{ getTextByIndex(6) }}<a href="https://filitime.com/agb">{{ getTextByIndex(7) }}</a>{{ getTextByIndex(8) }}</span>
            <br><br>
          </ng-container>
          <button pButton type="button" *ngIf="!resetPassword" type="submit" label="{{(registerNewClient!==true) ? translate('Login') : translate('Registrieren')}}"></button>
          <button pButton type="button" *ngIf="resetPassword" (click)="resetPW();" label="{{translate('Zurücksetzen')}}"></button>
        </form>
        <div>
          <div class="securePanelContainer">
            <img alt="DSGVO" src="assets/images/dsgvo.png" class="securePanelIcons">
            <br><span class="secureLabelText">{{ translate('DSGVO konform') }}</span>
          </div>
          <div class="securePanelContainer">
            <img alt="SSL" src="assets/images/ssl.png" class="securePanelIcons">
            <br><span class="secureLabelText">{{ translate('Sichere Übertragung') }}</span>
          </div>
          <div class="securePanelContainer">
            <img alt="Verschlüsselte Daten" src="assets/images/verschlueselt.png" class="securePanelIcons">
            <br><span class="secureLabelText">{{ translate('Verschlüsselte Daten') }}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <br>
  </div>
</div>
<p-toast position="top-right" [modal]="true" [baseZIndex]="5000"></p-toast>
<p-blockUI [blocked]="showIsLoading">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="top: calc( 50% - 0.6em ); left: calc( 50% - 0.6em ); position: absolute;"></i>
</p-blockUI>
