<div class="align">
  <div class="box">
    <h3 class="boxHeader">{{translate("Abteilungen")}}</h3>
    <div class="boxContent">
      <div *ngFor="let dep of departments">
        <div><span>{{dep.name}}</span>
          <button class="boxContElem" type="button" pButton [tooltipDisabled]="isMobile"
                  pTooltip="{{translate('Bearbeiten')}}" tooltipPosition="right" life="7000"
                  (click)="openDialog(3, dep)" label="{{translate('Bearbeiten')}}"></button>
          <button class="boxContElem" type="button" pButton [tooltipDisabled]="isMobile"
                  pTooltip="{{translate('Löschen')}}" tooltipPosition="right" life="7000"
                  (click)="delete('department', dep)" label="{{translate('Löschen')}}"></button>
        </div>
      </div>


      <button class="boxContElem" type="button" pButton [tooltipDisabled]="isMobile"
              pTooltip="{{translate('Department add')}}" tooltipPosition="right" life="7000"
              (click)="openDialog(1)" label="{{translate('Abteilung hinzufügen')}}"></button>
    </div>

  </div>
  <div class="box">
    <h3 class="boxHeader">{{translate("Gruppen")}}</h3>
    <div class="boxContent">
      <div *ngFor="let group of groups">
        <div>{{group.name}}
          <button class="boxContElem" type="button" pButton [tooltipDisabled]="isMobile"
                  pTooltip="{{translate('Bearbeiten')}}" tooltipPosition="right" life="7000"
                  (click)="openDialog(4, group)" label="{{translate('Bearbeiten')}}"></button>
          <button class="boxContElem" type="button" pButton [tooltipDisabled]="isMobile"
                  pTooltip="{{translate('Löschen')}}" tooltipPosition="right" life="7000"
                  (click)="delete('group', group)" label="{{translate('Löschen')}}"></button>
        </div>
      </div>


      <button class="boxContElem" type="button" pButton [tooltipDisabled]="isMobile"
              pTooltip="{{translate('Group add')}}" tooltipPosition="right" life="7000"
              (click)="openDialog(2)" label="{{translate('Gruppe hinzufügen')}}"></button>

    </div>
  </div>
</div>
<p-dialog header="{{translate(getDialogHeader())}}" [(visible)]="showDialog" [responsive]="isMobile"
          [autoZIndex]="true">
  <table style="margin: auto;">
    <tr>
      <td>{{translate("Name")}}*</td>
      <td><input type="text" pInputText [(ngModel)]="selectedName"></td>
    </tr>
    <tr>
      <td>{{translate("Mitarbeiter")}}</td>
      <p-pickList [source]="filteredEmployees" [target]="selectedEmployees" sourceHeader="{{translate('Verfügbar')}}"
                  targetHeader="{{translate('Angezeigt')}}" filterBy="name"
                  sourceFilterPlaceholder="{{translate('Suche')}}"
                  targetFilterPlaceholder="{{translate('Suche')}}" dragdrop="true" dragdropScope="filitime002"
                  [showSourceControls]="false">
        <ng-template let-employee pTemplate="item">
          <div class="ui-helper-clearfix">
            <div style="font-size:14px;">{{ employee.name }}</div>
          </div>
        </ng-template>
      </p-pickList>
      <td></td>
    </tr>
  </table>
  <p-footer>
    <div style="width: 100%; text-align: right">
      <button type="button" pButton icon="fa fa-close" (click)="closeDialog()" label="{{translate('Abbrechen')}}"
              style="float: left;"></button>
      <button type="button" pButton icon="fa fa-check" (click)="add()" label="{{translate('Bestätigen')}}"></button>
    </div>
  </p-footer>
</p-dialog>
