/* tslint:disable */
import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';

import {Ci18n} from './ci18n';
import {SetAssignmentsService} from './services/set-assignments.service';
import {HelperService} from './services/helper.service';
import {Assignment, AssignmentHistory, DeleteAssignmentHelper} from './model/assignment.model';
import {Project} from './model/project.model';
import {DeleteAssignmentService} from './services/delete-assignment.service';
import * as firebase from 'firebase/app';
import {take} from 'rxjs/operators';
import {UpdateAssignmentService} from './services/update-assignment.service';
import {Employee} from './model/employee.model';
import {Access} from './model/access.model';
import {EmployeeAssignments} from './model/employee-assignment.model';
import GetOptions = firebase.firestore.GetOptions;
import FieldValue = firebase.firestore.FieldValue;
import {Department, Group} from "./model/departments.model";
import {TimeTracking} from "./model/timetracking.model";

@Injectable({
  providedIn: 'root',
})
export class Dbi2Service {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions,
              private setAssignmentService: SetAssignmentsService, private helper: HelperService,
              private deleteAssignmentService: DeleteAssignmentService,
              private updateAssignmentService: UpdateAssignmentService) {
    afAuth.onAuthStateChanged(user => {
      if (this._ignoerAuthChanges || (!user && !this._uId)) {
        return;
      }

      if (!!user && !this._uId) {
        this.autoLogin.emit(user.email);
        this.loginStart.emit();
        this.afterSignIn()
          .then(() => {
          })
          .catch(() => {
            /* ec */
            console.error('Error203124');
            this.logout(() => {
            }, () => {
            });
          });
      }

      if (!user && !!this._uId) {
        this.logout(() => {
        }, () => {
        });
        return;
      }

      if (!!this._uId && !!user.uid && this._uId !== user.uid) {
        window.location.reload();
        return;
      }
    });
  }

  //#region properties

  private _isLoggedIn = false;
  private _ignoerAuthChanges = false;
  private _mail: string;
  private _uId: string;
  public clientId: string;

  private _dataPreloaded = false;

  private _userEmployeeProfileDocPath: string;
  public _usersEmployeeId: string;
  private _usersEmployeeProfile: Employee;
  public _usersEmployeeIsCA = false;
  private _userEmployeeIsScheduler = false;
  private _userEmployeeCanSelfEdit = false;
  private _usersEmployeeName: string;

  private _usersAccessesDoc: {};
  private _usersAccesses: Access[] = [];

  private _employeeNames: string[] = [];
  private _employeeNamesIds: string[] = [];

  private _inactiveEmployeeNames: string[] = [];
  private _inactiveEmployeeNamesIds: string[] = [];

  private _employeesAccessTo: Employee[] = [];
  private _employeesAccessToIds: string[] = [];

  public _projectIds: string[] = [];
  public _projects: Project[] = [];

  private _projectNamesIds: string[] = [];
  private _projectNames: string[] = [];

  private _assignmentIds: string[] = [];
  private _assignments: Assignment[] = [];

  private _employeeAssignmentsList: EmployeeAssignments[] = [];
  private _employeeAssignmentsListIds: string[] = [];

  private _layoutNames: string[] = [];
  private _layouts: {}[] = [];

  private myEditRequest: string[] = [];

  private tempDate01436 = new Date();
  private tempDate1280 = new Date();
  //#endregion

  //#region  const
  private readonly msPerDay: number = 86400000;
  private readonly msPerHour: number = 3600000;
  private readonly msPerMinute: number = 60000;
  private readonly blockTimeout = 10 * this.msPerMinute;

  private readonly regExpEMail = new RegExp(
    '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
  );

  public readonly preloadLimiterTS = this.calcPreloadLimiter();
  //#endregion

  //#region eventEmitter
  public autoLogin = new EventEmitter<string>();
  public loginStart = new EventEmitter();
  public loginSucced = new EventEmitter();
  public loginFailed = new EventEmitter<string>();
  public logoutSucced = new EventEmitter();

  public usersEmployeeChange = new EventEmitter<Employee>();

  public projectAdd = new EventEmitter<[Project, number]>();
  public projectChange = new EventEmitter<[Project, number]>();
  public projectRemove = new EventEmitter<[Project, number]>();

  public projectNameAdd = new EventEmitter<[string, number]>();
  public projectNameChange = new EventEmitter<[string, number]>();
  public projectNameRemove = new EventEmitter<[string, number]>();

  public employeeAccessToAdd = new EventEmitter<[Employee, number]>();
  public employeeAccessToChange = new EventEmitter<[Employee, number]>();
  public employeeAccessToRemove = new EventEmitter<[Employee, number]>();

  public employeeTableExtend = new EventEmitter<string>();

  public employeeNameAdd = new EventEmitter<[string, number]>();
  public employeeNameChange = new EventEmitter<[string, number]>();
  public employeeNameRemove = new EventEmitter<[string, number]>();

  public usersAccessAdd = new EventEmitter<[Access, number]>();
  public usersAccessChange = new EventEmitter<[Access, number]>();
  public usersAccessRemove = new EventEmitter<[Access, number]>();

  public assignmentAdd = new EventEmitter<[Assignment, number]>();
  public assignmentChange = new EventEmitter<[Assignment, number]>();
  public assignmentRemove = new EventEmitter<[Assignment, number]>();
  //#endregion

  //#region subscribtion

  private _userEmployeeProfileUnsubFunc: () => void = undefined;

  private _employeeNameAddedSub: Subscription = undefined;
  private _employeeNameModifiedSub: Subscription = undefined;
  private _employeeNameRemoveSub: Subscription = undefined;

  private _employeeAddedSub: Subscription = undefined;
  private _employeeRemoveSub: Subscription = undefined;

  private _projectAddedSub: Subscription = undefined;
  private _projectModifiedSub: Subscription = undefined;
  private _projectRemoveSub: Subscription = undefined;

  private _employeeAssignmentsSubsEmployeeIds: string[] = [];
  private _employeeAssignmentsAddedSubList: Subscription[] = [];
  private _employeeAssignmentsModifiedSubList: Subscription[] = [];
  private _employeeAssignmentsRemovedSubList: Subscription[] = [];

  private _employeeAccessesToSubsEmployeeIds: string[] = [];
  private _employeeAccessesToSubsList: Subscription[] = [];
  private _employeeAccessesToSubsEmployeeIds2: string[] = [];
  private _employeeAccessesToSubsList2: Subscription[] = [];
  private _employeeAccessesToSubsEmployeeIds3: string[] = [];
  private _employeeAccessesToSubsList3: Subscription[] = [];

  private _subPool: Subscription[] = [];

  private _layoutAddedSub: Subscription = undefined;
  private _layoutModifiedSub: Subscription = undefined;
  private _layoutRemoveSub: Subscription = undefined;

  // fix to wait for projects to be loaded before display weekly view
  numberOfProjectsAlreadyLoaded = 0;
  numberOfProjectsHaveToLoad = 0;
  allProjectsAreLoaded = false;

  private unsubscribeAll() {
    this.unsubscribeAllEmployeeAssignments();
    this.unsubscribeAllEmployeeAccessesTo();
    this.unsubscribeProjects();
    this.unsubscribeEmployees();
    this.unsubscribeUserEmployeeProfile();
    this.unsubscribeEmployeeNames();

    while (this._subPool.length > 0) {
      const tempSub = this._subPool.shift();
      tempSub.unsubscribe();
    }

    this.unsubscribeLayouts();
  }

  public unsubscribeUserEmployeeProfile() {
    if (!!this._userEmployeeProfileUnsubFunc) {
      this._userEmployeeProfileUnsubFunc();
      this._userEmployeeProfileUnsubFunc = undefined;
    }
  }

  private unsubscribeEmployeeNames() {
    if (!!this._employeeNameAddedSub) {
      this._employeeNameAddedSub.unsubscribe();
      this._employeeNameAddedSub = undefined;
    }

    if (!!this._employeeNameModifiedSub) {
      this._employeeNameModifiedSub.unsubscribe();
      this._employeeNameModifiedSub = undefined;
    }

    if (!!this._employeeNameRemoveSub) {
      this._employeeNameRemoveSub.unsubscribe();
      this._employeeNameRemoveSub = undefined;
    }
  }

  private unsubscribeEmployees() {
    if (!!this._employeeAddedSub) {
      this._employeeAddedSub.unsubscribe();
      this._employeeAddedSub = undefined;
    }

    if (!!this._employeeRemoveSub) {
      this._employeeRemoveSub.unsubscribe();
      this._employeeRemoveSub = undefined;
    }
  }

  private unsubscribeProjects() {
    if (!!this._projectAddedSub) {
      this._projectAddedSub.unsubscribe();
      this._projectAddedSub = undefined;
    }

    if (!!this._projectModifiedSub) {
      this._projectModifiedSub.unsubscribe();
      this._projectModifiedSub = undefined;
    }

    if (!!this._projectRemoveSub) {
      this._projectRemoveSub.unsubscribe();
      this._projectRemoveSub = undefined;
    }
  }

  private unsubscribeEmployeeAccessesTo(employeeId: string) {
    const i1 = this._employeeAccessesToSubsEmployeeIds.indexOf(employeeId);
    const i2 = this._employeeAccessesToSubsEmployeeIds2.indexOf(employeeId);
    const i3 = this._employeeAccessesToSubsEmployeeIds3.indexOf(employeeId);

    if (i1 === -1) {
      /* ec */
      console.error('Error123847');
      return;
    }
    if (i2 === -1) {
      /* ec */
      console.error('Error150841');
      return;
    }
    if (i3 === -1) {
      /* ec */
      console.error('Error150841');
      return;
    }

    this._employeeAccessesToSubsEmployeeIds.splice(i1, 1);
    const employeeAccessToSub = this._employeeAccessesToSubsList.splice(
      i1,
      1
    )[0];
    this._employeeAccessesToSubsEmployeeIds2.splice(i2, 1);
    const employeeAccessToSub2 = this._employeeAccessesToSubsList2.splice(
      i2,
      1
    )[0];
    this._employeeAccessesToSubsEmployeeIds3.splice(i3, 1);
    const employeeAccessToSub3 = this._employeeAccessesToSubsList3.splice(
      i3,
      1
    )[0];

    employeeAccessToSub.unsubscribe();
    employeeAccessToSub2.unsubscribe();
    employeeAccessToSub3.unsubscribe();
  }

  private unsubscribeAllEmployeeAccessesTo() {
    for (let i = 0; i < this._employeeAccessesToSubsEmployeeIds.length; i++) {
      this._employeeAccessesToSubsList[i].unsubscribe();
    }
    for (let i = 0; i < this._employeeAccessesToSubsEmployeeIds2.length; i++) {
      this._employeeAccessesToSubsList2[i].unsubscribe();
    }
    for (let i = 0; i < this._employeeAccessesToSubsEmployeeIds3.length; i++) {
      this._employeeAccessesToSubsList3[i].unsubscribe();
    }

    this._employeeAccessesToSubsEmployeeIds = [];
    this._employeeAccessesToSubsList = [];
    this._employeeAccessesToSubsEmployeeIds2 = [];
    this._employeeAccessesToSubsList2 = [];
    this._employeeAccessesToSubsEmployeeIds3 = [];
    this._employeeAccessesToSubsList3 = [];
  }

  private unsubscribeEmployeeAssignments(employeeId) {
    const i = this._employeeAssignmentsSubsEmployeeIds.indexOf(employeeId);

    if (i === -1) {
      /* ec */
      console.error('Error237405');
      return;
    }

    this._employeeAssignmentsSubsEmployeeIds.splice(i, 1);
    const _employeeAssignmentsAddedSub = this._employeeAssignmentsAddedSubList.splice(i, 1)[0];
    const _employeeAssignmentsModifiedSub = this._employeeAssignmentsModifiedSubList.splice(i, 1)[0];
    const _employeeAssignmentsRemovedSub = this._employeeAssignmentsRemovedSubList.splice(i, 1)[0];

    const tempI = this._employeeAssignmentsListIds.indexOf(employeeId);

    if (tempI === -1) {
      /* ec */
      console.error('Error859130');
    } else {
      this._employeeAssignmentsListIds.splice(tempI, 1);
      this._employeeAssignmentsList.splice(tempI, 1);
    }
    _employeeAssignmentsAddedSub.unsubscribe();
    _employeeAssignmentsModifiedSub.unsubscribe();
    _employeeAssignmentsRemovedSub.unsubscribe();
  }

  private unsubscribeAllEmployeeAssignments() {
    for (let i = 0; i < this._employeeAssignmentsSubsEmployeeIds.length; i++) {
      this._employeeAssignmentsAddedSubList[i].unsubscribe();
      this._employeeAssignmentsModifiedSubList[i].unsubscribe();
      this._employeeAssignmentsRemovedSubList[i].unsubscribe();
    }

    this._employeeAssignmentsSubsEmployeeIds = [];
    this._employeeAssignmentsAddedSubList = [];
    this._employeeAssignmentsModifiedSubList = [];
    this._employeeAssignmentsRemovedSubList = [];
  }

  private subscribeLayouts() {
    const layoutsColl = this.afs.collection(
      '/users/' + this._uId + '/layouts'
    );

    if (!this._layoutAddedSub) {
      this._layoutAddedSub = layoutsColl.stateChanges(['added']).subscribe({
        next: layoutSnaps => {
          if (layoutSnaps.length === 0) {
            return;
          }

          layoutSnaps.forEach(layoutSnap => {
            this._layoutNames.push(layoutSnap.payload.doc.id);
            this._layouts.push(layoutSnap.payload.doc.data());
          });
        },
        error: err => {
          /* ec */
          console.error('Error107326');
        }
      });
    }

    if (!this._layoutRemoveSub) {
      this._layoutRemoveSub = layoutsColl.stateChanges(['removed']).subscribe({
        next: layoutSnaps => {
          if (layoutSnaps.length === 0) {
            return;
          }

          layoutSnaps.forEach(layoutSnap => {
            const layoutName = layoutSnap.payload.doc.id;
            const tempI = this._layoutNames.indexOf(layoutName);
            if (tempI !== 1) {
              this._layoutNames.splice(tempI, 1);
              this._layouts.splice(tempI, 1);
            }
          });
        },
        error: err => {
          /* ec */
          console.error('Error107326');
        }
      });
    }
  }

  private unsubscribeLayouts() {
    if (!!this._layoutAddedSub) {
      this._layoutAddedSub.unsubscribe();
      this._layoutAddedSub = undefined;
    }

    if (!!this._layoutModifiedSub) {
      this._layoutModifiedSub.unsubscribe();
      this._layoutModifiedSub = undefined;
    }

    if (!!this._layoutRemoveSub) {
      this._layoutRemoveSub.unsubscribe();
      this._layoutRemoveSub = undefined;
    }

  }

  //#endregion

  /****to be removed.....****/
  public getEmployeeId(employeeName: string): string {
    const i = this._employeeNames.indexOf(employeeName);

    if (i === -1) {
      /* ec */
      console.error('Error384029');
      return null;
    }

    const empId = this._employeeNamesIds[i];

    if (!empId) {
      /* ec */
      console.error('Error384030');
      return null;
    }

    return empId;
  }

  public getInactiveEmployeeId(employeeName: string): string {
    const i = this._inactiveEmployeeNames.indexOf(employeeName);

    if (i === -1) {
      /* ec */
      console.error('Error253446');
      return null;
    }

    const empId = this._inactiveEmployeeNamesIds[i];

    if (!empId) {
      /* ec */
      console.error('Error546843');
      return null;
    }

    return empId;
  }

  public getProjectId(projectName: string): string {
    const i = this._projectNames.indexOf(projectName);

    if (i === -1) {
      /* ec */
      console.error('Error238475');
      return null;
    }

    const projId = this._projectNamesIds[i];

    if (!projId) {
      /* ec */
      console.error('Error238476');
      return null;
    }

    return projId;
  }

  /**************************/

  //#region getProloadedData func
  public getEMail(): string {
    return this._mail;
  }

  public getLoadedUsersEmployee(): Employee {
    const usersEmployeeProfileCopy = this.copyEmployee(this._usersEmployeeProfile);
    return usersEmployeeProfileCopy;
  }

  public isUsersEmployeeAdmin(): boolean {
    return this._usersEmployeeIsCA;
  }

  public isUsersEmployeeScheduler(): boolean {
    return this._userEmployeeIsScheduler;
  }

  public canUsersEmployeeSelfEdit(): boolean {
    return this._userEmployeeCanSelfEdit;
  }

  public getLoadedProjects(): Project[] {
    const projectsCopy: Project[] = [];

    this._projects.forEach(project => {
      projectsCopy.push(this.copyProject(project));
    });

    return projectsCopy;
  }

  public getLoadedProjectNames(): string[] {
    const projcetNamesCopy = this._projectNames.slice(0);
    return projcetNamesCopy;
  }

  public getLoadedEmployeeAccessTo(): Employee[] {
    const employeesAccessToCopy: Employee[] = [];
    this._employeesAccessTo.forEach(employeesAccessTo => {
      employeesAccessToCopy.push(this.copyEmployee(employeesAccessTo));
    });

    return employeesAccessToCopy;
  }

  public getLoadedEmployeeNames(): string[] {
    const employeeNamesCopy = this._employeeNames.slice(0);
    return employeeNamesCopy;
  }

  public getInactiveEmployeeNames(): string[] {
    const employeeNamesCopy = this._inactiveEmployeeNames.slice(0);
    return employeeNamesCopy;
  }

  public getLoadedUsersAccesses(): Access[] {
    const usersEmployeeAccessesToCopy: Access[] = [];

    if (!this._usersEmployeeIsCA) {
      this._usersEmployeeProfile.accessesTo.forEach(usersEmployeeAccessTo => {
        usersEmployeeAccessesToCopy.push(this.copyAccess(usersEmployeeAccessTo));
      });
    } else {
      for (let i = 0; i < this._employeeNames.length; i++) {
        const empName = this._employeeNames[i];

        if (empName === this._usersEmployeeName) {
          continue;
        }

        const empNameId = this._employeeNamesIds[i];

        const newAccess = new Access(empNameId, true);
        newAccess['name'] = empName;
        usersEmployeeAccessesToCopy.push(newAccess);
      }
    }

    return usersEmployeeAccessesToCopy;
  }

  public getLoadedAssignments(): Assignment[] {
    const assignmentsCopy: Assignment[] = [];
    this._assignments.forEach(assignment => {
      assignmentsCopy.push(this.helper.copyAssignment(assignment));
    });

    return assignmentsCopy;
  }

  public getLoadedAssignmentTableData(
    from: Date,
    to: Date
  ): [Assignment[][][], number[][][], number[], string[]] {
    return this.buildAssignmentTable(from, to);
  }

  //#endregion

  //#region init func
  private reset() {
    this.unsubscribeAll();

    this._isLoggedIn = false;
    this._mail = undefined;
    this._uId = undefined;
    this.clientId = undefined;

    this._dataPreloaded = false;

    this._userEmployeeProfileDocPath = undefined;
    this._usersEmployeeId = undefined;
    this._usersEmployeeProfile = undefined;
    this._usersEmployeeIsCA = false;
    this._userEmployeeIsScheduler = false;
    this._userEmployeeCanSelfEdit = false;
    this._usersEmployeeName = undefined;

    this._usersAccessesDoc = undefined;
    this._usersAccesses = [];

    this._employeeNames = [];
    this._employeeNamesIds = [];

    this._inactiveEmployeeNames = [];
    this._inactiveEmployeeNamesIds = [];

    this._employeesAccessTo = [];
    this._employeesAccessToIds = [];

    this._projectIds = [];
    this._projects = [];

    this._projectNamesIds = [];
    this._projectNames = [];

    this._assignmentIds = [];
    this._assignments = [];

    this._employeeAssignmentsList = [];
    this._employeeAssignmentsListIds = [];

    this._layoutNames = [];
    this._layouts = [];
  }

  //#endregion

  //#region dbi func
  public login(email: string, password: string) {
    email = email.toLowerCase(); // make mail lower case
    email = email.replace(/\s/g, ''); // delete whitespaces

    if (!this.emailFormatCheck(email)) {
      /* ec */
      console.error('Error346271');
      return;
    }

    this.signIn(email, password)
      .catch(() => {
        /* ec */
        console.error('Error252305');
      })
      .then(() => {
      });
  }

  private signIn(mail, pw): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      mail = mail.toLowerCase(); // make mail lower case
      mail = mail.replace(/\s/g, ''); // delete whitespaces
      if (!this.emailFormatCheck(mail)) {
        /* ec */
        console.error('Error128365');
        reject(Ci18n.translate('E-Mail-Format ungültig'));
        return;
      }

      this._ignoerAuthChanges = true;
      this.loginStart.emit();
      this.afAuth.signInWithEmailAndPassword(mail, pw)
        .then(auth => {
          if (auth) {
            this.afterSignIn()
              .then(() => {
                resolve();
                this._ignoerAuthChanges = false;
              })
              .catch(err => {
                reject(err);
                this._ignoerAuthChanges = false;
              });
          } else {
            /* ec */
            console.error('Error134798');
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            this._ignoerAuthChanges = false;
          }
        })
        .catch(error => {
          let errText = Ci18n.translate('Login fehlgeschlagen');
          switch (error.code) {
            case 'auth/user-not-found':
              errText = Ci18n.translate('E-Mail-Adresse inkorrekt');
              break;
            case 'auth/wrong-password':
              errText = Ci18n.translate('Passwort inkorrekt');
              break;
            case 'auth/too-many-requests':
              errText = Ci18n.translate('Zu viele Anfragen auf diesen Account. Wenden Sie sich an den Administrator.');
              break;
            case 'auth/user-disabled':
              errText = Ci18n.translate('Ihr Account wurde deaktiviert!');
              break;
          }

          this.loginFailed.emit(errText);
          let returnValue = error.message;
          if (!returnValue) {
            returnValue = error;
          }
          reject(returnValue);
          this._ignoerAuthChanges = false;
          return;
        });
    });
  }

  private afterSignIn(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      const userId = (await this.afAuth.currentUser).uid;
      const userMail = (await this.afAuth.currentUser).email;

      const userDoc = this.afs.collection('users').doc(userId);
      const tempSub = userDoc.valueChanges().subscribe({
        next: userObj => {
          tempSub.unsubscribe();
          if (!userObj['employeeProfiles']) {
            /* ec */
            console.error('Error357415');
            this.loginFailed.emit(Ci18n.translate('Kein Mitarbeiter-Profil vorhanden'));
            reject('no employee profile for this user found');
            return;
          } else {
            const docPaths: string[] = userObj['employeeProfiles'];
            if (!docPaths || !docPaths.length) {
              /* ec */
              console.error('Error321445');
              this.loginFailed.emit(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
              reject('unexpected error');
              return;
            } else {
              const clientIds: string[] = [];
              let clientId: string;
              const userEmployeeProfileDocPaths: string[] = [];
              let userEmployeeProfileDocPath: string;
              for (let i = 0; i < docPaths.length; i++) {
                const docPath = docPaths[i];
                userEmployeeProfileDocPath = docPath;
                userEmployeeProfileDocPaths.push(
                  userEmployeeProfileDocPath
                );

                const pathArray: string[] = userEmployeeProfileDocPath.split('/');
                if (
                  !pathArray ||
                  !pathArray.length ||
                  pathArray.length < 2 ||
                  pathArray[0] !== 'clients'
                ) {
                  this.loginFailed.emit(Ci18n.translate('Mitarbeiter-Profil konnte nicht gefunden werden'));
                  reject('employee profile could not be found');
                  return;
                } else {
                  clientIds.push(pathArray[1]);
                  clientId = pathArray[1];
                }
              }

              this._uId = userId;
              this._mail = userMail;
              this.clientId = clientId;
              this._userEmployeeProfileDocPath = userEmployeeProfileDocPath;

              this._isLoggedIn = true;

              this.preloadData().then(
                val => {
                  resolve(this._usersEmployeeProfile.name);
                  this.loginSucced.emit();

                  this.subscribeLayouts();
                  return;
                },
                err => {
                  /* ec */
                  console.error('Error320649');
                  this.loginFailed.emit(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                  reject('preloading data failed');
                }
              ).catch(err => {
                /* ec */
                console.error('Error293582');
                this.loginFailed.emit(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                reject('preloading data failed');
              });
            }
          }
        },
        error: err => {
          /* ec */
          console.error('Error239487');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        },
        complete: () => {
          /* ec */
          console.error('Error143401');
        }
      });
    });
  }

  public logout(succedCB: () => void, errorCB: (err: string) => void) {
    this.signOut()
      .then(val => {
        this.reset();
        this.logoutSucced.emit();
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error485602');
        errorCB(Ci18n.translate('Logout fehlgeschlagen'));
      });
  }

  private signOut(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.afAuth
        .signOut()
        .then(
          val => {
            resolve();
            return;
          },
          err => {
            reject();
            return;
          }
        )
        .catch(error => {
          if (!!error) {
            reject();
            return;
          }
        });
    });
  }

  private signUp(
    email: string,
    password: string,
    succedCB: (userData: {}) => void,
    errorCB: (err: string) => void,
    clinetId?: string,
    empId?: string
  ) {
    email = email.toLowerCase(); // make mail lower case
    email = email.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(email)) {
      /* ec */
      console.error('Error183640');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(() => {

        this.createUser(clinetId, empId)
          .then(userData => {
            this.afAuth.signOut()
              .then(() => {
                this.afAuth.signInWithEmailAndPassword(email, password)
                  .then(() => {
                    succedCB(userData);
                    return;
                  })
                  .catch(error => {
                    /* ec */
                    console.error('Error205231');
                    errorCB(error);
                    return;
                  });
              })
              .catch(err => {
                /* ec */
                console.error('Error193865');
                errorCB(err);
                return;
              });
          })
          .catch(err => {
            /* ec */
            console.error('Error454848');
            errorCB(err);
            return;
          });
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error390421');
        errorCB(err);
        return;
      });
  }

  private createUser(clientId?: string, empId?: string) {
    return new Promise(async (resolve, reject) => {
      if (!clientId) {
        clientId = this.helper.generatePushId();
      }
      if (!empId) {
        empId = this.helper.generatePushId();
      }
      const mail = (await this.afAuth.currentUser).email;
      const uid = (await this.afAuth.currentUser).uid;
      const empProfilePath = 'clients/' + clientId + '/employees/' + empId;

      const userData = {
        mail: mail,
        userId: uid,
        employeeProfiles: [empProfilePath]
      };

      const userDoc = this.afs.firestore.doc('/users/' + uid);
      userDoc.set(userData)
        .then(() => {
          resolve(userData);
        })
        .catch(
          err => {
            reject();
          });
    });
  }

  public resetPassword(
    email: string,
    succedCB: () => void,
    errorCB: (err: { code: string }) => void,
  ) {
    email = email.toLowerCase(); // make mail lower case
    email = email.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(email)) {
      /* ec */
      console.error('Error179346');
      errorCB({code: 'auth/invalid-email'});
      return;
    }

    this.afAuth
      .sendPasswordResetEmail(email/*, { url: window.location.origin }*/)
      .then(any => {
        succedCB();
      })
      .catch(error => {
        /* ec */
        console.error('Error854023');
        errorCB(error);
      });
  }

  public changePassword(
    email: string,
    oldPassword: string,
    newPassword: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    email = email.toLowerCase(); // make mail lower case
    email = email.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(email)) {
      /* ec */
      console.error('Error109287');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    this.afAuth.signInWithEmailAndPassword(email, oldPassword)
      .then(async user => {
        (await this.afAuth.currentUser)
          .updatePassword(newPassword)
          .then(() => {
            succedCB();
          })
          .catch(err => {
            errorCB(Ci18n.translate('Passwort konnte nicht geändert werden'));
          });
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/user-not-found':
            errorCB(Ci18n.translate('E-Mail-Adresse inkorrekt'));
            break;
          case 'auth/wrong-password':
            errorCB(Ci18n.translate('Passwort inkorrekt'));
            break;
          case 'auth/too-many-requests':
            errorCB(
              Ci18n.translate('Zu viele Anfragen auf diesen Account. Wenden Sie sich an den Administrator.')
            );
            break;
          default:
            errorCB(error.code);
            break;
        }
      });
  }

  public changeUsersEmail(
    oldEmail: string,
    newEmail: string,
    password: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    oldEmail = oldEmail.toLowerCase(); // make mail lower case
    oldEmail = oldEmail.replace(/\s/g, ''); // delete whitespaces
    newEmail = newEmail.toLowerCase(); // make mail lower case
    newEmail = newEmail.replace(/\s/g, ''); // delete whitespaces

    if (!this.emailFormatCheck(newEmail)) {
      /* ec */
      console.error('Error910287');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    this.afAuth.signInWithEmailAndPassword(oldEmail, password)
      .then(() => {
        const oldMsmpDocRef = this.afs.firestore.doc('/msmp/' + oldEmail);
        const options: GetOptions = {source: 'server'};
        oldMsmpDocRef.get(options)
          .then(snap => {
            if (!snap.exists) {
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
              return;
            }

            const oldMsmpDocData = snap.data();

            oldMsmpDocRef.delete()
              .then(async () => {
                (await this.afAuth.currentUser)
                  .updateEmail(newEmail)
                  .then(() => {
                    this._ignoerAuthChanges = true;
                    this.afAuth.signOut().then(
                      () => {
                        this.afAuth.signInWithEmailAndPassword(newEmail, password).then(
                          () => {
                            this._ignoerAuthChanges = false;
                            funcX();
                          }
                        );
                      }
                    );

                    const funcX: () => void = () => {
                      const batch = this.afs.firestore.batch();

                      // update email in useres/uid
                      const usersDocRef = this.afs.firestore.doc('/users/' + this._uId);
                      const userDocData = {mail: newEmail};
                      batch.update(usersDocRef, userDocData);

                      // in msmp create new mail doc
                      const newMsmpDocRef = this.afs.firestore.doc('/msmp/' + newEmail);
                      batch.set(newMsmpDocRef, oldMsmpDocData);

                      batch.commit()
                        .then(() => {
                          succedCB();
                        })
                        .catch(async () => {
                          (await this.afAuth.currentUser).updateEmail(oldEmail)
                            .then(() => {
                              oldMsmpDocRef.set(oldMsmpDocData)
                                .then(() => {
                                  errorCB(Ci18n.translate('E-Mail-Adresse konnte nicht geändert werden'));
                                })
                                .catch(() => {
                                  errorCB(Ci18n.translate('Ein Fehler ist aufgetreten. Wenden Sie sich an Ihren Administrator.'));
                                });
                            })
                            .catch(() => {
                              errorCB(Ci18n.translate('Ein Fehler ist aufgetreten. Wenden Sie sich an Ihren Administrator.'));
                            });
                        });
                    };
                  })
                  .catch(() => {
                    oldMsmpDocRef.set(oldMsmpDocData)
                      .then(() => {
                        errorCB(Ci18n.translate('E-Mail-Adresse konnte nicht geändert werden'));
                      })
                      .catch(() => {
                        errorCB(Ci18n.translate('Ein Fehler ist aufgetreten. Wenden Sie sich an Ihren Administrator.'));
                      });
                  });
              })
              .catch(
                () => {
                  errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                  return;
                }
              );
          });
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/user-not-found':
            errorCB(Ci18n.translate('E-Mail-Adresse inkorrekt'));
            break;
          case 'auth/wrong-password':
            errorCB(Ci18n.translate('Passwort inkorrekt'));
            break;
          // case 'auth/too-many-requests':
          //   errorCB(Ci18n.translate('Zu viele Anfragen auf diesen Account. Wenden Sie sich an den Administrator.'))
          default:
            /* ec */
            console.error('Error138946');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            break;
        }
      });
  }

  public isEmailNewUser(
    email: string,
    succedCB: (arg0: boolean) => void,
    errorCB: (err: string) => void
  ) {
    // sicherheitsausnahme für diese(s) document(e) benötigt.
    email = email.toLowerCase(); // make mail lower case
    email = email.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(email)) {
      /* ec */
      console.error('Error923751');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    const newUsersDoc = this.afs.firestore.doc('/newUsers/' + email);

    // hotfix
    // for some reason we get rejcted and resolve of get() called!!!.. thx google!
    let succeded;

    const options: GetOptions = {source: 'server'};
    newUsersDoc
      .get(options)
      .then(newUsersSnap => {
        if (succeded === false) {
          return;
        }
        succeded = true;

        if (newUsersSnap.exists) {
          succedCB(true);
        } else {
          succedCB(false);
        }
      })
      .catch(err => {
        if (succeded === true) {
          return;
        }
        succeded = false;

        /* ec */
        console.error('Error357040');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  private getNewUserData(
    email: string,
    succedCB: (arg0?: string[]) => void,
    errorCB: (err: string) => void
  ) {
    email = email.toLowerCase(); // make mail lower case
    email = email.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(email)) {
      /* ec */
      console.error('Error201762');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    const newUsersDoc = this.afs.firestore.doc('/newUsers/' + email);

    const options: GetOptions = {source: 'server'};
    const tempSub = newUsersDoc
      .get(options)
      .then(newUsersSnap => {
        if (!newUsersSnap.exists) {
          succedCB();
        }

        const newUserDocData: {} = newUsersSnap.data();
        const clientId_empId: string = Object.keys(newUserDocData)[0];
        const returnArr: string[] = clientId_empId.split('_');

        if (returnArr.length !== 2) {
          /* ec */
          console.error('Error129058');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }

        succedCB(returnArr);
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error135709' + ' | err: ' + err);
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public changeNewUserMail(
    mail: string,
    employeeId: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    mail = mail.toLowerCase(); // make mail lower case
    mail = mail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(mail)) {
      /* ec */
      console.error('Error824619');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    const clientEmpId: string = this.clientId + '_' + employeeId;
    const newUsersCol = this.afs.collection('/newUsers/', ref =>
      ref.where(clientEmpId, '==', true)
    );

    const tempSub = newUsersCol.stateChanges().subscribe({
      next: snaps => {
        tempSub.unsubscribe();
        if (snaps.length === 0) {
          // /* ec */ console.error('Error239085');
          // errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));

          // this can happen due to en error that occured when setting an taken email adresse as an users email address
          this.addNewUser(mail, employeeId, errorCB, succedCB);
          return;
        }

        const oldMail = snaps[0].payload.doc.id;

        if (oldMail === mail) {
          succedCB();
          return;
        }

        const batch = this.afs.firestore.batch();

        const oldNewUserDocRef = firebase
          .firestore()
          .doc('/newUsers/' + oldMail);
        batch.delete(oldNewUserDocRef);

        const newNewUserDocRef = this.afs.firestore.doc('/newUsers/' + mail);
        batch.set(newNewUserDocRef, snaps[0].payload.doc.data());

        const oldMsmpDocRef = this.afs.firestore.doc('/msmp/' + oldMail);
        batch.delete(oldMsmpDocRef);

        const newMampDocRef = this.afs.firestore.doc('/msmp/' + mail);
        batch.set(newMampDocRef, snaps[0].payload.doc.data());

        batch
          .commit()
          .then(() => {
            succedCB();
          })
          .catch(err => {
            /* ec */
            console.error('Error109451' + ' | err: ' + err);
            errorCB(Ci18n.translate('E-Mail-Adresse konnte nicht aktualisiert werden'));
          });
      },
      error: () => {
        tempSub.unsubscribe();
        /* ec */
        console.error('Error109234');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });
  }

  public setUpNewClient(
    email: string,
    password: string,
    company: string,
    phone: string,
    registrant: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    email = email.toLowerCase(); // make mail lower case
    email = email.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(email)) {
      /* ec */
      console.error('Error640952');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    this.isMailListed(
      email,
      mailTaken => {
        if (mailTaken) {
          errorCB(Ci18n.translate('E-Mail-Adresse ist bereits vergeben'));
          return;
        }

        this._ignoerAuthChanges = true;
        this.loginStart.emit();
        this.signUp(
          email,
          password,
          async userData => {
            this._uId = (await this.afAuth.currentUser).uid;
            this._mail = (await this.afAuth.currentUser).email;

            const employeeProfilePath = userData['employeeProfiles'][0];
            const employeeProfilePathArr = employeeProfilePath.split('/');

            const newClientId = employeeProfilePathArr[1];
            const newEmployeeKey: string = employeeProfilePathArr[3];
            const newProjectKey1: string = this.helper.generatePushId();
            const newProjectKey2: string = this.helper.generatePushId();

            const employeeProfileSortPath = newClientId + '_' + newEmployeeKey;

            this.clientId = newClientId;
            this._userEmployeeProfileDocPath = employeeProfilePath;

            // const userdocRef = this.afs.firestore.doc('users/' + this._uId);
            // const userDocObj = {
            //   employeeProfiles: [employeeProfilePath],
            //   mail: this._mail,
            //   userId: this._uId
            // };

            const listedMailDocRef = firebase
              .firestore()
              .doc('msmp/' + this._mail);
            const listedMailDocData = {};
            listedMailDocData[employeeProfileSortPath] = true;

            const employeeDocRef = firebase
              .firestore()
              .doc(employeeProfilePath);
            const adminName = Ci18n.translate('Chef');
            const employeeDocData = {
              ca: true, // client admin
              name: adminName,
              number: '123456',
              user: true
            };

            const langStr = Ci18n.lang;

            const clientDocRef = this.afs.firestore.doc('/clients/' + newClientId);
            const clientDocData = {
              sendMeWelcome_mail: email,
              sendMeWelcome_lang: langStr,
              company: company,
              phone: phone,
              poc: registrant,
            };

            const employeeAccessesDocRef = firebase
              .firestore()
              .doc('clients/' + newClientId + '/accesses/' + newEmployeeKey);
            const employeeAccessesDocData = {};

            const employeeHistoryDocRef = firebase
              .firestore()
              .doc(
                '/clients/' +
                newClientId +
                '/history/employees/' +
                newEmployeeKey +
                '/' +
                this.helper.generatePushId()
              );
            const employeeHistoryDocData = {
              changeTime: FieldValue.serverTimestamp(),
              changeTyp: 'added',
              changerId: newEmployeeKey,
              changerName: adminName,
              object: employeeDocData
            };

            const project1DocRef = firebase
              .firestore()
              .doc('clients/' + newClientId + '/projects/' + newProjectKey1);
            const project1DocData = {
              color: '#ffd407',
              duration: 5409460,
              name: 'Krank',
              number: '#$1ckn3$$',
              reserved: false,
              timeToAllocate: 5409460
            };

            const project1HistoryDocRef = firebase
              .firestore()
              .doc(
                '/clients/' +
                newClientId +
                '/history/projects/' +
                newProjectKey1 +
                '/' +
                this.helper.generatePushId()
              );
            const project1HistoryDocData = {
              changeTime: FieldValue.serverTimestamp(),
              changeTyp: 'added',
              changerId: newEmployeeKey,
              changerName: adminName,
              object: project1DocData
            };

            const project2DocRef = firebase
              .firestore()
              .doc('clients/' + newClientId + '/projects/' + newProjectKey2);
            const project2DocData = {
              color: '#ff4b4b',
              duration: 5409460,
              name: 'Urlaub',
              number: '#V@c@710n',
              reserved: false,
              timeToAllocate: 5409460
            };

            const project2HistoryDocRef = firebase
              .firestore()
              .doc(
                '/clients/' +
                newClientId +
                '/history/projects/' +
                newProjectKey2 +
                '/' +
                this.helper.generatePushId()
              );
            const project2HistoryDocData = {
              changeTime: FieldValue.serverTimestamp(),
              changeTyp: 'added',
              changerId: newEmployeeKey,
              changerName: adminName,
              object: project1DocData
            };

            const batch = this.afs.firestore.batch();
            // batch.set(userdocRef, userDocObj);
            batch.set(clientDocRef, clientDocData);
            batch.set(listedMailDocRef, listedMailDocData);
            batch.set(employeeDocRef, employeeDocData);
            batch.set(employeeAccessesDocRef, employeeAccessesDocData);
            batch.set(employeeHistoryDocRef, employeeHistoryDocData);
            batch.set(project1DocRef, project1DocData);
            batch.set(project1HistoryDocRef, project1HistoryDocData);
            batch.set(project2DocRef, project2DocData);
            batch.set(project2HistoryDocRef, project2HistoryDocData);

            batch
              .commit()
              .then(() => {
                this._isLoggedIn = true;
                this.preloadData().then(
                  val => {
                    this._ignoerAuthChanges = false;
                    succedCB();
                    this.loginSucced.emit();
                    return;
                  },
                  err => {
                    /* ec */
                    console.error('Error012941');
                    this.loginFailed.emit(
                      Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten')
                    );
                    errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                    this._ignoerAuthChanges = false;
                    return;
                  }
                );
              })
              .catch(err => {
                errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                this._ignoerAuthChanges = false;
                return;
              });
          },
          err => {
            /* ec */
            console.error('Error801623');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            this._ignoerAuthChanges = false;
            return;
          }
        );
      },
      err => {
        /* ec */
        console.error('Error219306');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }
    );
  }

  public setUpFirstUser(
    email: string,
    password: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    email = email.toLowerCase(); // make mail lower case
    email = email.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(email)) {
      /* ec */
      console.error('Error750812');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    this.isMailListed(
      email,
      mailTaken => {
        if (!mailTaken) {
          errorCB(Ci18n.translate('E-Mail-Adresse ist nicht bekannt'));
          return;
        }

        this.getNewUserData(
          email,
          newUserData => {
            if (!newUserData) {
              /* ec */
              console.error('Error102975');
              errorCB(
                Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten. Wenden Sie sich an Ihren Administrator.')
              );
              return;
            }

            const clientId: string = newUserData[0];
            const empId: string = newUserData[1];

            this._ignoerAuthChanges = true;
            this.loginStart.emit();
            this.signUp(
              email,
              password,
              async () => {
                this._uId = (await this.afAuth.currentUser).uid;
                this._mail = (await this.afAuth.currentUser).email;

                const employeeProfilePath =
                  'clients/' + clientId + '/employees/' + empId;
                const employeeProfileSortPath = clientId + '_' + empId;

                this.clientId = clientId;
                this._userEmployeeProfileDocPath = employeeProfilePath;

                const employeeDocRef = firebase
                  .firestore()
                  .doc(employeeProfilePath);
                const employeeDocData = {user: true};

                const newUserDocRef = firebase
                  .firestore()
                  .doc('/newUsers/' + email);

                // const userDocRef = firebase
                //   .firestore()
                //   .doc('/users/' + this._uId);
                // const userDocData = {
                //   mail: email,
                //   userId: this._uId,
                //   employeeProfiles: [employeeProfilePath]
                // };

                const batch = this.afs.firestore.batch();
                batch.update(employeeDocRef, employeeDocData);
                batch.delete(newUserDocRef);
                // batch.set(userDocRef, userDocData);

                batch
                  .commit()
                  .then(() => {
                    this._isLoggedIn = true;
                    this.preloadData().then(
                      val => {
                        succedCB();
                        this.loginSucced.emit();
                        this._ignoerAuthChanges = false;
                        return;
                      },
                      err => {
                        /* ec */
                        console.error('Error018275');
                        this.loginFailed.emit(
                          Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten')
                        );
                        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                        this._ignoerAuthChanges = false;
                        return;
                      }
                    );
                  })
                  .catch(err => {
                    /* ec */
                    console.error('Error727316');
                    errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten. Wenden Sie sich an Ihren Administrator.'));
                    this._ignoerAuthChanges = false;
                  });
              },
              err => {
                /* ec */
                console.error('Error207142');
                errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                this._ignoerAuthChanges = false;
                return;
              },
              clientId,
              empId
            );
          },
          err => {
            /* ec */
            console.error('Error822555');
            errorCB(Ci18n.translate('E-Mail-Adresse ist bereits vergeben'));
            return;
          }
        );
      },
      err => {
        /* ec */
        console.error('Error791251');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }
    );
  }

  public async addDepartment(department: {
    name: string;
    id?: string;
  }) {
    try {
      const id = this.helper.generatePushId()
      department.id = id
      const departmentDoc = this.afs.firestore.doc(`/clients/${this.clientId}/departments/${id}`)
      const res = await departmentDoc.set(department)
    } catch (e) {
      console.error(e)
    }
  }

  public async updateDepartment(department: {
    name: string;
    id: string;
  }, employeesToRemove: Employee[], employeesToAdd: Employee[]) {
    try {
      const batch = this.afs.firestore.batch();
      const departmentDoc = this.afs.firestore.doc(`/clients/${this.clientId}/departments/${department.id}`)
      const employeeCollection = this.afs.firestore.collection(`/clients/${this.clientId}/employees/`)
      const data = await employeeCollection.get()
      const removeSet = new Set(employeesToRemove.map(e => e.id))
      const addSet = new Set(employeesToAdd.map(e => e.id))
      for (const employee of data.docs) {
        if (removeSet.has(employee.id)) {
          batch.update(employee.ref, "dept", FieldValue.arrayRemove(department.id))
        }
        if (addSet.has(employee.id)) {
          batch.update(employee.ref, "dept", FieldValue.arrayUnion(department.id))
        }
      }
      batch.set(departmentDoc, department)
      await batch.commit()
      //We now have all Employees in the given Department; after this
      // Compare with employeesToRemove and use arrayRemove with the departmentId
      // Compare with employeesToAdd and use arrayUnion with the departmentId
      // const res = await departmentDoc.set(department)
    } catch (e) {
      console.error(e)
    }
  }

  public async deleteDepartment(department: Department) {
    try {
      const batch = this.afs.firestore.batch();
      const departmentDoc = this.afs.firestore.doc(`/clients/${this.clientId}/departments/${department.id}`)
      const employeeCollection = this.afs.firestore.collection(`/clients/${this.clientId}/employees/`)
        .where("dept", "array-contains", department.id)
      const data = await employeeCollection.get()

      for (const employee of data.docs) {
        batch.update(employee.ref, "dept", FieldValue.arrayRemove(department.id))
      }
      batch.delete(departmentDoc)
      await batch.commit()

    } catch (e) {
      console.error(e)
    }
  }

  public async getDepartments() {
    try {
      const departmentDoc = this.afs.firestore.collection(`/clients/${this.clientId}/departments`)
      const res = await departmentDoc.get()
      return res.docs.map(d => {
        const data = d.data()
        return {name: data["name"], id: data["id"], employees: data["employees"]}
      })
    } catch (e) {
      console.error(e)
    }
  }

  public async addGroup(group: {
    name: string;
    id?: string;
  }) {
    try {
      const id = this.helper.generatePushId()
      group.id = id
      const groupDoc = this.afs.firestore.doc(`/clients/${this.clientId}/groups/${id}`)
      const res = await groupDoc.set(group)
    } catch (e) {
      console.error(e)
    }
  }

  public async updateGroup(group: {
    name: string;
    id?: string;
  }, employeesToRemove: Employee[], employeesToAdd: Employee[]) {
    try {
      const batch = this.afs.firestore.batch()
      const groupDoc = this.afs.firestore.doc(`/clients/${this.clientId}/groups/${group.id}`)
      const employeeCollection = this.afs.firestore.collection(`/clients/${this.clientId}/employees/`)
      const data = await employeeCollection.get()
      const removeSet = new Set(employeesToRemove.map(e => e.id))
      const addSet = new Set(employeesToAdd.map(e => e.id))
      for (const employee of data.docs) {
        if (removeSet.has(employee.id)) {
          batch.update(employee.ref, "group", FieldValue.arrayRemove(group.id))
        }
        if (addSet.has(employee.id)) {
          batch.update(employee.ref, "group", FieldValue.arrayUnion(group.id))
        }
      }
      batch.set(groupDoc, group)
      await batch.commit()
      // const res = await groupDoc.set(group)
    } catch (e) {
      console.error(e)
    }
  }

  public async getGroups() {
    try {
      const groupDoc = this.afs.firestore.collection(`/clients/${this.clientId}/groups`)
      const res = await groupDoc.get()
      return res.docs.map(g => {
        const data = g.data()
        return {name: data["name"], id: data["id"], employees: data["employees"]}
      })
    } catch (e) {
      console.error(e)
    }
  }

  public async deleteGroup(group: Group){
      try {
        const batch = this.afs.firestore.batch();
        const groupDoc = this.afs.firestore.doc(`/clients/${this.clientId}/groups/${group.id}`)
        const employeeCollection = this.afs.firestore.collection(`/clients/${this.clientId}/employees/`)
          .where("group", "array-contains", group.id)
        const data = await employeeCollection.get()

        for (const employee of data.docs) {
          batch.update(employee.ref, "group", FieldValue.arrayRemove(group.id))
        }
        batch.delete(groupDoc)
        await batch.commit()

      } catch (e) {
        console.error(e)
      }
    }

  public async getAllEmployees() {
    try {
      const employeeCollection = this.afs.firestore.collection(`/clients/${this.clientId}/employees`)
      const res = await employeeCollection.get()
      return res.docs.map(e => ({...this.convertDBObjToEmployee(e.data()), id: e.id}))
    } catch (e) {
      console.error(e)
    }
  }

  public getSudoDocData() {
    return new Promise<any>((res, rej) => {
      const clientSudoDoc = this.afs.firestore.doc('/_clientSudoCol/' + this.clientId);

      clientSudoDoc.get().then(clientSudoDocSnap => {
        const data = clientSudoDocSnap.data();
        if (!data) {
          rej('Error435634');
          return;
        }
        res(data);
      }).catch(err => {
        rej('Error462358' + ' | err:' + err);
      });
    });
  }

  public getSudoDocDataInvoiceDocs() {
    return new Promise<any>((res, rej) => {
      const invoicesCol = this.afs.firestore.collection('/_clientSudoCol/' + this.clientId + '/invoices');
      invoicesCol.get().then(snapList => {
        res(snapList.docs);
      }).catch(err => {
        rej('Error022837' + ' | err:' + err);
      });
    });
  }

  // private removeCurrUser(errorCB: (err: string) => void, succedCB: () => void) {

  // }

  public deleteUser(email: string, password: string): Promise<any> {
    return new Promise<any>((res, rej) => {
      if (email !== this._mail) {
        rej(Ci18n.translate('E-Mail-Adresse inkorrekt'));
        return;
      }

      if (this.isUsersEmployeeAdmin()) {
        rej(Ci18n.translate('Admin-Profil kann nicht gelöscht werden'));
        return;
      }

      this.afAuth.signInWithEmailAndPassword(email, password).then(auth => {
        const batch = this.afs.firestore.batch();
        // delete user atr. from employee (patch from users-entry)
        const employeeDocRef = this.afs.firestore.doc('/clients/' + this.clientId + '/employees/' + this._usersEmployeeId);
        const employeeDocData = {
          user: FieldValue.delete()
        };
        // set access-doc to empty obj
        const accessesDocRef = this.afs.firestore.doc('/clients/' + this.clientId + '/accesses/' + this._usersEmployeeId);
        const accessesDocData = {};
        // remove msmp entry
        const msmpDocRef = this.afs.firestore.doc('/msmp/' + email);
        // remove user entry
        const userDocRef = this.afs.firestore.doc('/users/' + this._uId);

        batch.update(employeeDocRef, employeeDocData);
        batch.set(accessesDocRef, accessesDocData);
        batch.delete(msmpDocRef);
        batch.delete(userDocRef);

        batch.commit().then(() => {
          // delete User from firestore
          auth.user.delete().then(() => {
            res();
          }).catch(err => {
            rej('Error616146' + ' err: ' + err);
          });
        }).catch(err => {
          rej('Error847362' + ' err: ' + err);
        });
      }).catch(err => {
        rej('Error457354' + ' err: ' + err);
      });
    });
  }

  private preloadData(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this._dataPreloaded === true) {
        /* ec */
        console.error('Error572951');
        reject(Ci18n.translate('Doppelte Anfrage verweigert'));
        return;
      }
      this._dataPreloaded = true;

      let userEmployeeLoaded = false;
      let userEmployeeAssignmentsLoaded = false;
      let employeeNamesLoaded = false;
      let employeeAccessToLoaded = false;
      let projectsLoaded = false;

      const checkIfAllLoaded: () => void = () => {
        if (
          userEmployeeLoaded &&
          employeeNamesLoaded &&
          userEmployeeAssignmentsLoaded &&
          employeeAccessToLoaded &&
          projectsLoaded
        ) {
          resolve();
          return;
        }
      };

      this.syncEmployeeNames().then(
        val1 => {
          employeeNamesLoaded = true;
          this.syncUsersEmployee().then(
            val2 => {
              userEmployeeLoaded = true;
              this.startSyncEmployeeAssignmets(this._usersEmployeeId).then(
                val3 => {
                  userEmployeeAssignmentsLoaded = true;
                  checkIfAllLoaded();
                },
                err => {
                  /* ec */
                  console.error('Error778368');
                  reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                }
              ).catch(err => {
                /* ec */
                console.error('Error037851');
                reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
              });

              if (!this._usersEmployeeIsCA) {
                this.syncEmployeeAccessTo().then(
                  val4 => {
                    employeeAccessToLoaded = true;
                    // startLoadUsersEmployeeAccessesToAssignment();
                    checkIfAllLoaded();
                  },
                  err => {
                    /* ec */
                    console.error('Error673419');
                    reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                  }
                ).catch(err => {
                  /* ec */
                  console.error('Error108750');
                  reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                });
              } else {
                this.syncAllEmployees().then(
                  val4 => {
                    employeeAccessToLoaded = true;
                    // startLoadUsersEmployeeAccessesToAssignment();
                    checkIfAllLoaded();
                  },
                  err => {
                    /* ec */
                    console.error('Error432760');
                    reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                  }
                ).catch(err => {
                  /* ec */
                  console.error('Error176985');
                  reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                });
              }
            },
            err => {
              /* ec */
              console.error('Error374215');
              reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          ).catch(err => {
            /* ec */
            console.error('Error591875');
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          });
        },
        err => {
          /* ec */
          console.error('Error967874');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      ).catch(err => {
        console.error(err)
        /* ec */
        console.error('Error341517');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });

      this.syncProjects().then(
        val5 => {
          projectsLoaded = true;
          checkIfAllLoaded();
        },
        err => {
          /* ec */
          console.error('Error235714');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      ).catch(err => {
        console.error(err)
        /* ec */
        console.error('Error134623');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
    });
  }

  // private loadUserData(succedCB: () => void, errorCB: (err: string) => void) {

  // }

  private syncUsersEmployee() {
    return new Promise((resolve, reject) => {
      const userEmployeeProfileDoc = this.afs.firestore.doc(this._userEmployeeProfileDocPath);
      this._userEmployeeProfileUnsubFunc = userEmployeeProfileDoc.onSnapshot({
        next: employeeSnap => {
          const userEmployeeProfileData = employeeSnap.data();

          const employee: Employee = this.convertDBObjToEmployee(userEmployeeProfileData);

          let oldEmployeeProfile;
          if (!!this._usersEmployeeProfile) {
            oldEmployeeProfile = this.copyEmployee(this._usersEmployeeProfile);
          }

          this._usersEmployeeId = employeeSnap.id;
          this._usersEmployeeProfile = employee;
          this._usersEmployeeName = employee.name;
          this._usersEmployeeIsCA = (employee['ca'] === true);
          this._userEmployeeIsScheduler = (employee['scheduler'] !== false); // undefined => true
          this._userEmployeeCanSelfEdit = (employee['selfEdit'] !== false); // undefined => true

          resolve(employeeSnap.id);

          const userIsInactive = (userEmployeeProfileData['inactive'] === true);

          if (!!oldEmployeeProfile) {
            if (userIsInactive) {
              window.location.reload();
              return;
            } else {
              this.usersEmployeeChange.emit(employee);
            }
          } else {
            reject('employee is inactive');
          }
        },
        error: err => {
          reject('unexpect error occurred while getting users employee profile');
        },
        complete: () => {
        }
      });
    });
  }

  private syncEmployeeAccessTo() {
    return new Promise((resolve, reject) => {
      if (!this._usersEmployeeProfile) {
        /* ec */
        console.error('Error320875');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const employeesAccessesDoc = this.afs.doc(
        '/clients/' + this.clientId + '/accesses/' + this._usersEmployeeId
      );
      const tempSub = employeesAccessesDoc
        .valueChanges()
        .subscribe({
          next: accessesSnap => {
            const newAccessesDoc = accessesSnap;
            const oldAccessesDoc = this._usersAccessesDoc;

            if (!oldAccessesDoc) {
              // first time running this func... => init loop
              const newATs: Access[] = [];
              const newATIs: number[] = [];

              let tempCount: number;
              for (const empId of Object.keys(newAccessesDoc)) {
                if (!tempCount) {
                  tempCount = 0;
                } else {
                  tempCount++;
                }

                const write: boolean = newAccessesDoc[empId];
                const empName = this.getEmployeeName(empId);

                const newAccess = new Access(empId, write);
                newAccess['name'] = empName;
                newATs.push(newAccess);
                newATIs.push(tempCount);
              }

              // let rejected = false;
              const syncsSucceededIds: string[] = [];

              this._usersEmployeeProfile.accessesTo = newATs;
              this._usersAccessesDoc = newAccessesDoc;

              if (newATs.length === 0) {
                // if no accesses e.g. when first user logs in first time
                resolve();
                return;
              }

              newATs.forEach(newAT => {
                this.startSyncEmployeeAccessTo(
                  newAT.key,
                  () => {
                    if (syncsSucceededIds.indexOf(newAT.key) === -1) {
                      syncsSucceededIds.push(newAT.key);

                      if (syncsSucceededIds.length === newATs.length) {
                        resolve();
                      }

                      const returnAT: Access = this.copyAccess(newAT);
                      const tempI = this._usersAccesses.push(newAT) - 1;

                      this.usersAccessAdd.emit([returnAT, tempI]);
                    }
                  },
                  err => {
                    /* ec */
                    console.error('Error012986');
                    reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                  }
                );
              });
            } else {
              const newATs: Access[] = [];
              const oldATs: Access[] = this._usersEmployeeProfile.accessesTo;
              const oldATIds: string[] = [];
              const oldATIWs: boolean[] = [];

              let tempCount: number;
              for (const empId of Object.keys(newAccessesDoc)) {
                if (!tempCount) {
                  tempCount = 0;
                } else {
                  tempCount++;
                }
                const write: boolean = newAccessesDoc[empId];
                const empName = this.getEmployeeName(empId);

                const newAccess = new Access(empId, write);
                newAccess['name'] = empName;
                newATs.push(newAccess);
              }

              for (let i = 0; i < oldATs.length; i++) {
                oldATIds.push(oldATs[i].key);
                oldATIWs.push(oldATs[i].write);
              }

              const addedATs: Access[] = [];
              const removedATs: Access[] = [];
              const changedATs: Access[] = [];
              const unchangedATs: Access[] = [];

              const removedIds: string[] = oldATIds.slice(0);

              for (let i = 0; i < newATs.length; i++) {
                const newAT = newATs[i];

                if (oldATIds.indexOf(newAT.key) === -1) {
                  addedATs.push(newAT);
                } else {
                  {
                    const tempI = oldATIds.indexOf(newAT.key);

                    if (newAT.write === oldATIWs[tempI]) {
                      unchangedATs.push(newAT);
                    } else {
                      changedATs.push(newAT);
                    }
                  }
                }

                const tempI2 = removedIds.indexOf(newAT.key);
                removedIds.splice(tempI2, 1);
              }

              removedIds.forEach(removedId => {
                const tempI = oldATIds.indexOf(removedId);
                removedATs.push(oldATs[tempI]);
              });

              addedATs.forEach(addedAT => {
                this.startSyncEmployeeAccessTo(
                  addedAT.key,
                  () => {
                    const returnAT: Access = this.copyAccess(addedAT);
                    const tempI = this._usersAccesses.push(addedAT) - 1;

                    this.usersAccessAdd.emit([returnAT, tempI]);
                  },
                  err => {
                    /* ec */
                    console.error('Error304827');
                    return;
                  }
                );
              });

              removedATs.forEach(removedAT => {
                this.stopSyncEmployeeAccessTo(removedAT.key);

                for (let i = 0; i < this._usersAccesses.length; i++) {
                  const tempAccess = this._usersAccesses[i];
                  if (!tempAccess) {
                    continue;
                  }

                  if (tempAccess.key === removedAT.key) {
                    this.usersAccessRemove.emit([tempAccess, i]);
                    this._usersAccesses[i] = null;
                    break;
                  }
                }
              });

              changedATs.forEach(changedAT => {
                for (let i = 0; i < this._usersAccesses.length; i++) {
                  const tempAccess = this._usersAccesses[i];
                  if (!tempAccess) {
                    continue;
                  }

                  if (tempAccess.key === changedAT.key) {
                    this.usersAccessChange.emit([changedAT, i]);
                    this._usersAccesses[i] = changedAT;
                    break;
                  }
                }
              });

              this._usersEmployeeProfile.accessesTo = newATs;
              this._usersAccessesDoc = newAccessesDoc;

              if (!!oldAccessesDoc) {
                this.usersEmployeeChange.emit(this._usersEmployeeProfile);
              }
            }
          },
          error: err => {
            /* ec */
            console.error('Error667921');
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }
        });

      this._subPool.push(tempSub);
    });
  }

  private syncAllEmployees() {
    return new Promise((resolve, reject) => {
      if (!this._usersEmployeeProfile) {
        /* ec */
        console.error('Error821436');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const employeesCol = this.afs.collection(
        'clients/' + this.clientId + '/employees'
      );

      this._employeeAddedSub = employeesCol
        .stateChanges(['added'])
        .subscribe({
          next: employeeSnaps => {
            const empIds: string[] = [];

            for (let i2 = 0; i2 < employeeSnaps.length; i2++) {
              const employeeSnap = employeeSnaps[i2];
              const employeeId = employeeSnap.payload.doc.id;

              const tempDocData = employeeSnap.payload.doc.data();
              const emplyoeeIsInactive = (tempDocData['inactive'] === true);
              if (!emplyoeeIsInactive) {
                empIds.push(employeeId);
              }
            }

            const syncsSucceededIds: string[] = [];

            let i = -1;
            empIds.forEach(empId => {
              i++;

              if (empId === this._usersEmployeeId) {
                if (syncsSucceededIds.indexOf(empId) === -1) {
                  syncsSucceededIds.push(empId);

                  if (syncsSucceededIds.length === empIds.length) {
                    resolve();
                  }
                }
                return; // forEach continue
              }

              this.startSyncEmployeeAccessTo(
                empId,
                () => {
                  if (syncsSucceededIds.indexOf(empId) === -1) {
                    syncsSucceededIds.push(empId);

                    if (syncsSucceededIds.length === empIds.length) {
                      resolve();
                    }

                    const returnAT = new Access(empId, true);

                    const empI = this._employeeNamesIds.indexOf(empId);
                    if (empI === -1) {
                      /* ec */
                      console.error('Error219812');
                      reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                    }

                    returnAT['name'] = this._employeeNames[empI];
                    const tempI = this._usersAccesses.push(returnAT) - 1;

                    this.usersAccessAdd.emit([returnAT, tempI]);
                  }
                },
                err => {
                  /* ec */
                  console.error('Error012986');
                  reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                }
              );
            });
          },
          error: err => {
            /* ec */
            console.error('Error230946');
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }
        });

      this._employeeRemoveSub = employeesCol
        .stateChanges(['removed'])
        .subscribe({
          next: employeeSnaps => {
            const empIds: string[] = [];
            for (let i2 = 0; i2 < employeeSnaps.length; i2++) {
              const employeeSnap = employeeSnaps[i2];
              const employeeId = employeeSnap.payload.doc.id;
              empIds.push(employeeId);
            }

            empIds.forEach(empId => {
              this.stopSyncEmployeeAccessTo(empId);
            });
          },
          error: err => {
            /* ec */
            console.error('Error032462');
          }
        });
    });
  }

  // private syncEmployeeAccessBy() {

  // }

  private convertDBObjToEmployee(employeeDbObj: {}): Employee {
    const returnValue: Employee = new Employee();
    returnValue.name = employeeDbObj['name'];
    returnValue.number = employeeDbObj['number'];
    returnValue.group = employeeDbObj['group'];
    returnValue.dept = employeeDbObj['dept'];
    returnValue.otherInformation = employeeDbObj['otherInformation']
    returnValue.user = employeeDbObj['user'];
    returnValue.blockedAt = employeeDbObj['blockedAt'];
    returnValue.id = employeeDbObj['id'];

    if (!!employeeDbObj['ca']) {
      returnValue['ca'] = employeeDbObj['ca'];
    }

    returnValue.scheduler = employeeDbObj['scheduler'] !== false; // undefined => true
    returnValue.selfEdit = employeeDbObj['selfEdit'] !== false; // undefined => true

    const tempAccessesBy_objArr: {}[] = employeeDbObj['accessesBy'];
    const tempAccessesTo_objArr: {}[] = employeeDbObj['accessesTo'];

    const tempAccessesBy: Access[] = [];
    const tempAccessesTo: Access[] = [];

    if (!!tempAccessesBy_objArr) {
      tempAccessesBy_objArr.forEach(element => {
        const tempAccessBy = new Access(element['key'], element['write']);

        const empName = this.getEmployeeName(element['key']);
        if (!empName) {
          /* ec */
          console.error('Error548763');
        }
        tempAccessBy['name'] = empName;

        tempAccessesBy.push(tempAccessBy);
      });
    }

    if (!!tempAccessesTo_objArr) {
      tempAccessesTo_objArr.forEach(element => {
        const tempAccessTo = new Access(element['key'], element['write']);

        const empName = this.getEmployeeName(element['key']);
        if (!empName) {
          /* ec */
          console.error('Error548763');
        }
        tempAccessTo['name'] = empName;

        tempAccessesTo.push(tempAccessTo);
      });
    }

    returnValue.accessesBy = tempAccessesBy;
    returnValue.accessesTo = tempAccessesTo;

    if (!returnValue.name || !returnValue.number) {
      return null;
    }

    return returnValue;
  }

  private convertEmployeeToDBObj(employee: Employee): {} {
    const returnValue: {} = {};

    if (employee.name) {
      returnValue['name'] = employee.name;
    }
    if (employee.number) {
      returnValue['number'] = employee.number;
    }
    if (employee.group) {
      returnValue['group'] = employee.group;
    }
    if (employee.dept) {
      returnValue['dept'] = employee.dept;
    }
    if (employee.user !== null && employee.user !== undefined) {
      returnValue['user'] = employee.user;
    }
    if (employee.blockedAt) {
      returnValue['blockedAt'] = employee.blockedAt;
    }
    if (employee.scheduler) {
      returnValue['scheduler'] = employee.scheduler;
    }
    if (employee.selfEdit) {
      returnValue['selfEdit'] = employee.selfEdit;
    }

    const tempAccessesBy: {}[] = [];
    const tempAccessesTo: {}[] = [];

    if (!!employee.accessesBy) {
      employee.accessesBy.forEach(element => {
        const tempAccessBy = {};
        tempAccessBy['key'] = element.key;
        tempAccessBy['write'] = element.write;
        tempAccessesBy.push(tempAccessBy);
      });
    }
    if (!!employee.accessesTo) {
      employee.accessesTo.forEach(element => {
        const tempAccessTo = {};
        tempAccessTo['key'] = element.key;
        tempAccessTo['write'] = element.write;
        tempAccessesTo.push(tempAccessTo);
      });
    }
    if (tempAccessesBy.length > 0) {
      returnValue['accessesBy'] = tempAccessesBy;
    }
    if (tempAccessesTo.length > 0) {
      returnValue['accessesTo'] = tempAccessesTo;
    }

    if (!returnValue['name'] || !returnValue['number']) {
      /* ec */
      console.warn('Error457532');
      return null;
    }

    return returnValue;
  }

  private convertDBObjToProject(projectDbObj: {}): Project {
    const returnValue: Project = new Project();

    returnValue.name = projectDbObj['name'];
    returnValue.number = projectDbObj['number'];
    returnValue.color = projectDbObj['color'];
    returnValue.duration = projectDbObj['duration'];
    returnValue.timeToAllocate = projectDbObj['timeToAllocate'];
    returnValue.note = projectDbObj['note'];
    returnValue.cloudLink = projectDbObj['cloudLink'];
    returnValue.reserved = projectDbObj['reserved'];
    returnValue.marker = projectDbObj['marker'];
    returnValue.isConflicted = projectDbObj['isConflicted'];
    returnValue.blockedAt = projectDbObj['blockedAt'];
    returnValue.finished = projectDbObj['finished'];

    if (
      !returnValue.name ||
      !returnValue.number ||
      returnValue.duration === NaN ||
      returnValue.timeToAllocate === NaN
    ) {
      /* ec */
      console.error('Error102937');
      return null;
    }

    return returnValue;
  }

  private convertProjectToDBObj(project: Project): {} {
    const returnValue: {} = {};

    if (!!project.name) {
      returnValue['name'] = project.name;
    }
    if (!!project.number) {
      returnValue['number'] = project.number;
    }
    if (!!project.color) {
      returnValue['color'] = project.color;
    }
    if (project.duration !== NaN) {
      returnValue['duration'] = project.duration;
    }
    if (project.timeToAllocate !== NaN) {
      returnValue['timeToAllocate'] = project.timeToAllocate;
    }
    if (!!project.note) {
      returnValue['note'] = project.note;
    }
    if (!!project.cloudLink) {
      returnValue['cloudLink'] = project.cloudLink;
    }
    if (project.reserved !== null && project.reserved !== undefined) {
      returnValue['reserved'] = project.reserved;
    }
    if (!!project.marker) {
      returnValue['marker'] = project.marker;
    }
    if (project.isConflicted !== null && project.isConflicted !== undefined) {
      returnValue['isConflicted'] = project.isConflicted;
    }
    if (!!project.blockedAt) {
      returnValue['blockedAt'] = project.blockedAt;
    }
    if (!!project.finished) {
      returnValue['finished'] = project.finished;
    }

    if (
      !returnValue['name'] ||
      !returnValue['number'] ||
      returnValue['duration'] === NaN ||
      returnValue['timeToAllocate'] === NaN
    ) {
      /* ec */
      console.warn('Error592378');
      return null;
    }

    return returnValue;
  }

  private convertDBObjToAssignment(assignmentDbObj: {}, id:string): Assignment {
    const returnValue: Assignment = new Assignment();

    returnValue.employeeId = assignmentDbObj['employeeId'];
    returnValue.projectId = assignmentDbObj['projectId'];
    returnValue.start = assignmentDbObj['start'];
    returnValue.end = assignmentDbObj['end'];
    returnValue.employeeId_start = assignmentDbObj['employeeId_start'];
    returnValue.employeeId_end = assignmentDbObj['employeeId_end'];
    returnValue.employeeId_projectId = assignmentDbObj['employeeId_projectId'];
    returnValue.projectId_start = assignmentDbObj['projectId_start'];
    returnValue.projectId_end = assignmentDbObj['projectId_end'];
    returnValue.note = assignmentDbObj['note'];
    returnValue.reserved = assignmentDbObj['reserved'];
    returnValue.isConflicted = assignmentDbObj['isConflicted'];
    returnValue.blockedAt = assignmentDbObj['blockedAt'];
    returnValue.id = id

    if (
      !returnValue.employeeId ||
      !returnValue.projectId ||
      !returnValue.start ||
      !returnValue.start ||
      !returnValue.end ||
      !returnValue.start
    ) {
      /* ec */
      console.warn('Error103972');
      return null;
    }

    return returnValue;
  }

  private storeAddedProject(projectId: string, project: Project) {
    const setCreatedAtAndContinue: (arg: number) => void =
      (ts) => {
        project['createdAt'] = ts;

        this._projectIds.push(projectId);
        this._projects.push(project);

        this.projectAdd.emit([
          this.copyProject(project),
          this._projects.length - 1
        ]);
      };

    this.getProjectCreatedAt(projectId)
      .then(ts => {
        // wait until projects are loaded
        setCreatedAtAndContinue(ts);
      })
      .catch(() => {
        /* ec */
        console.warn('Error539815');
        setCreatedAtAndContinue(Date.now());
      })
      .finally(() => {
        this.numberOfProjectsHaveToLoad = this.numberOfProjectsHaveToLoad + 1;
        if (this.numberOfProjectsHaveToLoad === this.numberOfProjectsAlreadyLoaded) {
          this.allProjectsAreLoaded = true;
        }
      });
  }

  private storeChangedProject(projectId: string, project: Project) {
    const projectIdsIndex = this._projectIds.indexOf(projectId);

    if (projectIdsIndex === -1) {
      /* ec */
      console.error('Error187006');
      return;
    }

    const setCreatedAtAndContinue: (arg: number) => void =
      (ts) => {
        project['createdAt'] = ts;

        this._projects[projectIdsIndex] = project;
        this.projectChange.emit([this.copyProject(project), projectIdsIndex]);
      };

    this.getProjectCreatedAt(projectId)
      .then(ts => {
        setCreatedAtAndContinue(ts);
      })
      .catch(() => {
        /* ec */
        console.error('Error082516');
        setCreatedAtAndContinue(Date.now());
      });
  }

  private storeRemovedProject(projectId, project) {
    const projectIdsIndex = this._projectIds.indexOf(projectId);

    if (projectIdsIndex === -1) {
      /* ec */
      console.error('Error634049');
      return;
    }

    this._projectIds.splice(projectIdsIndex, 1);
    this._projects.splice(projectIdsIndex, 1);

    this.projectRemove.emit([this.copyProject(project), projectIdsIndex]);
  }

  private storeAddedProjectName(projectId: string, projectName: string) {
    this._projectNamesIds.push(projectId);
    this._projectNames.push(projectName);

    this.projectNameAdd.emit([projectName, this._projectNames.length - 1]);
  }

  private storeChangedProjectName(projectId: string, projectName: string) {
    const projectNamesIndex = this._projectNamesIds.indexOf(projectId);

    if (projectNamesIndex === -1) {
      /* ec */
      console.error('Error403754');
      return;
    }

    if (this._projectNames[projectNamesIndex] === projectName) {
      return;
    }
    this._projectNames[projectNamesIndex] = projectName;

    this.projectNameChange.emit([projectName, projectNamesIndex]);
  }

  private storeRemovedProjectName(projectId: string, projectName: string) {
    const projectNamesIndex = this._projectNamesIds.indexOf(projectId);

    if (projectNamesIndex === -1) {
      /* ec */
      console.error('Error634049');
      return;
    }

    this._projectNamesIds.splice(projectNamesIndex, 1);
    this._projectNames.splice(projectNamesIndex, 1);

    this.projectNameRemove.emit([projectName, projectNamesIndex]);
  }

  private syncProjects() {
    return new Promise<void | string>((resolve, reject) => {
      const projectCol = this.afs.collection(
        'clients/' + this.clientId + '/projects'
      );

      this.unsubscribeProjects();

      {
        const tempSub = projectCol.valueChanges().subscribe({
          next: projSnaps => {
            tempSub.unsubscribe();
            if (projSnaps.length === 0) {
              resolve();
            }
          },
          error: err => {
            tempSub.unsubscribe();
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }
        });
      }

      this._projectAddedSub = projectCol.stateChanges(['added']).subscribe({
        next: projectSnaps => {
          // save number of projects --> to wait until projects are loaded
          this.numberOfProjectsAlreadyLoaded = projectSnaps.length;
          for (let i = 0; i < projectSnaps.length; i++) {
            const projectSnap = projectSnaps[i];
            const projectId = projectSnap.payload.doc.id;
            const projectObj = projectSnap.payload.doc.data();

            const project: Project = this.convertDBObjToProject(projectObj);
            if (!project) {
              /* ec */
              console.error('Error636242');
              return;
            }

            this.storeAddedProject(projectId, project);
            this.storeAddedProjectName(projectId, project.name);
          }
          resolve();
        },
        error: err => {
          /* ec */
          console.error('Error610460');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        },
        complete: () => {
          /* ec */
          console.error('Error103123');
        }
      });

      this._projectModifiedSub = projectCol
        .stateChanges(['modified'])
        .subscribe({
          next: projectSnaps => {
            for (let i = 0; i < projectSnaps.length; i++) {
              const projectSnap = projectSnaps[i];
              const projectId = projectSnap.payload.doc.id;
              const projectObj = projectSnap.payload.doc.data();

              const project: Project = this.convertDBObjToProject(projectObj);
              if (!project) {
                /* ec */
                console.error('Error465143');
                return;
              }

              this.storeChangedProject(projectId, project);
              this.storeChangedProjectName(projectId, project.name);
            }
          },
          error: err => {
            /* ec */
            console.error('Error128650');
          },
          complete: () => {
            /* ec */
            console.error('Error077950');
          }
        });

      this._projectRemoveSub = projectCol.stateChanges(['removed']).subscribe({
        next: projectSnaps => {
          for (let i = 0; i < projectSnaps.length; i++) {
            const projectSnap = projectSnaps[i];
            const projectId = projectSnap.payload.doc.id;
            const projectObj = projectSnap.payload.doc.data();

            const project: Project = this.convertDBObjToProject(projectObj);
            if (!project) {
              /* ec */
              console.error('Error427267');
              return;
            }

            this.storeRemovedProject(projectId, project);
            this.storeRemovedProjectName(projectId, project.name);
          }
        },
        error: err => {
          /* ec */
          console.error('Error128237');
        },
        complete: () => {
          /* ec */
          console.error('Error958200');
        }
      });
    });
  }

  private storeAddedEmployeeName(employeeId: string, employeeName: string) {
    this._employeeNamesIds.push(employeeId);
    this._employeeNames.push(employeeName);
    this.employeeNameAdd.emit([employeeName, this._employeeNames.length - 1]);
  }

  private storeChangedEmployeeName(employeeId: string, employeeName: string) {
    const employeeNameIndex = this._employeeNamesIds.indexOf(employeeId);

    if (employeeNameIndex === -1) {
      /* ec */
      console.error('Error123098');
      return;
    }

    if (this._employeeNames[employeeNameIndex] === employeeName) {
      return;
    }
    this._employeeNames[employeeNameIndex] = employeeName;

    this.employeeNameChange.emit([employeeName, employeeNameIndex]);
  }

  private storeRemovedEmployeeName(employeeId, employeeName) {
    const employeeNamesIndex = this._employeeNamesIds.indexOf(employeeId);

    if (employeeNamesIndex === -1) {
      /* ec */
      console.error('Error634049');
      return;
    }

    this._employeeNamesIds.splice(employeeNamesIndex, 1);
    this._employeeNames.splice(employeeNamesIndex, 1);

    this.employeeNameRemove.emit([employeeName, employeeNamesIndex]);
  }

  private syncEmployeeNames() {
    return new Promise<void | string>((resolve, reject) => {
      const employeesCol = this.afs.collection(
        'clients/' + this.clientId + '/employees'
      );

      this.unsubscribeEmployeeNames();

      {
        const tempSub = employeesCol.valueChanges().subscribe({
          next: emplSnaps => {
            tempSub.unsubscribe();
            if (emplSnaps.length === 0) {
              reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          },
          error: err => {
            tempSub.unsubscribe();
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }
        });
      }

      this._employeeNameAddedSub = employeesCol
        .stateChanges(['added'])
        .subscribe({
          next: employeeSnaps => {
            for (let i = 0; i < employeeSnaps.length; i++) {
              const employeeSnap = employeeSnaps[i];
              const employeeId = employeeSnap.payload.doc.id;
              const employeeObj = employeeSnap.payload.doc.data();

              const employeeName: string = employeeObj['name'];
              if (!employeeName) {
                /* ec */
                console.error('Error228787');
                return;
              }

              const employeeInactive: boolean = employeeObj['inactive'] === true;

              if (!employeeInactive) {
                this.storeAddedEmployeeName(employeeId, employeeName);
              } else {
                this._inactiveEmployeeNames.push(employeeName);
                this._inactiveEmployeeNamesIds.push(employeeId);
              }
            }
            resolve();
          },
          error: err => {
            /* ec */
            console.error('Error105894');
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          },
          complete: () => {
            /* ec */
            console.error('Error508061');
          }
        });

      this._employeeNameModifiedSub = employeesCol
        .stateChanges(['modified'])
        .subscribe({
          next: employeeSnaps => {
            for (let i = 0; i < employeeSnaps.length; i++) {
              const employeeSnap = employeeSnaps[i];
              const employeeId = employeeSnap.payload.doc.id;
              const employeeObj = employeeSnap.payload.doc.data();

              const employeeName: string = employeeObj['name'];
              if (!employeeName) {
                /* ec */
                console.error('Error465095');
                return;
              }
              const employeeIsInactive: boolean = employeeObj['inactive'] === true;
              const employeeWasInactive: boolean = this._inactiveEmployeeNamesIds.indexOf(employeeId) !== -1;

              if (!employeeIsInactive && !employeeWasInactive) {
                this.storeChangedEmployeeName(employeeId, employeeName);
              } else if (!employeeIsInactive && employeeWasInactive) {
                // add employee name in acitve list and remove inactive list
                this.storeAddedEmployeeName(employeeId, employeeName);

                const tempI = this._inactiveEmployeeNames.indexOf(employeeId);
                this._inactiveEmployeeNamesIds.splice(tempI, 1);
                this._inactiveEmployeeNames.splice(tempI, 1);
              } else if (employeeIsInactive && !employeeWasInactive) {
                // removeEmployee name from active and add to inactive
                const oldName = this.getEmployeeName(employeeId);
                if (!oldName) {
                  /* ec */
                  console.error('Error468464');
                  return;
                }
                this.storeRemovedEmployeeName(employeeId, oldName);
                this._inactiveEmployeeNamesIds.push(employeeId);
                this._inactiveEmployeeNames.push(employeeName);
              } else if (employeeIsInactive && employeeWasInactive) {
                // probably never gonna happen.. since u cant change a name of an inactive employee....
                const tempI = this._inactiveEmployeeNames.indexOf(employeeId);
                if (this._inactiveEmployeeNames[tempI] === employeeName) {
                  return;
                }
                this._inactiveEmployeeNames[tempI] = employeeName;
              }
            }
          },
          error: err => {
            /* ec */
            console.error('Error159681');
          },
          complete: () => {
            /* ec */
            console.error('Error049025');
          }
        });

      this._employeeNameRemoveSub = employeesCol
        .stateChanges(['removed'])
        .subscribe({
          next: employeeSnaps => {
            for (let i = 0; i < employeeSnaps.length; i++) {
              const employeeSnap = employeeSnaps[i];
              const employeeId = employeeSnap.payload.doc.id;
              const employeeObj = employeeSnap.payload.doc.data();

              const employeeName: string = employeeObj['name'];
              if (!employeeName) {
                /* ec */
                console.error('Error957575');
                return;
              }

              const employeeInactive: boolean = employeeObj['inactive'] === true;

              if (!employeeInactive) {
                this.storeRemovedEmployeeName(employeeId, employeeName);
              } else {
                const tempI = this._inactiveEmployeeNamesIds.indexOf(employeeId);
                if (tempI === -1) {
                  /* ec */
                  console.error('Error568468');
                  return;
                }
                this._inactiveEmployeeNames.splice(tempI, 1);
                this._inactiveEmployeeNamesIds.splice(tempI, 1);
              }
            }
          },
          error: err => {
            /* ec */
            console.error('Error098751');
          },
          complete: () => {
            /* ec */
            console.error('Error985886');
          }
        });
    });
  }

  // private requestEmployeeName(empId: string): string {

  // }

  private startSyncEmployeeAccessTo(
    employeeId: string,
    afterInitCB: () => void,
    errorCB: (err: string) => void
  ) {
    const employeeDoc = this.afs.doc(
      'clients/' + this.clientId + '/employees/' + employeeId
    );

    const employeeAccessToSub = employeeDoc.valueChanges().subscribe({
      next: employeeSnap => {
        const employee: Employee = this.convertDBObjToEmployee(employeeSnap);

        const tempI = this._employeesAccessToIds.indexOf(employeeId);
        if (tempI === -1) {
          this._employeesAccessTo.push(employee);
          const tempI2 = this._employeesAccessToIds.push(employeeId) - 1;

          this.employeeAccessToAdd.emit([employee, tempI2]);

          const accessesDoc = this.afs.doc(
            'clients/' + this.clientId + '/accesses/' + employeeId
          );
          const employeeAccessToSub2 = accessesDoc.valueChanges().subscribe({
            next: accessesSnap => {
              const newATs: Access[] = [];
              for (const empId of Object.keys(accessesSnap ?? {})) {
                const write: boolean = accessesSnap[empId];
                const empName = this.getEmployeeName(empId);

                const newAccess = new Access(empId, write);
                newAccess['name'] = empName;
                newATs.push(newAccess);
              }
              const tempI3 = this._employeesAccessToIds.indexOf(employeeId);
              this._employeesAccessTo[tempI3].accessesTo = newATs;
            },
            error: err => {
              /* ec */
              console.error('Error448464');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          });

          this._employeeAccessesToSubsEmployeeIds2.push(employeeId);
          this._employeeAccessesToSubsList2.push(employeeAccessToSub2);

          const accessesCol = this.afs.collection(
            'clients/' + this.clientId + '/accesses/',
            ref => ref.where(employeeId, '>=', false)
          );

          const employeeAccessToSub3 = accessesCol.stateChanges().subscribe({
            next: accessesSnaps => {
              const employeeI = this._employeesAccessToIds.indexOf(employeeId);

              accessesSnaps.forEach(accessesSnap => {
                const tempEmpId = accessesSnap.payload.doc.id;
                const tempData = accessesSnap.payload.doc.data();
                const write: boolean = tempData[employeeId];
                const empName = this.getEmployeeName(tempEmpId);

                for (
                  let i = 0;
                  i < this._employeesAccessTo[employeeI].accessesBy.length;
                  i++
                ) {
                  if (
                    this._employeesAccessTo[employeeI].accessesBy[i].key ===
                    tempEmpId
                  ) {
                    this._employeesAccessTo[employeeI].accessesBy[
                      i
                      ].write = write;
                    this._employeesAccessTo[employeeI].accessesBy[i][
                      'name'
                      ] = empName;
                    return; // forEach Continue
                  }
                }

                const newAccess = new Access(tempEmpId, write);
                newAccess['name'] = empName;

                this._employeesAccessTo[employeeI].accessesBy.push(newAccess);
              });
            },
            error: err => {
              /* ec */
              console.error('Error852500');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          });

          this._employeeAccessesToSubsEmployeeIds3.push(employeeId);
          this._employeeAccessesToSubsList3.push(employeeAccessToSub3);

          this.startSyncEmployeeAssignmets(employeeId).then(
            val => {
              afterInitCB();
            },
            err => {
              /* ec */
              console.error('Error124436');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          );
        } else {
          employee.accessesBy = this._employeesAccessTo[tempI].accessesBy;
          employee.accessesTo = this._employeesAccessTo[tempI].accessesTo;
          this._employeesAccessTo[tempI] = employee;
          this.employeeAccessToChange.emit([employee, tempI]);
        }
      },
      error: err => {
        /* ec */
        console.error('Error954110');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });

    this._employeeAccessesToSubsEmployeeIds.push(employeeId);
    this._employeeAccessesToSubsList.push(employeeAccessToSub);
  }

  private stopSyncEmployeeAccessTo(employeeId: string) {
    this.stopSyncEmployeeAssignments(employeeId);
    this.unsubscribeEmployeeAccessesTo(employeeId);

    const tempI = this._employeesAccessToIds.indexOf(employeeId);
    this._employeesAccessToIds.splice(tempI, 1);
    const removedEmployee: Employee = this._employeesAccessTo.splice(
      tempI,
      1
    )[0];

    this.employeeAccessToRemove.emit([removedEmployee, tempI]);
  }

  private storeAddedEmployeeAssignment(
    employeeId: string,
    assignmentId: string,
    assignment: Assignment
  ) {
    const employeeAssignmentsListI = this._employeeAssignmentsListIds.indexOf(
      employeeId
    );

    if (employeeAssignmentsListI === -1) {
      /* ec */
      console.error('Error827349');
      return;
    }

    if (this._assignmentIds.indexOf(assignmentId) !== -1) {
      /* ec */
      console.warn('Error827350');
    }

    this._employeeAssignmentsList[employeeAssignmentsListI].assignments.push(
      assignment
    );
    this._employeeAssignmentsList[employeeAssignmentsListI].assignmentIds.push(
      assignmentId
    );

    const newLength = this._assignments.push(assignment);
    this._assignmentIds.push(assignmentId);

    this.assignmentAdd.emit([this.helper.copyAssignment(assignment), newLength - 1]);
  }

  private storeChangedEmployeeAssignment(
    employeeId: string,
    assignmentId: string,
    assignment: Assignment
  ) {
    const employeeAssignmentsListI = this._employeeAssignmentsListIds.indexOf(
      employeeId
    );

    if (employeeAssignmentsListI === -1) {
      /* ec */
      console.error('Error023487');
      return;
    }

    if (this._assignmentIds.indexOf(assignmentId) === -1) {
      /* ec */
      console.error('Error023488');
      return;
    }

    const tempI = this._employeeAssignmentsList[
      employeeAssignmentsListI
      ].assignmentIds.indexOf(assignmentId);
    const assignmentI = this._assignmentIds.indexOf(assignmentId);

    if (tempI === -1) {
      /* ec */
      console.error('Error023489');
      return;
    }

    if (assignmentI === -1) {
      /* ec */
      console.error('Error023490');
      return;
    }

    const oldAssignmentCopy: Assignment = this.helper.copyAssignment(this._assignments[assignmentI]);
    const newAssignmentCopy: Assignment = this.helper.copyAssignment(assignment);
    const newBlockedAt = newAssignmentCopy['blockedAt'];
    const oldBlockedAt = oldAssignmentCopy['blockedAt'];
    delete oldAssignmentCopy['blockedAt'];
    delete newAssignmentCopy['blockedAt'];

    const areEqual = this.objectsAreEqual(oldAssignmentCopy, newAssignmentCopy);

    // ignore changes if no changes detected or is own blocked object
    if (areEqual && (!newBlockedAt && !oldBlockedAt || this.isListedBlockedObj(assignmentId))) {
      return;
    }

    this._employeeAssignmentsList[employeeAssignmentsListI].assignments[
      tempI
      ] = assignment;

    this._assignments[assignmentI] = assignment;

    this.assignmentChange.emit([this.helper.copyAssignment(assignment), assignmentI]);
  }

  private storeRemovedEmployeeAssignment(
    employeeId: string,
    assignmentId: string,
    assignment: Assignment
  ) {
    const employeeAssignmentsListI = this._employeeAssignmentsListIds.indexOf(
      employeeId
    );

    if (employeeAssignmentsListI === -1) {
      /* ec */
      console.error('Error507895');
      return;
    }

    if (this._assignmentIds.indexOf(assignmentId) === -1) {
      /* ec */
      console.error('Error507896');
      return;
    }

    const tempI = this._employeeAssignmentsList[
      employeeAssignmentsListI
      ].assignmentIds.indexOf(assignmentId);
    const assignmentI = this._assignmentIds.indexOf(assignmentId);

    if (tempI === -1) {
      /* ec */
      console.error('Error507897');
      return;
    }

    if (assignmentI === -1) {
      /* ec */
      console.error('Error507898');
      return;
    }

    this._employeeAssignmentsList[employeeAssignmentsListI].assignments.splice(
      tempI,
      1
    );
    this._employeeAssignmentsList[employeeAssignmentsListI].assignmentIds.splice(
      tempI,
      1
    );

    this._assignments[assignmentI] = null;
    this._assignmentIds[assignmentI] = null;

    this.assignmentRemove.emit([this.helper.copyAssignment(assignment), assignmentI]);
  }

  private startSyncEmployeeAssignmets(employeeId: string) {
    return new Promise<void | string>((resolve, reject) => {
      if (this._employeeAssignmentsListIds.indexOf(employeeId) !== -1) {
        /* ec */
        console.error('Error823470');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      this._employeeAssignmentsListIds.push(employeeId);
      const i1 =
        this._employeeAssignmentsList.push(new EmployeeAssignments()) - 1;

      this._employeeAssignmentsList[i1].employeeId = employeeId;
      this._employeeAssignmentsList[i1].assignments = [];
      this._employeeAssignmentsList[i1].assignmentIds = [];

      const employeesAssignmentCollection = this.afs.collection(
        'clients/' + this.clientId + '/assignments',
        ref =>
          ref
            .where('employeeId', '==', employeeId)
            .where('start', '>=', this.preloadLimiterTS)
      );

      // check if query returns emptyList... if yes... dont wait for 'added' to resolve
      {
        const tempSub = employeesAssignmentCollection.valueChanges().subscribe({
          next: val => {
            tempSub.unsubscribe();
            if (val.length === 0) {
              resolve();
            }
          },
          error: err => {
            tempSub.unsubscribe();
            /* ec */
            console.error('Error850964');
            console.error(err)
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }
        });
      }

      const employeeAssignmentsAddedSub = employeesAssignmentCollection
        .stateChanges(['added'])
        .subscribe({
          next: assignmentSnaps => {
            for (let i2 = 0; i2 < assignmentSnaps.length; i2++) {
              const assignmentSnap = assignmentSnaps[i2];
              const assignmentId = assignmentSnap.payload.doc.id;
              const assignmentObj = assignmentSnap.payload.doc.data();

              const assignment: Assignment = this.convertDBObjToAssignment(
                assignmentObj, assignmentId
              );
              assignment.id = assignmentId;
              if (!assignment) {
                /* ec */
                console.error('Error100111');
                reject();
                return;
              }

              this.storeAddedEmployeeAssignment(
                employeeId,
                assignmentId,
                assignment
              );
            }
            resolve();
          },
          error: err => {
            /* ec */
            console.error('Error850963');
            console.error(err)
            reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          },
          complete: () => {
            /* ec */
            console.error('Error883752');
          }
        });

      const employeeAssignmentsModifiedSub = employeesAssignmentCollection
        .stateChanges(['modified'])
        .subscribe({
          next: assignmentSnaps => {
            for (let i = 0; i < assignmentSnaps.length; i++) {
              const assignmentSnap = assignmentSnaps[i];
              const assignmentId = assignmentSnap.payload.doc.id;
              const assignmentObj = assignmentSnap.payload.doc.data();

              const assignment: Assignment = this.convertDBObjToAssignment(
                assignmentObj, assignmentId
              );
              if (!assignment) {
                /* ec */
                console.error('Error716802');
                return;
              }

              this.storeChangedEmployeeAssignment(
                employeeId,
                assignmentId,
                assignment
              );
            }
          },
          error: err => {
            /* ec */
            console.error('Error372987');
            console.error(err)
          },
          complete: () => {
            /* ec */
            console.error('Error780450');
          }
        });

      const employeeAssignmentsRemovedSub = employeesAssignmentCollection
        .stateChanges(['removed'])
        .subscribe({
          next: assignmentSnaps => {
            for (let i = 0; i < assignmentSnaps.length; i++) {
              const assignmentSnap = assignmentSnaps[i];
              const assignmentId = assignmentSnap.payload.doc.id;

              const assignmentI = this._assignmentIds.indexOf(assignmentId);
              const assignment = this._assignments[assignmentI];

              if (!assignment) {
                /* ec */
                console.error('Error272884');
                return;
              }

              this.storeRemovedEmployeeAssignment(
                employeeId,
                assignmentId,
                assignment
              );
            }
          },
          error: err => {
            /* ec */
            console.error('Error125891');
            console.error(err)
          },
          complete: () => {
            /* ec */
            console.error('Error935025');
          }
        });

      this._employeeAssignmentsSubsEmployeeIds.push(employeeId);
      this._employeeAssignmentsAddedSubList.push(employeeAssignmentsAddedSub);
      this._employeeAssignmentsModifiedSubList.push(
        employeeAssignmentsModifiedSub
      );
      this._employeeAssignmentsRemovedSubList.push(
        employeeAssignmentsRemovedSub
      );

      const name = this.getEmployeeName(employeeId);
      if (!name) {
        /* ec */
        console.error('Error281727');
        return;
      }
      this.employeeTableExtend.emit(name);
    });
  }

  private stopSyncEmployeeAssignments(employeeId: string) {
    this.unsubscribeEmployeeAssignments(employeeId);
  }

  // private syncAssignmentConflicts() {
  // }

  public getProject_fast(projectId: string): Project {
    return this.helper.getProject(this._projectIds, this._projects, projectId);
  }

  private async getProjectFromFirebase(projectId: string): Promise<Project | { error: string }> {
    try {
      const projectSnap = await firebase
        .firestore()
        .doc('/clients/' + this.clientId + '/projects/' + projectId).get();

      if (!projectSnap.exists) {
        console.error('Error382601');
        throw new Error(Ci18n.translate('Projekt konnte nicht gefunden werden'));
      }

      const project = this.convertDBObjToProject(projectSnap.data());

      if (!project) {
        /* ec */
        console.error('Error290712');
        throw new Error(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }

      return project;
    } catch (e) {
      return {
        error: e,
      };
    }
  }

  private getProject(
    projectId,
    succedCB: (arg0: Project) => void,
    errorCB: (string) => void
  ) {
    const projectDoc = firebase
      .firestore()
      .doc('/clients/' + this.clientId + '/projects/' + projectId);

    projectDoc
      .get()
      .then(projectSnap => {
        if (!projectSnap.exists) {
          /* ec */
          console.error('Error382601');
          errorCB(Ci18n.translate('Projekt konnte nicht gefunden werden'));
          return;
        }

        const project = this.convertDBObjToProject(projectSnap.data());

        if (!project) {
          /* ec */
          console.error('Error290712');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          return;
        }

        succedCB(project);
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error234688');
        console.error(err)
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public getProjectByName(projectName: string): Project {
    const i = this._projectNames.indexOf(projectName);

    if (i === -1) {
      /* ec */
      console.error('Error231827');
      return null;
    }

    const projectId = this._projectNamesIds[i];

    return this.getProject_fast(projectId);
  }

  public getProjectName(projectId: string) {
    const tempI = this._projectNamesIds.indexOf(projectId);

    if (tempI === -1) {
      return null;
    }
    return this._projectNames[tempI];
  }

  private getEmployeeAccessTo(employeeId: string): Employee {
    const i = this._employeesAccessToIds.indexOf(employeeId);

    if (i === -1) {
      return null;
    }

    return this._employeesAccessTo[i];
  }

  public getAllAccessess(): Access[] {
    let accesses: Access[] = [new Access(this._usersEmployeeId, true)];
    accesses[0]['name'] = this._usersEmployeeName;

    const loadedUsersAccesses = this.getLoadedUsersAccesses();
    for (let i = 0; i < loadedUsersAccesses.length; i++) {
      if (loadedUsersAccesses[i]['name'] === this._usersEmployeeName) {
        loadedUsersAccesses.splice(i, 1);
        break;
      }
    }

    accesses = accesses.concat(loadedUsersAccesses);
    return accesses;
  }

  public getEmployeeName(employeeId: string): string {
    const i = this._employeeNamesIds.indexOf(employeeId);

    if (i === -1) {
      /* ec */
      console.error('Error110823');
      return null;
    }

    return this._employeeNames[i];
  }

  private getEmployeeByName(employeName: string): Employee {
    if (employeName === this._usersEmployeeName) {
      return this._usersEmployeeProfile;
    }

    let employeeToReturn = null;
    this._employeesAccessTo.forEach(employeeAccessTo => {
      if (employeeAccessTo.name === employeName) {
        employeeToReturn = employeeAccessTo;
        return;
      }
    });

    return employeeToReturn;
  }

  private getEmployeeById(employeeId: string): Employee {
    let returnValue: Employee = null;

    if (employeeId === this._usersEmployeeId) {
      returnValue = this.copyEmployee(this._usersEmployeeProfile);
    } else {
      const i = this._employeesAccessToIds.indexOf(employeeId);
      if (i !== -1) {
        returnValue = this._employeesAccessTo[i];
      }
    }

    return returnValue;
  }

  private getEmployeeMetaData(
    employeeId: string,
    succedCB: (arg0?: Employee) => void,
    errorCB: (err: string) => void
  ) {
    const employeeDoc = firebase
      .firestore()
      .doc('clients/' + this.clientId + '/employees/' + employeeId);
    let employeeMetaData: Employee;

    employeeDoc
      .get()
      .then(empSnap => {
        if (!empSnap.exists) {
          succedCB();
          return;
        }

        const empObj = empSnap.data();
        employeeMetaData = this.convertDBObjToEmployee(empObj);
        succedCB(employeeMetaData);
      })
      .catch(err => {

        /* ec */
        console.error('Error016205');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public getEmployeesAssignments(
    employeeName: string,
    from: Date,
    to: Date
  ): [Assignment[][], number[][], number[], string] {
    const areSameDay: Function = (date1: Date, date2: Date): boolean => {
      if (date1.toDateString() === date2.toDateString()) {
        return true;
      }
      return false;
    };

    let tempDate: Date;

    if (to > from) {
      tempDate = new Date(from.valueOf());
    } else {
      tempDate = to;
      to = from;
      from = new Date(tempDate.valueOf());
    }

    const fromTS = from.valueOf();
    const toTS = to.valueOf();

    let dayCount = 1;
    const daysArrayTemplate = [null];
    const firstTS = this.get0oclockTS(fromTS);
    const tsArray: number[] = [firstTS];

    while (!areSameDay(tempDate, to)) {
      dayCount++;
      daysArrayTemplate.push(null);
      this.nextDayOfDate(tempDate);
      tsArray.push(tempDate.valueOf());
    }
    this.nextDayOfDate(tempDate);
    const lastTS: number = tempDate.valueOf();
    tsArray.push(lastTS);

    let employeeId: string;
    {
      const tempI = this._employeeNames.indexOf(employeeName);

      if (tempI === -1) {
        /* ec */
        console.error('Error373881');
        return null;
      }

      employeeId = this._employeeNamesIds[tempI];
      if (!employeeId) {
        /* ec */
        console.error('Error975477');
        return null;
      }
    }
    let empAss: EmployeeAssignments;
    {
      const tempI = this._employeeAssignmentsListIds.indexOf(employeeId);
      if (tempI === -1) {
        /* ec */
        console.error('Error236617');
        return null;
      }
      empAss = this._employeeAssignmentsList[tempI];
      if (!EmployeeAssignments) {
        /* ec */
        console.error('Error887610');
        return null;
      }
    }

    let returnTable: Assignment[][] = [];
    let returnTableI: number[][] = [];

    returnTable = daysArrayTemplate.slice(0);
    returnTableI = daysArrayTemplate.slice(0);

    for (let i = 0; i < empAss.assignments.length; i++) {
      const assignment = empAss.assignments[i];
      if (assignment !== null) {
        if (assignment.start >= firstTS && assignment.start < lastTS) {
          for (let di = 0; di < tsArray.length - 1; di++) {
            if (tsArray[di + 1] > assignment.start) {
              const assignmentI = this._assignments.indexOf(assignment);
              if (assignmentI === -1) {
                /* ec */
                console.error('Error027319');
                return null;
              }

              if (returnTable[di] === null) {
                returnTable[di] = [assignment];
                returnTableI[di] = [assignmentI];
              } else {
                returnTable[di].push(assignment);
                returnTableI[di].push(assignmentI);
              }
              break;
            }
          }
        }
      }
    }

    return [returnTable, returnTableI, tsArray, employeeName];
  }

  public removeSingleAssignment(
    assignmentI: number,
    errorCB: (err: string) => void,
    succedCB: (arg0?: string, arg1?: any, arg2?: string) => void
  ) {
    const assignmentId = this._assignmentIds[assignmentI];
    if (!assignmentId) {
      /* ec */
      console.error('Error320789');
      return;
    }
    this.deleteSingleAssignment(assignmentId, errorCB, succedCB);
  }

  private deleteSingleAssignment(
    assignmentId: string,
    errorCB: (err: string) => void,
    succedCB: (arg0?: string, arg1?: any, arg2?: string) => void,
    undoHistoryId?: string,
    redoHistoryId?: string
  ) {
    const changerId = this._usersEmployeeId;
    const changerName = this._usersEmployeeName;

    const assignment = this.helper.getAssignment(this._assignmentIds, this._assignments, assignmentId);
    if (!assignment) {
      /* ec */
      console.error('Error255389');
      return;
    }

    const updateAssToNotConf_Ids: string[] = [];
    const conflictedAssignmentIds: string[] = [];
    let newTTA: number;
    const projectId = assignment.projectId;

    const conflictsCol = this.afs.collection(
      '/clients/' + this.clientId + '/conflicts/',
      ref => ref.where(assignmentId, '==', true)
    );

    const tempSub1 = conflictsCol.snapshotChanges().subscribe({
      next: conflictSnaps => {
        tempSub1.unsubscribe();

        if (conflictSnaps.length === 0) {
          setUpdatedProject();
          return;
        }

        conflictSnaps.forEach(conflictSnap => {
          const tempAssId = conflictSnap.payload.doc.id;
          const tempAssConf = conflictSnap.payload.doc.data();

          const tempAssConfWith = Object.keys(tempAssConf);

          conflictedAssignmentIds.push(tempAssId);
          if (tempAssConfWith.length === 1) {
            updateAssToNotConf_Ids.push(tempAssId);
          }
        });

        setUpdatedProject();
      },
      error: err => {
        tempSub1.unsubscribe();
        /* ec */
        console.error('Error381250');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });

    const setUpdatedProject: Function = () => {
      const project = this.getProject_fast(projectId);
      if (!project) {
        /* ec */
        console.error('Error381250');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      if (
        project.number !== '%23%241ckn3%24%24' &&
        project.number !== '%23V@c@710n'
      ) {
        const assignmentDuration: number = assignment.end - assignment.start;
        if (assignmentDuration !== 0) {
          newTTA =
            project.timeToAllocate + assignmentDuration / this.msPerMinute;
        }
      }
      updateData();
    };

    const updateData: Function = () => {
      const batch = this.afs.firestore.batch();

      const assignmentDoc = firebase
        .firestore()
        .doc('/clients/' + this.clientId + '/assignments/' + assignmentId);
      batch.delete(assignmentDoc);

      let historyObj: {};
      let historyId: string;

      if (!undoHistoryId) {
        historyObj = {
          changerId: this._usersEmployeeId,
          changerName: this._usersEmployeeName,
          changeTyp: 'removed',
          changeTime: FieldValue.serverTimestamp()
        };

        if (!redoHistoryId) {
          historyId = this.helper.generatePushId();
        } else {
          historyId = redoHistoryId;
        }
        const assignmentHistDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId
          );
        batch.set(assignmentHistDoc, historyObj);
      } else {
        historyId = undoHistoryId;
        const assignmentHistoryDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId
          );
        batch.delete(assignmentHistoryDoc);
      }

      if (newTTA !== NaN) {
        const projectDoc = firebase
          .firestore()
          .doc('/clients/' + this.clientId + '/projects/' + projectId);
        batch.update(projectDoc, {timeToAllocate: newTTA});
      }

      const assignmentConflictsDoc = firebase
        .firestore()
        .doc('/clients/' + this.clientId + '/conflicts/' + assignmentId);
      batch.delete(assignmentConflictsDoc);

      conflictedAssignmentIds.forEach(conflictedAssignmentId => {
        const assignmentConflictsDocL2 = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/conflicts/' +
            conflictedAssignmentId
          );
        const tempObj = {};
        tempObj[assignmentId] = FieldValue.delete();
        batch.update(assignmentConflictsDocL2, tempObj);
      });

      updateAssToNotConf_Ids.forEach(tempAssId => {
        const assignmentDoc2 = firebase
          .firestore()
          .doc('/clients/' + this.clientId + '/assignments/' + tempAssId);
        batch.update(assignmentDoc2, {isConflicted: false});
      });

      batch
        .commit()
        .then(() => {
          succedCB(assignmentId, historyObj, historyId);
        })
        .catch(err => {
          /* ec */
          console.error('Error729147');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        });
    };
  }

  public removeMultipleAssignments(
    assignmentIs: number[],
    errorCB: (arg0: string) => void,
    succedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void
  ) {
    const assignmentIds: string[] = [];

    for (let tempI = 0; tempI < assignmentIs.length; tempI++) {
      const assignmentI = assignmentIs[tempI];
      const assignmentId = this._assignmentIds[assignmentI];
      const assignment = this._assignments[assignmentI];

      if (!assignmentId) {
        /* ec */
        console.error('Error373064');
        errorCB(Ci18n.translate('Zuweisung nicht gefunden'));
        return;
      }

      assignmentIds.push(assignmentId);
    }

    this.deleteMultipleAssignments(assignmentIds, errorCB, succedCB);
  }

  private async deleteMultipleAssignments(
    assignmentIdsToDelete: string[],
    errorCB: (arg0: string) => void,
    succedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void,
    undoHistoryIds?: string[],
    redoHistoryIds?: string[],
  ) {

    const assignmentsToDelete: DeleteAssignmentHelper[] = assignmentIdsToDelete.map(assignmentId => {
      const assignment = this.helper.getAssignment(this._assignmentIds, this._assignments, assignmentId);

      if (!assignment) {
        /* ec */
        console.error('Error026041');
        errorCB(Ci18n.translate('Zuweisung nicht gefunden'));
        return;
      }

      return {
        assignmentId: assignmentId,
        assignment: this.helper.copyAssignment(assignment),
      };
    });

    const projectId = assignmentsToDelete[0].assignment.projectId;

    const projectOrError = await this.getProjectFromFirebase(projectId);
    if (projectOrError instanceof Project) {
      await this.deleteAssignmentService.deleteMultipleAssignments(
        assignmentIdsToDelete,
        assignmentsToDelete,
        errorCB,
        succedCB,
        this.msPerMinute,
        this.clientId,
        this._usersEmployeeId,
        this._usersEmployeeName,
        projectOrError,
        undoHistoryIds,
        redoHistoryIds,
      );
    } else {
      errorCB(projectOrError.error);
    }
  }

  private getAssignment(
    assignmentId: string,
    errorCB: (err: string) => void,
    succedCB: (arg: Assignment) => void
  ) {
    const assignmentDoc = this.afs.doc(
      '/clients/' + this.clientId + '/assignments/' + assignmentId
    );

    const tempSub = assignmentDoc.valueChanges().subscribe({
      next: snap => {
        tempSub.unsubscribe();
        succedCB(this.convertDBObjToAssignment(snap, assignmentId));
      },
      error: err => {
        tempSub.unsubscribe();
        /* ec */
        console.error('Error527083');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });
  }

  public getAssignmentsOfProject(
    projectId: string,
    errorCB: (err: string) => void,
    succedCB: (arg0?: Assignment[], arg1?: string[]) => void
  ) {
    const projectAssignmentsCollection = this.afs.collection(
      'clients/' + this.clientId + '/assignments',
      ref => ref.where('projectId', '==', projectId)
    );

    const tempSub = projectAssignmentsCollection.stateChanges().subscribe({
      next: snaps => {
        tempSub.unsubscribe();
        const returnAssignments: Assignment[] = [];
        const returnIds: string[] = [];

        snaps.forEach(snap => {
          const tempDoc = snap.payload.doc;
          returnAssignments.push(this.convertDBObjToAssignment(tempDoc.data(), tempDoc.id));
          returnIds.push(tempDoc.id);
        });

        succedCB(returnAssignments, returnIds);
      },
      error: () => {
        tempSub.unsubscribe();
        /* ec */
        console.error('Error113709');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });
  }

  public fixProjectTTA(
    projectId: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    this.getProject(
      projectId,
      project => {
        this.getAssignmentsOfProject(
          projectId,
          err => {
            errorCB(err);
          },
          (assignments, assignmentIds) => {
            let tta = project.duration;
            let tta_ms = tta * this.msPerMinute;
            for (let ai = 0; ai < assignments.length; ai++) {
              const assignment = assignments[ai];
              const assiDura_ms = assignment.end - assignment.start;
              tta_ms -= assiDura_ms;
            }
            tta = tta_ms / this.msPerMinute;

            if (project.timeToAllocate === tta) {
              succedCB();
              return;
            }

            const projectDoc = this.afs.firestore.doc('/clients/' + this.clientId + '/projects/' + projectId);
            const projectData = {
              timeToAllocate: tta
            };

            projectDoc.update(projectData)
              .then(() => {
                succedCB();
              })
              .catch(err => {
                /* ec */
                console.error('Error713486');
                errorCB('Projekt konnte nicht aktualisiert werden.');
              });
          });
      },
      err => {
        errorCB(err);
      }
    );
  }

  public fixAssignmentConflictStateByIndex(
    assignmentI: number,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    succedCB();
  }

  // private fixAssignmentConflictState(assignmentId: string, errorCB: (err: string) => void, succedCB: () => void) {

  // }

  public getAssignmentEditors(
    assignmentId: string,
    errorCB: (err: string) => void,
    succedCB: (arg0: {}[]) => void
  ) {
    const assignmentHistoryCol = this.afs.collection(
      '/clients/' + this.clientId + '/history/assignments/' + assignmentId,
      ref => ref.orderBy('changeTime', 'asc')
    );

    const tempSub = assignmentHistoryCol.valueChanges().subscribe({
      next: snapList => {
        tempSub.unsubscribe();
        if (snapList.length === 0) {
          errorCB(Ci18n.translate('Keine Zuweisung gefunden'));
          return;
        }

        const returnList: {}[] = [];
        snapList.forEach(snap => {
          const newObj = {
            ts: snap['changeTime'].toMillis(),
            changeTyp: snap['changeTyp'],
            changerId: snap['changerId']
          };

          returnList.push(newObj);
        });

        succedCB(returnList);
      },
      error: () => {
        tempSub.unsubscribe();
        /* ec */
        console.error('Error413477');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });
  }

  public getAssignmentEditorsByIndex(
    assignmentI: number,
    errorCB: (err: string) => void,
    succedCB: (arg0: any[]) => void
  ) {
    const assignmentKey: string = this._assignmentIds[assignmentI];

    if (!assignmentKey) {
      /* ec */
      console.error('Error095149');
      errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      return;
    }

    this.getAssignmentEditors(
      assignmentKey,
      err => {
        errorCB(err);
      },
      val => {
        succedCB(val);
      });
  }

  private getProjectCreatedAt(projectId: string): Promise<any> {
    return new Promise<any>((res, rej) => {
      const projectHistoryCol = this.afs.collection(
        '/clients/' + this.clientId + '/history/projects/' + projectId,
        ref => ref
          .where('changeTyp', '==', 'added'));

      const tempSub = projectHistoryCol.valueChanges().subscribe({
        next: histDocSnaps => {
          tempSub.unsubscribe();

          if (histDocSnaps.length !== 1) {
            rej();
            return;
          }

          const histDocSnap = histDocSnaps[0];
          const createdAt = histDocSnap['changeTime'];
          if (!createdAt) {
            rej();
            return;
          }

          const ts_s = createdAt['seconds'];
          const ts = ts_s * 1000;
          res(ts);
        },
        error: () => {
          // wLQ34HVee63GxoQ9QRkt
          console.log(projectId);
          tempSub.unsubscribe();
          /* ec */
          console.error('Error470253');
          rej();
        }
      });
    });
  }

  private buildAssignmentTable(
    from: Date,
    to: Date
  ): [Assignment[][][], number[][][], number[], string[]] {
    const areSameDay: Function = (date1: Date, date2: Date): boolean => {
      if (date1.toDateString() === date2.toDateString()) {
        return true;
      }
      return false;
    };

    let tempDate: Date;

    if (to > from) {
      tempDate = new Date(from.valueOf());
    } else {
      tempDate = to;
      to = from;
      from = new Date(tempDate.valueOf());
    }

    const fromTS = from.valueOf();
    const toTS = to.valueOf();

    let dayCount = 1;
    const daysArrayTemplate = [null];
    const firstTS = this.get0oclockTS(fromTS);
    const tsArray: number[] = [firstTS];

    while (!areSameDay(tempDate, to)) {
      dayCount++;
      daysArrayTemplate.push(null);
      this.addDaysToDate(tempDate, 1);
      tsArray.push(this.get0oclockTS(tempDate.valueOf()));
    }
    this.addDaysToDate(tempDate, 1);
    const lastTS: number = this.get0oclockTS(tempDate.valueOf());
    tsArray.push(lastTS);

    const empAssLIds: string[] = this._employeeAssignmentsListIds;
    const empAssL: EmployeeAssignments[] = this._employeeAssignmentsList;

    const dataTable: Assignment[][][] = new Array(empAssL.length);
    const dataTableIs: number[][][] = new Array(empAssL.length);
    const employeeNames: string[] = [];

    for (let ei = 0; ei < empAssL.length; ei++) {
      dataTable[ei] = daysArrayTemplate.slice(0);
      dataTableIs[ei] = daysArrayTemplate.slice(0);

      let assignmentCounter = 0;
      empAssL[ei].assignments.forEach(assignment => {
        if (assignment !== null) {
          if (assignment.start >= firstTS && assignment.start < lastTS) {
            for (let di = 0; di < tsArray.length - 1; di++) {
              if (tsArray[di + 1] > assignment.start) {
                const assignmentI = this._assignments.indexOf(assignment);

                if (dataTable[ei][di] === null) {
                  dataTable[ei][di] = [this.helper.copyAssignment(assignment)];
                  dataTableIs[ei][di] = [assignmentI];
                } else {
                  dataTable[ei][di].push(this.helper.copyAssignment(assignment));
                  dataTableIs[ei][di].push(assignmentI);
                }
                break;
              }
            }
          }
        }
        assignmentCounter++;
      });

      const empId: string = empAssL[ei].employeeId;
      employeeNames.push(this.getEmployeeName(empId));
    }

    let returnValue: [Assignment[][][], number[][][], number[], string[]];
    returnValue = [dataTable, dataTableIs, tsArray, employeeNames];
    return returnValue;
  }

  private checkIfEmployeeNameIsTaken(name: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!name || typeof name !== 'string') {
        /* ec */
        console.error('Error912843');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const empCol = this.afs.collection(
        '/clients/' + this.clientId + '/employees',
        ref => ref.where('name', '==', name)
      );

      const tempSub = empCol.valueChanges().subscribe({
        next: projSnaps => {
          tempSub.unsubscribe();
          if (projSnaps.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        error: err => {
          tempSub.unsubscribe();
          /* ec */
          console.error('Error010782');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      });
    });
  }

  private checkIfEmployeeNumberIsTaken(number: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!number || typeof number !== 'string') {
        /* ec */
        console.error('Error923746');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const empCol = this.afs.collection(
        '/clients/' + this.clientId + '/employees',
        ref => ref.where('number', '==', number)
      );

      const tempSub = empCol.valueChanges().subscribe({
        next: projSnaps => {
          if (projSnaps.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
          tempSub.unsubscribe();
        },
        error: err => {
          /* ec */
          console.error('Error010782');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          tempSub.unsubscribe();
        }
      });
    });
  }

  private checkIfProjectNameIsTaken(name: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!name || typeof name !== 'string') {
        /* ec */
        console.error('Error204394');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const projCol = this.afs.collection(
        '/clients/' + this.clientId + '/projects',
        ref => ref.where('name', '==', name)
      );

      const tempSub = projCol.valueChanges().subscribe({
        next: projSnaps => {
          if (projSnaps.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
          tempSub.unsubscribe();
        },
        error: err => {
          /* ec */
          console.error('Error010782');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          tempSub.unsubscribe();
        }
      });
    });
  }

  private getNextInteratedProjectName(name: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!name || typeof name !== 'string') {
        /* ec */
        console.error('Error239056');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const queryStartWith = name;
      let queryEndWith: string;
      {
        queryEndWith = name;
        const lastCharCode = queryEndWith.charCodeAt(queryEndWith.length - 1);
        queryEndWith =
          queryEndWith.slice(0, -1) + String.fromCharCode(lastCharCode + 1);
      }

      const iterNameRegEx = new RegExp('^' + name + ' \\([0-9][0-9]*\\)');

      const projectCol = this.afs.collection(
        '/clients/' + this.clientId + '/projects',
        ref =>
          ref
            .where('name', '>', queryStartWith)
            .where('name', '<', queryEndWith)
      );

      const tempSub = projectCol.valueChanges().subscribe({
        next: projectSnaps => {
          tempSub.unsubscribe();
          if (projectSnaps.length === 0) {
            resolve(name + '(2)');
            return;
          }

          let biggestIndex = 2;

          projectSnaps.forEach(projectSnap => {
            let tempName: string = projectSnap['name'];

            if (!iterNameRegEx.test(tempName)) {
              return; // forEch continue
            }

            tempName = tempName.replace(name + ' (', '');
            tempName = tempName.slice(0, -1);
            const tempIndex: number = Number(tempName);

            if (!tempIndex || tempIndex === NaN) {
              return; // forEch continue
            }

            biggestIndex = Math.max(biggestIndex, tempIndex);
          });

          const newName = name + ' (' + (biggestIndex + 1) + ')';
          resolve(newName);
        },
        error: err => {
          tempSub.unsubscribe();
          /* ec */
          console.error('Error913625');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      });
    });
  }

  private checkIfProjectNumberIsTaken(number: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!number || typeof number !== 'string') {
        /* ec */
        console.error('Error197563');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const projCol = this.afs.collection(
        '/clients/' + this.clientId + '/projects',
        ref => ref.where('number', '==', number)
      );

      const tempSub = projCol.valueChanges().subscribe({
        next: projSnaps => {
          if (projSnaps.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
          tempSub.unsubscribe();
        },
        error: err => {
          /* ec */
          console.error('Error195828');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          tempSub.unsubscribe();
        }
      });
    });
  }

  private checkProjectIdIsTaken(projectId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!projectId || typeof projectId !== 'string') {
        /* ec */
        console.error('Error190274');
        reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const projDoc = this.afs.firestore.doc(
        '/clients/' + this.clientId + '/projects/' + projectId
      );

      projDoc
        .get()
        .then(projDocSnap => {
          resolve(projDocSnap.exists);
        })
        .catch(err => {
          /* ec */
          console.error('Error234750');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        });
    });
  }

  private getAccessesToDocOfEmployee(employeeId: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const doc = this.afs.doc(
        '/clients/' + this.clientId + '/accesses/' + employeeId
      );
      const tempSub = doc.valueChanges().subscribe({
        next: snap => {
          tempSub.unsubscribe();
          resolve(snap);
        },
        error: err => {
          tempSub.unsubscribe();
          /* ec */
          console.error('Error486049');
          reject();
        }
      });
    });
  }

  private getAccessesByDocOfEmployee(employeeId: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const coll = this.afs.collection(
        '/clients/' + this.clientId + '/accesses/',
        ref => ref.where(employeeId, '>=', false)
      );

      const tempSub = coll.stateChanges().subscribe({
        next: snaps => {
          tempSub.unsubscribe();
          const returnObJ = {};

          snaps.forEach(snap => {
            const snapDoc = snap.payload.doc;
            returnObJ[snapDoc.id] = snapDoc.data()[employeeId];
          });

          resolve(returnObJ);
        },
        error: err => {
          tempSub.unsubscribe();
          /* ec */
          console.error('Error312649');
          reject();
        }
      });
    });
  }

  public getUsersAccessesByDoc(): Promise<Access[]> {
    return new Promise<Access[]>((resolve, reject) => {
      this.getAccessesByDocOfEmployee(this._usersEmployeeId)
        .then(val => {
          const empIds: string[] = Object.keys(val);
          const returnAccesses: Access[] = [];
          empIds.forEach(empId => {
            const newAccess = new Access(empId, val[empId]);
            newAccess['name'] = this.getEmployeeName(empId);
            returnAccesses.push(newAccess);
          });
          resolve(returnAccesses);
        })
        .catch(err => {
          reject();
        });
    });
  }

  private writeNewProjecToDB(project: Project): Promise<void | string> {
    return new Promise<void | string>((resolve, reject) => {
      const projectObj = this.convertProjectToDBObj(project);

      if (!projectObj) {
        /* ec */
        console.error('Error897182');
        reject(Ci18n.translate('Projekt konnte nicht umgewandelt werden'));
        return;
      }

      const projectId = this.helper.generatePushId();
      this.checkProjectIdIsTaken(projectId)
        .then(idIsTaken => {
          if (idIsTaken) {
            /* ec */
            console.error('Error474589');
            reject(
              Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es erneut.')
            );
            return;
          }

          const batch = this.afs.firestore.batch();

          const projDoc = this.afs.firestore.doc(
            '/clients/' + this.clientId + '/projects/' + projectId
          );
          const projHistDoc = this.afs.firestore
            .collection(
              '/clients/' + this.clientId + '/history/projects/' + projectId
            )
            .doc();

          const historyObj: {} = {
            changerId: this._usersEmployeeId,
            changerName: this._usersEmployeeName,
            changeTyp: 'added',
            changeTime: FieldValue.serverTimestamp(),
            object: projectObj
          };

          batch.set(projHistDoc, historyObj);
          batch.set(projDoc, projectObj);

          batch
            .commit()
            .then(val => {
              resolve();
            })
            .catch(err => {
              /* ec */
              console.error('Error230486');
              reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            });
        })
        .catch(err => {
          /* ec */
          console.error('Error230485');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        });
    });
  }

  private removeProjectFromDB(projectId: string): Promise<void | string> {
    return new Promise<void | string>((resolve, reject) => {
      this.checkProjectIdIsTaken(projectId)
        .then(idIsTaken => {
          if (!idIsTaken) {
            reject('Projekt konnte nicht in gefunden werden.');
            return;
          }

          this.getAssignmentsOfProject(
            projectId,
            err => {
              /* ec */
              console.error('Error185745');
              reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
              return;
            },
            (assignmets, assignmetIds) => {
              if (!assignmets) {
                /* ec */
                console.error('Error320784');
                reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                return;
              }

              if (assignmets.length !== 0) {
                reject(
                  Ci18n.translate('Projekt kann nicht gelöscht werden, solange es Mitarbeitern zugewiesen ist')
                );
                return;
              }

              const batch = this.afs.firestore.batch();

              const projDoc = this.afs.firestore.doc(
                '/clients/' + this.clientId + '/projects/' + projectId
              );
              const projHistDoc = this.afs.firestore
                .collection(
                  '/clients/' +
                  this.clientId +
                  '/history/projects/' +
                  projectId
                )
                .doc();

              const historyObj: {} = {
                changerId: this._usersEmployeeId,
                changerName: this._usersEmployeeName,
                changeTyp: 'removed',
                changeTime: FieldValue.serverTimestamp()
              };

              batch.set(projHistDoc, historyObj);
              batch.delete(projDoc);

              batch
                .commit()
                .then(val => {
                  resolve();
                })
                .catch(err => {
                  /* ec */
                  console.error('Error230486');
                  reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                });
            }
          );
        })
        .catch(err => {
          /* ec */
          console.error('Error230485');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        });
    });
  }

  private updateProjectInDB(
    projectId: string,
    project: Project
  ): Promise<void | string> {
    return new Promise<void | string>((resolve, reject) => {
      if (!project.timeToAllocate) {
        project.timeToAllocate = 0; // darf für convertProjectToDBObj nicht leer sein.. ansonsten undedeutent in dieser funktion
      }
      const projectObj = this.convertProjectToDBObj(project);

      if (!projectObj) {
        /* ec */
        console.error('Error910378');
        reject(Ci18n.translate('Projekt konnte nicht umgewandelt werden'));
        return;
      }

      const projDoc = this.afs.firestore.doc(
        '/clients/' + this.clientId + '/projects/' + projectId
      );
      const projHistDoc = this.afs.firestore
        .collection(
          '/clients/' + this.clientId + '/history/projects/' + projectId
        )
        .doc();

      this.checkIfProjectNameIsTaken(projectObj['name'])
        .then(projNameTaken => {
          this.checkIfProjectNumberIsTaken(projectObj['number'])
            .then(projNumberTaken => {
              firebase
                .firestore()
                .runTransaction(transaction =>
                  transaction.get(projDoc).then(docSnap => {
                    const oldProjectObj: {} = docSnap.data();
                    const nameChanged: boolean =
                      oldProjectObj['name'] !== projectObj['name'];
                    const numberChanged: boolean =
                      oldProjectObj['number'] !== projectObj['number'];
                    const colorChanged: boolean =
                      oldProjectObj['color'] !== projectObj['color'];
                    const noteChanged: boolean =
                      (!oldProjectObj['note']
                        ? null
                        : oldProjectObj['note']) !==
                      (!projectObj['note'] ? null : projectObj['note']);
                    const cloudLinkChanged: boolean =
                      (!oldProjectObj['cloudLink']
                        ? null
                        : oldProjectObj['cloudLink']) !==
                      (!projectObj['cloudLink'] ? null : projectObj['cloudLink']);
                    const reserveChanged: boolean =
                      oldProjectObj['reserved'] !== projectObj['reserved'];
                    const finishedChanged: boolean =
                      oldProjectObj['finished'] !== projectObj['finished'];
                    const markerChanged: boolean =
                      (!oldProjectObj['marker']
                        ? null
                        : oldProjectObj['marker']) !==
                      (!projectObj['marker'] ? null : projectObj['marker']);
                    const ttaDiff =
                      oldProjectObj['duration'] - projectObj['duration'];

                    if (
                      !nameChanged &&
                      !numberChanged &&
                      ttaDiff === 0 &&
                      !colorChanged &&
                      !noteChanged &&
                      !cloudLinkChanged &&
                      !reserveChanged &&
                      !markerChanged &&
                      !finishedChanged
                    ) {
                      resolve();
                      return;
                    }

                    if (numberChanged && projNumberTaken) {
                      reject(Ci18n.translate('Projektkennziffer ist bereits vergeben'));
                      return;
                    }

                    let historyObj: {};
                    if (nameChanged && projNameTaken) {
                      this.getNextInteratedProjectName(projectObj['name'])
                        .then(newName => {
                          projectObj['name'] = newName;

                          projectObj['timeToAllocate'] =
                            oldProjectObj['timeToAllocate'] - ttaDiff;

                          historyObj = {
                            changerId: this._usersEmployeeId,
                            changerName: this._usersEmployeeName,
                            changeTyp: 'updated',
                            changeTime: FieldValue.serverTimestamp(),
                            object: projectObj
                          };

                          transaction.set(projDoc, projectObj);
                          transaction.set(projHistDoc, historyObj);
                        })
                        .catch(err => {
                          /* ec */
                          console.error('Error346321');
                          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                        });

                      return;
                    }

                    projectObj['timeToAllocate'] =
                      oldProjectObj['timeToAllocate'] - ttaDiff;

                    historyObj = {
                      changerId: this._usersEmployeeId,
                      changerName: this._usersEmployeeName,
                      changeTyp: 'updated',
                      changeTime: FieldValue.serverTimestamp(),
                      object: projectObj
                    };

                    transaction.set(projDoc, projectObj);
                    transaction.set(projHistDoc, historyObj);
                  })
                )
                .then(() => {
                  resolve();
                })
                .catch(error => {
                  /* ec */
                  console.error('Error998500');
                  reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                });
            })
            .catch(err => {
              /* ec */
              console.error('Error691271');
              reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            });
        })
        .catch(err => {
          /* ec */
          console.error('Error954882');
          reject(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        });
    });
  }

  public addProject(
    projectName: string,
    projectNumber: string,
    projectColor: string,
    projectDuration: number,
    projectNote: string,
    cloudLink: string,
    projectMarker: string,
    projectReserved: boolean,
    projectTimeToAllocate: number,
    errorCB: (err: string) => void,
    succedCB: (arg0?: any) => void
  ) {
    const newProject = new Project();
    newProject.color = projectColor;
    newProject.duration = projectDuration;
    newProject.name = projectName;
    newProject.note = projectNote;
    newProject.cloudLink = cloudLink;
    newProject.marker = projectMarker;
    newProject.number = projectNumber;
    newProject.reserved = projectReserved;
    newProject.timeToAllocate = projectTimeToAllocate;

    // check if name or number are taken allready.. is so return an error
    this.checkIfProjectNameIsTaken(projectName)
      .then(projNameTaken => {
        this.checkIfProjectNumberIsTaken(projectNumber)
          .then(projNumberTaken => {
            if (projNumberTaken) {
              errorCB(Ci18n.translate('Projektkennziffer ist bereits vergeben'));
              return;
            }

            const runUpdate: () => void = () => {
              this.writeNewProjecToDB(newProject)
                .then(() => {
                  succedCB();
                })
                .catch(err => {
                  /* ec */
                  console.error('Error267432' + ' | err: ' + err);
                  errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                });
            };

            if (!projNameTaken) {
              runUpdate();
              return;
            }

            this.getNextInteratedProjectName(projectName)
              .then(newName => {
                newProject.name = newName;
                runUpdate();
              })
              .catch(err => {
                /* ec */
                console.error('Error298375');
                errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
              });
          })
          .catch(err => {
            /* ec */
            console.error('Error320897' + ' | err: ' + err);
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            return;
          });
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error268320' + ' | err: ' + err);
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      });
  }

  public updateProject(
    projectIndex: number,
    projectName: string,
    projectNumber: string,
    projectColor: string,
    projectDuration: number,
    projectNote: string,
    cloudLink: string,
    projectMarker: string,
    projectReserved: boolean,
    projectFinished,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    const project = new Project();
    project.color = projectColor;
    project.duration = projectDuration;
    project.name = projectName;
    project.note = projectNote;
    project.cloudLink = cloudLink;
    project.marker = projectMarker;
    project.number = projectNumber;
    project.reserved = projectReserved;
    project.finished = projectFinished;

    const projectId = this._projectIds[projectIndex];
    if (!projectId) {
      /* ec */
      console.error('Error031233');
      errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      return;
    }

    this.updateProjectInDB(projectId, project)
      .then(() => {
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error267432' + ' | err: ' + err);
        errorCB(err);
      });

    const runUpdate: () => void = () => {
      this.updateProjectInDB(projectId, project)
        .then(() => {
          succedCB();
        })
        .catch(err => {
          /* ec */
          console.error('Error267432' + ' | err: ' + err);
          errorCB(err);
        });
    };
  }

  public removeProject(
    projectIndex: number,
    errorCB: (err: string) => void,
    succedCB: (arg0?: any) => void
  ) {
    const projectId = this._projectIds[projectIndex];
    if (!projectId) {
      /* ec */
      console.error('Error699177');
      errorCB(Ci18n.translate('Projektindex nicht bekannt'));
      return;
    }

    this.removeProjectFromDB(projectId)
      .then(() => {
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error796465');
        errorCB(err);
      });
  }

  public addEmployee(
    name: string,
    number: string,
    dept: string,
    group: string,
    otherInfo: string,
    user: boolean,
    scheduler: boolean,
    selfEdit: boolean,
    accessesBy: Access[],
    accessesTo: Access[],
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    if (!name) {
      errorCB(Ci18n.translate('Bitte geben Sie einen Namen an'));
      return;
    }

    if (!number) {
      errorCB(Ci18n.translate('Bitte geben Sie eine Personalnummer an'));
      return;
    }

    const employeeObj = {
      name: name,
      number: number,
      dept: dept,
      group: group,
      otherInformation: otherInfo,
      user: user,
      scheduler: scheduler,
      selfEdit: selfEdit
    };

    if (employeeObj.user !== false && employeeObj.user !== true) {
      delete employeeObj.user;
    }
    if (!employeeObj.dept) {
      delete employeeObj.dept;
    }
    if (!employeeObj.otherInformation) {
      delete employeeObj.otherInformation;
    }
    if (!employeeObj.group) {
      delete employeeObj.group;
    }
    if (!employeeObj.scheduler) {
      delete employeeObj.scheduler;
    }
    if (!employeeObj.selfEdit) {
      delete employeeObj.selfEdit;
    }
    this.checkIfEmployeeNameIsTaken(name)
      .then(nameIsTaken => {
        if (nameIsTaken) {
          errorCB(Ci18n.translate('Name ist bereits vergeben'));
          return;
        }

        this.checkIfEmployeeNumberIsTaken(number)
          .then(numberIsTaken => {
            if (numberIsTaken) {
              errorCB(Ci18n.translate('Personalnummer ist bereits vergeben'));
              return;
            }

            const employeeId: string = this.helper.generatePushId();
            const historyObj: {} = {
              changerId: this._usersEmployeeId,
              changerName: this._usersEmployeeName,
              changeTyp: 'added',
              changeTime: FieldValue.serverTimestamp(),
              object: employeeObj
            };

            const batch = this.afs.firestore.batch();

            const employeeDoc = firebase
              .firestore()
              .doc('/clients/' + this.clientId + '/employees/' + employeeId);
            batch.set(employeeDoc, employeeObj);

            const employeeHistoryDoc = firebase
              .firestore()
              .doc(
                '/clients/' +
                this.clientId +
                '/history/employees/' +
                employeeId +
                '/' +
                this.helper.generatePushId()
              );
            batch.set(employeeHistoryDoc, historyObj);

            const accessesObj = {};
            for (let i = 0; i < accessesTo.length; i++) {
              const accessTo = accessesTo[i];
              accessesObj[accessTo.key] = accessTo.write;
            }
            const accessesDoc = firebase
              .firestore()
              .doc('/clients/' + this.clientId + '/accesses/' + employeeId);
            batch.set(accessesDoc, accessesObj);

            for (let i = 0; i < accessesBy.length; i++) {
              const accessBy = accessesBy[i];
              const tempObj = {};
              tempObj[employeeId] = accessBy.write;
              const tempDoc = firebase
                .firestore()
                .doc(
                  '/clients/' + this.clientId + '/accesses/' + accessBy.key
                );
              batch.update(tempDoc, tempObj);
            }

            batch
              .commit()
              .then(() => {
                succedCB();
              })
              .catch(err => {
                /* ec */
                console.error('Error843475');
                errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
              });
          })
          .catch(err => {
            // checkIfEmployeeNumberIsTaken failed
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            /* ec */
            console.error('Error520470');
            return;
          });
      })
      .catch(err => {
        // checkIfEmployeeNameIsTaken failed
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        /* ec */
        console.error('Error365109');
        return;
      });
  }

  public createEmployeeWithUser(
    name: string,
    number: string,
    dept: string,
    group: string,
    otherInfo: string,
    user: boolean,
    mail: string,
    scheduler: boolean,
    selfEdit: boolean,
    accessesBy: Access[],
    accessesTo: Access[],
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    mail = mail.toLowerCase(); // make mail lower case
    mail = mail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(mail)) {
      /* ec */
      console.error('Error987592');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    if (!name) {
      errorCB(Ci18n.translate('Bitte geben Sie einen Namen an'));
      return;
    }

    if (!number) {
      errorCB(Ci18n.translate('Bitte geben Sie eine Personalnummer an'));
      return;
    }

    const employeeObj = {
      name: name,
      number: number,
      dept: dept,
      group: group,
      otherInformation: otherInfo,
      user: false,
      scheduler: scheduler,
      selfEdit: selfEdit,
    };

    if (employeeObj.user !== false && employeeObj.user !== true) {
      delete employeeObj.user;
    }
    if (!employeeObj.dept) {
      delete employeeObj.dept;
    }
    if (!employeeObj.group) {
      delete employeeObj.group;
    }
    if (!employeeObj.otherInformation) {
      delete employeeObj.otherInformation
    }
    if (!employeeObj.scheduler) {
      delete employeeObj.scheduler;
    }
    if (!employeeObj.selfEdit) {
      delete employeeObj.selfEdit;
    }
    this.checkIfEmployeeNameIsTaken(name)
      .then(nameIsTaken => {
        if (nameIsTaken) {
          errorCB(Ci18n.translate('Name ist bereits vergeben'));
          return;
        }

        this.checkIfEmployeeNumberIsTaken(number)
          .then(numberIsTaken => {
            if (numberIsTaken) {
              errorCB(Ci18n.translate('Personalnummer ist bereits vergeben'));
              return;
            }

            this.isMailListed(
              mail,
              mailExists => {
                if (mailExists) {
                  errorCB(Ci18n.translate('E-Mail-Adresse ist bereits vergeben'));
                  return;
                }

                const employeeId: string = this.helper.generatePushId();
                const historyObj: {} = {
                  changerId: this._usersEmployeeId,
                  changerName: this._usersEmployeeName,
                  changeTyp: 'added',
                  changeTime: FieldValue.serverTimestamp(),
                  object: employeeObj
                };

                const batch = this.afs.firestore.batch();

                const employeeDoc = firebase
                  .firestore()
                  .doc(
                    '/clients/' + this.clientId + '/employees/' + employeeId
                  );
                batch.set(employeeDoc, employeeObj);

                const employeeHistoryDoc = firebase
                  .firestore()
                  .doc(
                    '/clients/' +
                    this.clientId +
                    '/history/employees/' +
                    employeeId +
                    '/' +
                    this.helper.generatePushId()
                  );
                batch.set(employeeHistoryDoc, historyObj);

                const accessesObj = {};
                for (let i = 0; i < accessesTo.length; i++) {
                  const accessTo = accessesTo[i];
                  accessesObj[accessTo.key] = accessTo.write;
                }
                const accessesDoc = firebase
                  .firestore()
                  .doc(
                    '/clients/' + this.clientId + '/accesses/' + employeeId
                  );
                batch.set(accessesDoc, accessesObj);

                for (let i = 0; i < accessesBy.length; i++) {
                  const accessBy = accessesBy[i];
                  const tempObj = {};
                  tempObj[employeeId] = accessBy.write;
                  const tempDoc = firebase
                    .firestore()
                    .doc(
                      '/clients/' + this.clientId + '/accesses/' + accessBy.key
                    );
                  batch.update(tempDoc, tempObj);
                }

                const newUserDoc = firebase
                  .firestore()
                  .doc('/newUsers/' + mail);
                const newUserObj = {};
                newUserObj[this.clientId + '_' + employeeId] = true;
                batch.set(newUserDoc, newUserObj);

                const listedMailDoc = this.afs.firestore.doc('/msmp/' + mail);
                const listedMailObj = {};
                listedMailObj[this.clientId + '_' + employeeId] = true;
                batch.set(listedMailDoc, listedMailObj);

                batch
                  .commit()
                  .then(() => {
                    succedCB();
                  })
                  .catch(err => {
                    /* ec */
                    console.error('Error843475');
                    errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                  });
              },
              err => {
                errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                /* ec */
                console.error('Error012851');
                return;
              }
            );
          })
          .catch(err => {
            // checkIfEmployeeNumberIsTaken failed
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            /* ec */
            console.error('Error520470');
            return;
          });
      })
      .catch(err => {
        // checkIfEmployeeNameIsTaken failed
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        /* ec */
        console.error('Error365109');
        return;
      });
  }

  public addNewUser(
    mail: string,
    employeeId: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    mail = mail.toLowerCase(); // make mail lower case
    mail = mail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(mail)) {
      /* ec */
      console.error('Error192497');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    this.isMailListed(
      mail,
      mailExists => {
        if (mailExists) {
          errorCB(Ci18n.translate('E-Mail-Adresse ist bereits vergeben'));
          return;
        }

        const newUserDoc = this.afs.firestore.doc('/newUsers/' + mail);
        const newUserObj = {};
        newUserObj[this.clientId + '_' + employeeId] = true;

        const listedMailDoc = this.afs.firestore.doc('/msmp/' + mail);
        const listedMailObj = {};
        listedMailObj[this.clientId + '_' + employeeId] = true;

        const batch = this.afs.firestore.batch();
        batch.set(newUserDoc, newUserObj);
        batch.set(listedMailDoc, listedMailObj);
        batch
          .commit()
          .then(() => {
            succedCB();
          })
          .catch(err => {
            /* ec */
            console.error('Error201087');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          });
      },
      err => {
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        /* ec */
        console.error('Error129845');
        return;
      }
    );
  }

  public removeNewUser(mail: string) {
    return new Promise<any>((res, rej) => {
      mail = mail.toLowerCase(); // make mail lower case
      mail = mail.replace(/\s/g, ''); // delete whitespaces
      if (!this.emailFormatCheck(mail)) {
        /* ec */
        console.error('Error192497');
        rej(Ci18n.translate('E-Mail-Format ungültig'));
        return;
      }

      this.isMailListed(
        mail,
        mailExists => {
          if (!mailExists) {
            rej(Ci18n.translate('E-Mail-Adresse ist nicht bekannt'));
            return;
          }

          const newUserDoc = this.afs.firestore.doc('/newUsers/' + mail);
          const listedMailDoc = this.afs.firestore.doc('/msmp/' + mail);

          const batch = this.afs.firestore.batch();
          batch.delete(newUserDoc);
          batch.delete(listedMailDoc);
          batch.commit().then(() => {
            res();
          })
            .catch(err => {
              /* ec */
              console.error('Error375011');
              rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            });
        },
        err => {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          /* ec */
          console.error('Error129845');
          return;
        }
      );
    });
  }

  public updateEmployeeMetaData(
    employeeId: string,
    name: string,
    number: string,
    dept: string,
    group: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    const oldEmployee: Employee = this.getEmployeeById(employeeId);
    const newEmployee: Employee = this.copyEmployee(oldEmployee);

    if (!name) {
      errorCB(Ci18n.translate('Bitte geben Sie einen Namen an'));
      return;
    }

    if (!number) {
      errorCB(Ci18n.translate('Bitte geben Sie eine Personalnummer an'));
      return;
    }
    newEmployee.name = name;
    newEmployee.number = number;

    if (!dept) {
      dept = null;
      delete newEmployee.dept;
    }

    if (!group) {
      group = null;
      delete newEmployee.group;
    }

    const oldName: string = oldEmployee.name;
    const oldNumber: string = oldEmployee.number;
    const oldDept = !oldEmployee.dept ? null : oldEmployee.dept;
    const oldGroup = !oldEmployee.group ? null : oldEmployee.group;

    const nameChanged: boolean = oldName !== name;
    const numberChanged: boolean = oldNumber !== number;
    const deptChanged: boolean = oldDept !== dept;
    const groupChanged: boolean = oldGroup !== group;

    if (!nameChanged && !numberChanged && !deptChanged && !groupChanged) {
      succedCB();
      return;
    }

    this.checkIfEmployeeNameIsTaken(name)
      .then(nameIsTaken => {
        if (nameChanged && nameIsTaken) {
          errorCB(Ci18n.translate('Name ist bereits vergeben'));
          return;
        }

        this.checkIfEmployeeNumberIsTaken(number)
          .then(numberIsTaken => {
            if (numberChanged && numberIsTaken) {
              errorCB(Ci18n.translate('Personalnummer ist bereits vergeben'));
              return;
            }

            const employeeObj = {
              name: name,
              number: number,
              dept: !!dept ? dept : FieldValue.delete(),
              group: !!group ? group : FieldValue.delete()
            };

            const histEmpObj = this.convertEmployeeToDBObj(newEmployee);

            const historyObj: {} = {
              changerId: this._usersEmployeeId,
              changerName: this._usersEmployeeName,
              changeTyp: 'updated',
              changeTime: FieldValue.serverTimestamp(),
              object: histEmpObj
            };
            const empDoc = firebase
              .firestore()
              .doc('/clients/' + this.clientId + '/employees/' + employeeId);
            const histDoc = firebase
              .firestore()
              .doc(
                '/clients/' +
                this.clientId +
                '/history/employees/' +
                employeeId +
                '/' +
                this.helper.generatePushId()
              );

            const batch = this.afs.firestore.batch();
            batch.update(empDoc, employeeObj);
            batch.set(histDoc, historyObj);
            batch
              .commit()
              .then(() => {
                succedCB();
              })
              .catch(err => {
                errorCB('Mitarbeiterprofil konnte nicht aktualisiert werden');
                return;
              });
          })
          .catch(err => {
          });
      })
      .catch(err => {
      });
  }

  private updateEmployeeUserState(
    employeeId: string,
    user: boolean,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    const employeeDoc = firebase
      .firestore()
      .doc('/clients/' + this.clientId + '/employees/' + employeeId);

    employeeDoc
      .get()
      .then(snap => {
        if (!snap.exists) {
          errorCB(Ci18n.translate('Mitarbeiter konnte nicht gefunden werden'));
          return;
        }

        const employeeObj = {user: user};

        const newEmpObj = snap.data();
        newEmpObj.user = user;

        const employeeHistoryDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/history/employees/' +
            employeeId +
            '/' +
            this.helper.generatePushId()
          );
        const historyObj: {} = {
          changerId: this._usersEmployeeId,
          changerName: this._usersEmployeeName,
          changeTyp: 'updated',
          changeTime: FieldValue.serverTimestamp(),
          object: newEmpObj
        };

        const batch = this.afs.firestore.batch();
        batch.update(employeeDoc, employeeObj);
        batch.set(employeeHistoryDoc, historyObj);
        batch
          .commit()
          .then(() => {
            succedCB();
          })
          .catch(err => {
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            /* ec */
            console.error('Error132084');
          });
      })
      .catch(err => {
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        /* ec */
        console.error('Error129035');
        return;
      });
  }

  // private updateEmployeeBlockState(employeeId: string, blocked: boolean, errorCB: (err: string) => void, succedCB: () => void) {

  // }

  private updateEmployeeToRegistered(
    employeeId: string,
    clientName: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    const employeeDoc = firebase
      .firestore()
      .doc('/clients/' + this.clientId + '/employees/' + employeeId);

    employeeDoc
      .get()
      .then(snap => {
        if (!snap.exists) {
          errorCB(Ci18n.translate('Mitarbeiter konnte nicht gefunden werden'));
          return;
        }

        const employeeObj = {user: true};

        const newEmpObj = snap.data();
        newEmpObj.user = true;

        const employeeHistoryDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/history/employees/' +
            employeeId +
            '/' +
            this.helper.generatePushId()
          );
        const historyObj: {} = {
          changerId: this._usersEmployeeId,
          changerName: this._usersEmployeeName,
          changeTyp: 'updated',
          changeTime: FieldValue.serverTimestamp(),
          object: newEmpObj
        };

        const batch = this.afs.firestore.batch();
        batch.update(employeeDoc, employeeObj);
        batch.set(employeeHistoryDoc, historyObj);
        batch
          .commit()
          .then(() => {
            succedCB();
          })
          .catch(err => {
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            /* ec */
            console.error('Error210398');
          });
      })
      .catch(err => {
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        /* ec */
        console.error('Error412908');
        return;
      });
  }

  public updateEmployeeAndAddNewUser(
    employeeId: string,
    name: string,
    number: string,
    dept: string,
    group: string,
    otherInfo: string,
    user: boolean,
    mail: string,
    scheduler: boolean,
    selfEdit: boolean,
    accessesBy: Access[],
    accessesTo: Access[],
    accessesByToRemove: string[],
    accessesToToRemove,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    mail = mail.toLowerCase(); // make mail lower case
    mail = mail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(mail)) {
      /* ec */
      console.error('Error091827');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    this.updateEmployee(
      employeeId,
      name,
      number,
      dept,
      group,
      otherInfo,
      user,
      scheduler,
      selfEdit,
      accessesBy,
      accessesTo,
      accessesByToRemove,
      accessesToToRemove,
      errorCB,
      () => {
        this.addNewUser(mail, employeeId, errorCB, succedCB);
      }
    );
  }

  public updateEmployeeAndUpdateNewUserMail(
    employeeId: string,
    name: string,
    number: string,
    dept: string,
    group: string,
    otherInfo: string,
    user: boolean,
    mail: string,
    scheduler: boolean,
    selfEdit: boolean,
    accessesBy: Access[],
    accessesTo: Access[],
    accessesByToRemove: string[],
    accessesToToRemove,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    mail = mail.toLowerCase(); // make mail lower case
    mail = mail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(mail)) {
      /* ec */
      console.error('Error901826');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    this.updateEmployee(
      employeeId,
      name,
      number,
      dept,
      group,
      otherInfo,
      user,
      scheduler,
      selfEdit,
      accessesBy,
      accessesTo,
      accessesByToRemove,
      accessesToToRemove,
      errorCB,
      () => {
        this.changeNewUserMail(mail, employeeId, errorCB, succedCB);
      }
    );
  }

  public updateEmployee(
    employeeId: string,
    name: string,
    number: string,
    dept: string,
    group: string,
    otherInfo: string,
    user: boolean,
    scheduler: boolean,
    selfEdit: boolean,
    accessesBy: Access[],
    accessesTo: Access[],
    accessesByToRemove: string[],
    accessesToToRemove,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    const oldEmployee: Employee = this.getEmployeeById(employeeId);
    const newEmployee: Employee = this.copyEmployee(oldEmployee);

    if (name === null || name === '') {
      errorCB(Ci18n.translate('Bitte geben Sie einen Namen an'));
      return;
    }

    if (number === null || number === '') {
      errorCB(Ci18n.translate('Bitte geben Sie eine Personalnummer an'));
      return;
    }

    newEmployee.name = name;
    newEmployee.number = number;

    if (!dept) {
      dept = null;
      delete newEmployee.dept;
    }

    if (!group) {
      group = null;
      delete newEmployee.group;
    }

    if (!otherInfo) {
      otherInfo = null;
      delete newEmployee.otherInformation
    }

    if (scheduler !== false && scheduler !== true) {
      scheduler = true;
    }

    if (selfEdit !== false && selfEdit !== true) {
      selfEdit = true;
    }

    const oldName: string = oldEmployee.name;
    const oldNumber: string = oldEmployee.number;
    const oldDept = !oldEmployee.dept ? null : oldEmployee.dept;
    const oldGroup = !oldEmployee.group ? null : oldEmployee.group;
    const oldOtherInfo: string = !oldEmployee.otherInformation ? null : oldEmployee.otherInformation
    const oldUser = oldEmployee.user;

    const nameChanged: boolean = oldName !== name;
    const numberChanged: boolean = oldNumber !== number;
    const deptChanged: boolean = oldDept !== dept;
    const groupChanged: boolean = oldGroup !== group;
    const otherInfoChanged: boolean = oldOtherInfo !== otherInfo
    const employeeObj: any = {
      name: name,
      number: number,
      dept: dept,
      group: group,
      otherInformation: otherInfo,
      user: user,
      scheduler: scheduler,
      selfEdit: selfEdit,
    };
    const employeeObjHist: any = {
      name: name,
      number: number,
      dept: dept,
      group: group,
      otherInformation: otherInfo,
      user: user,
      scheduler: scheduler,
      selfEdit: selfEdit,
    };

    if (employeeObj.user !== false && employeeObj.user !== true) {
      delete employeeObjHist.user;
      if (oldUser === false) {
        employeeObj.user = FieldValue.delete();
      } else {
        delete employeeObj.user;
      }
    }
    if (!employeeObj.dept) {
      delete employeeObj.dept;
      delete employeeObjHist.dept;
    }
    if (!employeeObj.group) {
      delete employeeObj.group;
      delete employeeObjHist.group;
    }
    if (!employeeObj.otherInformation) {
      delete employeeObj.otherInformation;
      delete employeeObjHist.otherInformation;
    }
    this.checkIfEmployeeNameIsTaken(name)
      .then(nameIsTaken => {
        if (nameChanged && nameIsTaken) {
          errorCB(Ci18n.translate('Name ist bereits vergeben'));
          return;
        }

        this.checkIfEmployeeNumberIsTaken(number)
          .then(numberIsTaken => {
            if (numberChanged && numberIsTaken) {
              errorCB(Ci18n.translate('Personalnummer ist bereits vergeben'));
              return;
            }

            let accessesToDoc: {};
            let accessesByDoc: {};

            this.getAccessesToDocOfEmployee(employeeId)
              .then(val => {
                accessesToDoc = val;
                updateWhenReady();
              })
              .catch(err => {
                errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                /* ec */
                console.error('Error037654');
              });

            this.getAccessesByDocOfEmployee(employeeId)
              .then(val => {
                accessesByDoc = val;
                updateWhenReady();
              })
              .catch(err => {
                errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                /* ec */
                console.error('Error961597');
              });

            let updateRunning = false;
            const updateWhenReady: Function = () => {
              if (updateRunning || !accessesToDoc || !accessesByDoc) {
                return;
              }

              updateRunning = true;

              const historyObj: {} = {
                changerId: this._usersEmployeeId,
                changerName: this._usersEmployeeName,
                changeTyp: 'updated',
                changeTime: FieldValue.serverTimestamp(),
                object: employeeObjHist
              };

              const batch = this.afs.firestore.batch();
              const employeeDoc = firebase
                .firestore()
                .doc('/clients/' + this.clientId + '/employees/' + employeeId);
              batch.update(employeeDoc, employeeObj);

              const employeeHistoryDoc = firebase
                .firestore()
                .doc(
                  '/clients/' +
                  this.clientId +
                  '/history/employees/' +
                  employeeId +
                  '/' +
                  this.helper.generatePushId()
                );
              batch.set(employeeHistoryDoc, historyObj);

              const accessesObj = {};
              for (let i = 0; i < accessesTo.length; i++) {
                const accessTo = accessesTo[i];
                accessesObj[accessTo.key] = accessTo.write;
              }
              const accessesDoc = firebase
                .firestore()
                .doc('/clients/' + this.clientId + '/accesses/' + employeeId);
              batch.set(accessesDoc, accessesObj);

              for (let i = 0; i < accessesBy.length; i++) {
                const accessBy = accessesBy[i];
                const tempObj = {};
                tempObj[employeeId] = accessBy.write;
                const tempDoc = firebase
                  .firestore()
                  .doc(
                    '/clients/' + this.clientId + '/accesses/' + accessBy.key
                  );
                batch.update(tempDoc, tempObj);

                if (typeof accessesByDoc[accessBy.key] === 'boolean') {
                  delete accessesByDoc[accessBy.key];
                }
              }

              for (const tempEmpId of Object.keys(accessesByDoc)) {
                const tempObj = {};
                tempObj[employeeId] = FieldValue.delete();
                const tempDoc = firebase
                  .firestore()
                  .doc('/clients/' + this.clientId + '/accesses/' + tempEmpId);
                batch.update(tempDoc, tempObj);
              }

              batch
                .commit()
                .then(() => {
                  succedCB();
                })
                .catch(err => {
                  /* ec */
                  console.error('Error107313');
                  errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
                });
            };
          })
          .catch(err => {
            // checkIfEmployeeNumberIsTaken failed
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            /* ec */
            console.error('Error680075');
            return;
          });
      })
      .catch(err => {
        // checkIfEmployeeNameIsTaken failed
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        /* ec */
        console.error('Error325320');
        return;
      });
  }

  public removeEmployee(
    employeeId: string
  ): Promise<any> {
    return new Promise<any>(async (res, rej) => {
      const employee = this.getEmployeeById(employeeId);
      if (employee.user === true) {
        rej(Ci18n.translate('Mitarbeiter kann nicht gelöscht werden, da dieser ein User ist.'));
        return;
      }

      const employeeIsNewUser = employee.user === false;

      const assignmentCol = this.afs.firestore.collection('/clients/' + this.clientId + '/assignments');
      assignmentCol.where('employeeId', '==', employeeId).get().then(async assignmentSnaps => {
        if (assignmentSnaps.size !== 0) {
          rej(Ci18n.translate('Mitarbeiter kann nicht gelöscht werden, solange dieser Projekten zugewiesen ist.'));
          return;
        }

        const batch = this.afs.firestore.batch();

        const employeeDocRef = this.afs.firestore.doc('/clients/' + this.clientId + '/employees/' + employeeId);
        const accessesDocRef = this.afs.firestore.doc('/clients/' + this.clientId + '/accesses/' + employeeId);

        const employeeHistoryDoc = this.afs.firestore.doc(
          '/clients/' + this.clientId + '/history/employees/' + employeeId + '/' + this.helper.generatePushId());
        const historyObj: {} = {
          changerId: this._usersEmployeeId,
          changerName: this._usersEmployeeName,
          changeTyp: 'removed',
          changeTime: FieldValue.serverTimestamp()
        };

        batch.delete(employeeDocRef);
        batch.delete(accessesDocRef);

        const accessColRef = this.afs.firestore.collection('/clients/' + this.clientId + '/accesses/');
        try {
          const accessColSnaps = await accessColRef.where(employeeId, '==', true).get();
          const accessColDocs = accessColSnaps.docs;
          for (let i = 0; i < accessColDocs.length; i++) {
            const accessDoc = accessColDocs[i];
            const accessDocRef = accessDoc.ref;
            const updateData = {};
            updateData[employeeId] = FieldValue.delete();
            batch.update(accessDocRef, updateData);
          }
        } catch (error) {
          rej('Error3246431');
          return;
        }

        batch.set(employeeHistoryDoc, historyObj);

        if (employeeIsNewUser) {
          try {
            const newUsersDocSnaps = await this.afs.firestore.collection('newUsers')
              .where(this.clientId + '_' + employeeId, '==', true).get();
            const newUsersDocRef = newUsersDocSnaps.docs[0].ref;
            batch.delete(newUsersDocRef);
          } catch (error) {
            rej('Error105980');
            console.log('Error105980' + ' | err: ' + error);
            // dont return since this can be a valid error...
          }
          try {
            const msmpSnaps = await this.afs.firestore.collection('msmp')
              .where(this.clientId + '_' + employeeId, '==', true).get();
            const msmpDocRef = msmpSnaps.docs[0].ref;
            batch.delete(msmpDocRef);
          } catch (error) {
            rej('Error572305');
            console.log('Error572305' + ' | err: ' + error);
            // dont return since this can be a valid error...
          }
        }

        batch.commit().then(() => {
          res();
        }).catch(err => {
          rej('Error513712' + ' | err: ' + err);
        });
      }).catch(err => {
        rej('Error542616' + ' | err: ' + err);
      });
    });

  }

  public setEmployeeInactive(employeeId): Promise<any> {
    return new Promise<any>((res, rej) => {
      const cf_deactivateEmployee = this.aff.httpsCallable('deactivateEmployee');

      if (!cf_deactivateEmployee) {
        rej('"deactivateEmployee" unknown');
        return;
      }

      cf_deactivateEmployee({eid: employeeId}).pipe(take(1)).toPromise().then(result => {
        if (result === 200) {
          res();
        } else if (result === 400) {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        } else {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      }).catch(err => {
        rej(err);
      });
    });
  }

  public setEmployeeActive(employeeId): Promise<any> {
    return new Promise<any>((res, rej) => {
      const cf_activateEmployee = this.aff.httpsCallable('activateEmployee');

      if (!cf_activateEmployee) {
        rej('"activateEmployee" unknown');
        return;
      }

      cf_activateEmployee({eid: employeeId}).pipe(take(1)).toPromise().then(result => {
        if (result === 200) {
          res();
        } else if (result === 400) {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        } else {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      }).catch(err => {
        rej(err);
      });
    });
  }

  public addSingleAssignment(
    employeeName: string,
    projectName: string,
    startTS: number,
    endTS: number,
    note: string,
    reserved: boolean,
    errorCB: (err: string) => void,
    succedCB: (arg0: string, arg1: any, arg2: string) => void,
    undoHistoryId?: string
  ) {
    this.setSingleAssignment(
      null,
      employeeName,
      projectName,
      startTS,
      endTS,
      note,
      reserved,
      errorCB,
      succedCB
    );
  }

  private setSingleAssignment(
    assignmentId: string,
    employeeName: string,
    projectName: string,
    startTS: number,
    endTS: number,
    note: string,
    reserved: boolean,
    errorCB: (err: string) => void,
    succedCB: (arg0: string, arg1: any, arg2: string) => void,
    undoHistoryId?: string,
    redoHistoryId?: string
  ) {
    const changerId = this._usersEmployeeId;
    const changerName = this._usersEmployeeName;

    const project = this.getProjectByName(projectName);
    if (!project) {
      /* ec */
      console.error('Error898941');
      errorCB(Ci18n.translate('Projekt nicht gefunden'));
      return;
    }

    const employeeNameI = this._employeeNames.indexOf(employeeName);
    const employeeId = this._employeeNamesIds[employeeNameI];

    if (!employeeId) {
      /* ec */
      console.error('Error514708');
      errorCB(Ci18n.translate('Mitarbeiter nicht gefunden'));
      return;
    }

    const projectI = this._projectNames.indexOf(projectName);
    const projectId = this._projectNamesIds[projectI];

    if (!projectId) {
      /* ec */
      console.error('Error932217');
      return;
    }

    if (startTS % this.msPerMinute !== 0) {
      startTS = Math.floor(startTS / this.msPerMinute) * this.msPerMinute;
      console.warn('startTS fixed');

      if (startTS % this.msPerMinute !== 0) {
        errorCB(Ci18n.translate('Start ungültig'));
        return;
      }
    }

    if (endTS % this.msPerMinute !== 0) {
      endTS = Math.floor(endTS / this.msPerMinute) * this.msPerMinute;
      console.warn('endTS fixed');

      if (endTS % this.msPerMinute !== 0) {
        errorCB(Ci18n.translate('Ende ungültig'));
        return;
      }
    }

    const employeeId_projectId = employeeId + '_' + projectId;
    const employeeId_start = employeeId + '_' + startTS;
    const employeeId_end = employeeId + '_' + endTS;
    const projectId_start = projectId + '_' + startTS;
    const projectId_end = projectId + '_' + endTS;

    const assignmentObject: Assignment = {
      employeeId: employeeId,
      projectId: projectId,
      start: startTS,
      end: endTS,
      note: !note ? null : note,
      reserved: !reserved ? false : reserved,
      isConflicted: false,
      employeeId_projectId: employeeId_projectId,
      employeeId_start: employeeId_start,
      employeeId_end: employeeId_end,
      projectId_start: projectId_start,
      projectId_end: projectId_end
    };

    if (!assignmentId) {
      assignmentId = this.helper.generatePushId();
    }

    const assignmentDoc = firebase
      .firestore()
      .doc('/clients/' + this.clientId + '/assignments/' + assignmentId);
    const conflictDoc = firebase
      .firestore()
      .doc('/clients/' + this.clientId + '/conflicts/' + assignmentId);
    const projectDoc = firebase
      .firestore()
      .doc('/clients/' + this.clientId + '/projects/' + projectId);

    let conflictedWith: string[] = [];
    let newTTA: number;

    const checkForConflict: Function = () => {
      const assignmentCol = this.afs.collection(
        '/clients/' + this.clientId + '/assignments/',
        ref =>
          ref
            .where(
              'employeeId_start',
              '>=',
              employeeId + '_' + (startTS - 24 * this.msPerHour)
            )
            .where(
              'employeeId_start',
              '<=',
              employeeId + '_' + (endTS + 24 * this.msPerHour)
            )
      );

      const tempSub = assignmentCol.stateChanges().subscribe({
        next: assignmentSnaps => {
          tempSub.unsubscribe();
          if (assignmentSnaps.length === 0) {
            setUpdatedProject();
            return;
          }

          assignmentSnaps.forEach(assignmentSnap => {
            const tempAssignmentId: string = assignmentSnap.payload.doc.id;
            const tempAssignmentObj: {} = assignmentSnap.payload.doc.data();

            if (!tempAssignmentObj['start']) {
              /* ec */
              console.error('Error430944');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }

            if (!tempAssignmentObj['end']) {
              /* ec */
              console.error('Error816945');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }

            const conflictFound = this.helper.checkIfAssignmentsAreConflictedByTS(
              startTS,
              endTS,
              tempAssignmentObj['start'],
              tempAssignmentObj['end']
            );
            if (conflictFound) {
              conflictedWith.push(tempAssignmentId);
            }
          });

          setUpdatedProject();
          return;
        },
        error: err => {
          tempSub.unsubscribe();
          /* ec */
          console.error('Error969567');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      });
    };

    const checkForConflictUnused: Function = () => {
      const assignmetsCol = this.afs.firestore.collection('/clients/' + this.clientId + '/assignments/');

      /*
      if ((startTS1 >= startTS2 && startTS1 < endTS2)
        || (endTS1 > startTS2 && endTS1 <= endTS2)
        || (startTS2 >= startTS1 && startTS2 < endTS1)
        || (endTS2 > startTS1 && endTS2 <= endTS1)) {
        return true;
      }
      return false;
      */

      const query1 = assignmetsCol
        .where('employeeId_start', '>=', employeeId + '_' + startTS)
        .where('employeeId_start', '<', employeeId + '_' + endTS);

      const query2 = assignmetsCol
        .where('employeeId_end', '>', employeeId + '_' + startTS)
        .where('employeeId_end', '<=', employeeId + '_' + endTS);

      let done1 = false;
      let done2 = false;

      const conflictedIdList1: string[] = [];
      const conflictedIdList2: string[] = [];

      const afterDoneFunc: Function = () => {
        if (!done1 || !done2) {
          return;
        }

        for (let i = 0; i < conflictedIdList2.length; i++) {
          const tempId = conflictedIdList2[i];
          if (conflictedIdList1.indexOf(tempId) === -1) {
            conflictedIdList1.push(tempId);
          }
        }

        conflictedWith = conflictedIdList1;
        setUpdatedProject();
      };

      query1.get().then(assiSnapList => {
        if (assiSnapList.size === 0) {
          done1 = true;
          afterDoneFunc();
          return;
        }

        for (let i = 0; i < assiSnapList.docs.length; i++) {
          conflictedIdList1.push(assiSnapList.docs[i].id);
        }
        afterDoneFunc();
      }).catch(() => {
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });

      query2.get().then(assiSnapList => {
        if (assiSnapList.size === 0) {
          done2 = true;
          afterDoneFunc();
          return;
        }

        for (let i = 0; i < assiSnapList.docs.length; i++) {
          conflictedIdList2.push(assiSnapList.docs[i].id);
        }
        afterDoneFunc();
      }).catch(() => {
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
    };

    const setUpdatedProject: Function = () => {
      if (
        project.number !== '%23%241ckn3%24%24' &&
        project.number !== '%23V@c@710n'
      ) {
        const assignmentDuration = endTS - startTS;
        newTTA = project.timeToAllocate - assignmentDuration / this.msPerMinute;

        if (newTTA % 1 !== 0) {
          newTTA = Math.round(newTTA);
        }
      }
      updateData();
    };

    const updateData: Function = () => {
      if (conflictedWith.length > 0) {
        assignmentObject.isConflicted = true;
      }

      const newConflictsObj: {} = {};

      const batch = this.afs.firestore.batch();

      conflictedWith.forEach(tempAssId => {
        newConflictsObj[tempAssId] = true;

        const tempDoc1 = firebase
          .firestore()
          .doc('/clients/' + this.clientId + '/assignments/' + tempAssId);
        batch.update(tempDoc1, {isConflicted: true});

        const tempObj: {} = {};
        tempObj[assignmentId] = true;
        const tempDoc2 = firebase
          .firestore()
          .doc('/clients/' + this.clientId + '/conflicts/' + tempAssId);
        batch.update(tempDoc2, tempObj);
      });

      batch.set(assignmentDoc, assignmentObject);
      batch.set(conflictDoc, newConflictsObj);

      if (newTTA !== NaN) {
        batch.update(projectDoc, {timeToAllocate: newTTA});
      }

      let historyId: string;
      let historyObj: AssignmentHistory;

      if (!undoHistoryId) {
        if (!redoHistoryId) {
          historyId = this.helper.generatePushId();
        } else {
          historyId = redoHistoryId;
        }

        const assignmentHistDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId
          );

        historyObj = {
          changerId: this._usersEmployeeId,
          changerName: this._usersEmployeeName,
          changeTyp: 'added',
          changeTime: FieldValue.serverTimestamp(),
          object: assignmentObject
        };

        batch.set(assignmentHistDoc, historyObj);
      } else {
        historyId = undoHistoryId;
        const assignmentHistDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId
          );
        batch.delete(assignmentHistDoc);
      }

      batch
        .commit()
        .then(() => {
          succedCB(assignmentId, historyObj, historyId);
        })
        .catch(err => {
          /* ec */
          console.error('Error312815');
          errorCB(Ci18n.translate('Zuweisung konnte nicht angelegt werden'));
        });
    };

    checkForConflict();
  }

  public addMultipleAssignments(
    employeeName: string,
    projectName: string,
    startTSEndTSArray,
    notes: string[],
    reserved: boolean[],
    errorCB: (err: string) => void,
    succedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void
  ) {
    this.setMultipleAssignments(
      null,
      employeeName,
      projectName,
      startTSEndTSArray,
      notes,
      reserved,
      errorCB,
      succedCB,
    );
  }

  private async setMultipleAssignments(
    assignmentIds: string[],
    employeeName: string,
    projectName: string,
    startTSEndTSArray: number[][],
    notes: string[],
    reserved: boolean[],
    errorCB: (err: string) => void,
    succedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void,
    undoHistoryIds?: string[],
    redoHistoryIds?: string[],
  ) {

    const employeeI = this._employeeNames.indexOf(employeeName);
    const employeeId = this._employeeNamesIds[employeeI];

    const projectI = this._projectNames.indexOf(projectName);
    const projectId = this._projectNamesIds[projectI];

    const projectOrError = await this.getProjectFromFirebase(projectId);

    if (projectOrError instanceof Project) {
      await this.setAssignmentService.setMultipleAssignments(assignmentIds, employeeName,
        projectName, startTSEndTSArray, notes, reserved,
        errorCB, succedCB, employeeId, projectId, projectOrError,
        this.msPerMinute, this._usersEmployeeId, this._usersEmployeeName,
        this.msPerHour, this.clientId, undoHistoryIds, redoHistoryIds);
    } else {
      errorCB(projectOrError.error);
    }
  }

  // GET THE ROLE OF THE CURRENT USER
  // TODO: move this to seperate service
  casualUser: boolean;

  public async checkRoles() {
    const employee: any = (await this.afs.collection('/clients/' + this.clientId + '/employees').doc(this._usersEmployeeId).get().toPromise()).data();
    if (!employee.ca && !employee.scheduler) {
      this.casualUser = true;
    }
  }

  public changeSingleAssignment(
    assignmentI: number,
    employeeName: string,
    projectName: string,
    startTS: number,
    endTS: number,
    note: string,
    reserved: boolean,
    errorCB: (err: string) => void,
    succedCB: (arg0?: string, arg1?: any, arg2?: string) => void
  ) {
    const assignmentId = this._assignmentIds[assignmentI];
    if (!assignmentId) {
      /* ec */
      console.error('Error405861');
      return;
    }

    this.updateSingleAssignment(
      assignmentId,
      employeeName,
      projectName,
      startTS,
      endTS,
      note,
      reserved,
      errorCB,
      succedCB
    );
  }

  private updateSingleAssignment(
    assignmentId: string,
    employeeName: string,
    projectName: string,
    startTS: number,
    endTS: number,
    note: string,
    reserved: boolean,
    errorCB: (err: string) => void,
    succedCB: (arg0?: string, arg1?: any, arg2?: string) => void,
    undoHistoryId?: string,
    redoHistoryId?: string
  ) {
    const changerId = this._usersEmployeeId;
    const changerName = this._usersEmployeeName;

    const assignment = this.helper.getAssignment(this._assignmentIds, this._assignments, assignmentId);
    if (!assignment) {
      /* ec */
      console.error('Error049435');
      return;
    }

    const project1 = this.getProjectByName(projectName);
    if (!project1) {
      /* ec */
      console.error('Error987338');
      errorCB(Ci18n.translate('Projekt nicht gefunden'));
      return;
    }

    const employeeNameI = this._employeeNames.indexOf(employeeName);
    const employeeId = this._employeeNamesIds[employeeNameI];

    if (!employeeId) {
      /* ec */
      console.error('Error462006');
      errorCB(Ci18n.translate('Mitarbeiter nicht gefunden'));
      return;
    }

    const projectI = this._projectNames.indexOf(projectName);
    const projectId1 = this._projectNamesIds[projectI];

    if (!projectId1) {
      /* ec */
      console.error('Error412310');
      return;
    }

    if (startTS % this.msPerMinute !== 0) {
      startTS = Math.floor(startTS / this.msPerMinute) * this.msPerMinute;
      console.warn('startTS fixed');

      if (startTS % this.msPerMinute !== 0) {
        errorCB(Ci18n.translate('Start ungültig'));
        return;
      }
    }

    if (endTS % this.msPerMinute !== 0) {
      endTS = Math.floor(endTS / this.msPerMinute) * this.msPerMinute;
      console.warn('endTS fixed');

      if (endTS % this.msPerMinute !== 0) {
        errorCB(Ci18n.translate('Ende ungültig'));
        return;
      }
    }

    const oldConfAssIds: string[] = [];
    const oldConfAssConfObjs: {}[] = [];

    const newConfAssIds: string[] = [];

    let newTTA: number;

    note = !note ? null : note;
    reserved = !reserved ? false : reserved;

    const getOldConflicts: Function = () => {
      const conflictsCol = this.afs.collection(
        '/clients/' + this.clientId + '/conflicts/',
        ref => ref.where(assignmentId, '==', true)
      );

      const tempSub1 = conflictsCol.snapshotChanges().subscribe({
        next: conflictSnaps => {
          tempSub1.unsubscribe();

          if (conflictSnaps.length === 0) {
            getNewConflicts();
            return;
          }

          conflictSnaps.forEach(conflictSnap => {
            const tempAssId = conflictSnap.payload.doc.id;
            const tempAssConf = conflictSnap.payload.doc.data();

            oldConfAssIds.push(tempAssId);
            oldConfAssConfObjs.push(tempAssConf);
          });

          getNewConflicts();
        },
        error: err => {
          tempSub1.unsubscribe();
          /* ec */
          console.error('Error666240');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      });
    };

    const getNewConflicts: Function = () => {
      const assignmentCol = this.afs.collection(
        '/clients/' + this.clientId + '/assignments/',
        ref =>
          ref
            .where(
              'employeeId_start',
              '>=',
              employeeId + '_' + (startTS - 24 * this.msPerHour)
            )
            .where(
              'employeeId_start',
              '<=',
              employeeId + '_' + (endTS + 24 * this.msPerHour)
            )
      );

      const tempSub = assignmentCol.stateChanges().subscribe({
        next: assignmentSnaps => {
          tempSub.unsubscribe();
          if (assignmentSnaps.length === 0) {
            setUpdatedProject();
            return;
          }

          assignmentSnaps.forEach(assignmentSnap => {
            const tempAssignmentId: string = assignmentSnap.payload.doc.id;
            if (tempAssignmentId === assignmentId) {
              return; // foreach continue
            }
            const tempAssignmentObj: {} = assignmentSnap.payload.doc.data();

            if (!tempAssignmentObj['start']) {
              /* ec */
              console.error('Error197788');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }

            if (!tempAssignmentObj['end']) {
              /* ec */
              console.error('Error036309');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }

            const conflictFound = this.helper.checkIfAssignmentsAreConflictedByTS(
              startTS,
              endTS,
              tempAssignmentObj['start'],
              tempAssignmentObj['end']
            );
            if (conflictFound) {
              newConfAssIds.push(tempAssignmentId);
            }
          });

          setUpdatedProject();
          return;
        },
        error: err => {
          tempSub.unsubscribe();
          /* ec */
          console.error('Error780159');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      });
    };

    const setUpdatedProject: Function = () => {
      const projectId2 = assignment.projectId;

      const project2 = this.getProject_fast(projectId2);
      if (!project2) {
        /* ec */
        console.error('Error381250');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      if (
        project2.number !== '%23%241ckn3%24%24' &&
        project2.number !== '%23V@c@710n'
      ) {
        const assignmentDurationOld: number = assignment.end - assignment.start;
        const assignmentDurationNew: number = endTS - startTS;
        const durationDiff = assignmentDurationOld - assignmentDurationNew;

        if (durationDiff !== 0) {
          newTTA = project2.timeToAllocate + durationDiff / this.msPerMinute;
        }
      }
      updateData();
    };

    const updateData: Function = () => {
      const batch = this.afs.firestore.batch();

      const assignmentObject = {
        employeeId: employeeId,
        projectId: projectId1,
        start: startTS,
        end: endTS,
        note: note,
        reserved: reserved,
        isConflicted: !!(newConfAssIds.length > 0),
        employeeId_projectId: employeeId + '_' + projectId1,
        employeeId_start: employeeId + '_' + startTS,
        employeeId_end: employeeId + '_' + endTS,
        projectId_start: projectId1 + '_' + startTS,
        projectId_end: projectId1 + '_' + endTS
      };

      let historyId: string;
      let historyObj: AssignmentHistory;

      if (!undoHistoryId) {
        if (!redoHistoryId) {
          historyId = this.helper.generatePushId();
        } else {
          historyId = redoHistoryId;
        }

        const assignmentHistDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId
          );

        historyObj = {
          changerId: this._usersEmployeeId,
          changerName: this._usersEmployeeName,
          changeTyp: 'updated',
          changeTime: FieldValue.serverTimestamp(),
          object: assignmentObject
        };

        batch.set(assignmentHistDoc, historyObj);
      } else {
        historyId = undoHistoryId;
        const assignmentHistDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            this.clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId
          );
        batch.delete(assignmentHistDoc);
      }

      const assignmentDoc1 = firebase
        .firestore()
        .doc('/clients/' + this.clientId + '/assignments/' + assignmentId);
      batch.set(assignmentDoc1, assignmentObject);

      const updatedConflictObj: {} = {};
      const conflictsToUpdateIds: string[] = [];
      const conflictsToUpdateObj: {}[] = [];
      const assignmentsToUpdateObj: {}[] = [];

      for (let i = 0; i < oldConfAssIds.length; i++) {
        const oldConflictedAssId: string = oldConfAssIds[i];

        oldConfAssConfObjs[i][assignmentId] = false;

        let isUnconflicted = true;
        for (const key in oldConfAssConfObjs[i]) {
          if (oldConfAssConfObjs[i][key] === true) {
            isUnconflicted = false;
            break;
          }
        }

        updatedConflictObj[
          oldConflictedAssId
          ] = FieldValue.delete();

        if (conflictsToUpdateIds.indexOf(oldConflictedAssId) === -1) {
          const tempObj = {};
          tempObj[assignmentId] = FieldValue.delete();

          conflictsToUpdateIds.push(oldConflictedAssId);
          conflictsToUpdateObj.push(tempObj);

          if (isUnconflicted) {
            assignmentsToUpdateObj.push({isConflicted: false});
          } else {
            assignmentsToUpdateObj.push({isConflicted: true});
          }
        } else {
          const tempI = conflictsToUpdateIds.indexOf(oldConflictedAssId);
          conflictsToUpdateObj[tempI][
            assignmentId
            ] = FieldValue.delete();

          if (isUnconflicted) {
            assignmentsToUpdateObj[tempI]['isConflicted'] = false;
          } else {
            assignmentsToUpdateObj[tempI]['isConflicted'] = true;
          }
        }
      }

      newConfAssIds.forEach(newConfAssId => {
        updatedConflictObj[newConfAssId] = true;

        if (conflictsToUpdateIds.indexOf(newConfAssId) === -1) {
          const tempObj = {};
          tempObj[assignmentId] = true;

          conflictsToUpdateIds.push(newConfAssId);
          conflictsToUpdateObj.push(tempObj);
          assignmentsToUpdateObj.push({isConflicted: true});
        } else {
          const tempI = conflictsToUpdateIds.indexOf(newConfAssId);
          conflictsToUpdateObj[tempI][assignmentId] = true;
          assignmentsToUpdateObj[tempI]['isConflicted'] = true;
        }
      });

      const assignmentConflictsDoc1 = firebase
        .firestore()
        .doc('/clients/' + this.clientId + '/conflicts/' + assignmentId);
      batch.update(assignmentConflictsDoc1, updatedConflictObj);

      for (let i = 0; i < conflictsToUpdateIds.length; i++) {
        const tempAssId = conflictsToUpdateIds[i];
        const tempConfObj = conflictsToUpdateObj[i];
        const tempAssObj = assignmentsToUpdateObj[i];

        const assignmentDoc2 = firebase
          .firestore()
          .doc('/clients/' + this.clientId + '/assignments/' + tempAssId);
        batch.update(assignmentDoc2, tempAssObj);

        const assignmentConflictsDoc2 = firebase
          .firestore()
          .doc('/clients/' + this.clientId + '/conflicts/' + tempAssId);
        batch.update(assignmentConflictsDoc2, tempConfObj);
      }

      if (!!newTTA || newTTA === 0) {
        const projectDoc = firebase
          .firestore()
          .doc('/clients/' + this.clientId + '/projects/' + projectId1);
        batch.update(projectDoc, {timeToAllocate: newTTA});
      }

      batch
        .commit()
        .then(() => {
          succedCB(assignmentId, historyObj, historyId);
        })
        .catch(err => {
          /* ec */
          console.error('Error428111');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        });
    };

    getOldConflicts();
  }

  public changeMultipleAssignments(
    assignmentIs: number[],
    employeeId: string,
    projectId: string,
    startTSEndTSArray: number[][],
    notes: string[],
    reserved: boolean[],
    errorCB: (err: string) => void,
    succedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void
  ) {
    const assignmentIds: string[] = [];
    for (let i = 0; i < assignmentIs.length; i++) {
      const assignmentI = assignmentIs[i];
      const assignmentId = this._assignmentIds[assignmentI];

      if (!assignmentId) {
        /* ec */
        console.error('Error889856');
        errorCB(Ci18n.translate('Zuweisung konnte nicht gefunden werden'));
        return;
      }

      assignmentIds.push(assignmentId);
    }

    this.updateMultipleAssignments(
      assignmentIds,
      employeeId,
      projectId,
      startTSEndTSArray,
      notes,
      reserved,
      errorCB,
      succedCB
    );
  }

  private async updateMultipleAssignments(
    assignmentIds: string[],
    employeeId: string,
    projectId: string,
    startTSEndTSArray: number[][],
    notes: string[],
    reserved: boolean[],
    errorCB: (err: string) => void,
    succeedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void,
    undoHistoryIds?: string[],
    redoHistoryIds?: string[]
  ) {

    const projectOrError = await this.getProjectFromFirebase(projectId);

    const employeeI = this._employeeNamesIds.indexOf(employeeId);
    const employeeName = this._employeeNames[employeeI];
    if (projectOrError instanceof Project) {
      await this.updateAssignmentService.updateMultipleAssignments(assignmentIds, employeeId, projectId, startTSEndTSArray, notes, reserved, errorCB,
        succeedCB, this.clientId, employeeName, projectOrError, this._assignmentIds, this._assignments, this.msPerMinute, this.msPerHour,
        this._usersEmployeeId, this._usersEmployeeName, undoHistoryIds, redoHistoryIds);
    } else {
      errorCB(projectOrError.error);
    }
  }

  public isMailListed(
    mail: string,
    succedCB: (arg0: boolean) => void,
    errorCB: (err: string) => void
  ) {
    mail = mail.toLowerCase(); // make mail lower case
    mail = mail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(mail)) {
      /* ec */
      console.error('Error712658');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    const newUsersDoc = this.afs.firestore.doc('/msmp/' + mail);

    // hotfix
    // for some reason we get rejcted and resolve of get() called!!!.. thx google!
    let succeded;

    const options: GetOptions = {source: 'server'};
    newUsersDoc
      .get(options)
      .then(newUsersSnap => {
        if (succeded === false) {
          return;
        }
        succeded = true;
        if (!newUsersSnap.exists) {
          succedCB(false);
          return;
        }

        succedCB(true);
      })
      .catch(err => {
        if (succeded === true) {
          return;
        }
        succeded = false;
        /* ec */
        console.error('Error357041');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  private getListedMailOfEmployee(
    employeeId: string,
    errorCB: (err: string) => void,
    succedCB: (arg0: string) => void
  ) {
    const empPath = this.clientId + '_' + employeeId;

    const listedMailsCol = this.afs.collection('/msmp/', ref =>
      ref.where(empPath, '==', true)
    );

    const tempSub = listedMailsCol.stateChanges().subscribe({
      next: mailSnaps => {
        if (mailSnaps.length === 0) {
          errorCB(
            Ci18n.translate('E-Mail-Adresse des Mitarbeiters konnte nicht ermittelt werden')
          );
          return;
        }

        const mail = mailSnaps[0].payload.doc.id;

        succedCB(mail);
      },
      error: () => {
        /* ec */
        console.error('Error215376');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });
  }

  public getListedMailOfEmployeeName(
    employeeName: string,
    errorCB: (err: string) => void,
    succedCB: (arg0: string) => void
  ) {
    const employeeId = this.getEmployeeId(employeeName);

    this.getListedMailOfEmployee(employeeId, errorCB, succedCB);
  }

  public changeListedMail(
    oldMail: string,
    newMail: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    oldMail = oldMail.toLowerCase(); // make mail lower case
    oldMail = oldMail.replace(/\s/g, ''); // delete whitespaces
    newMail = newMail.toLowerCase(); // make mail lower case
    newMail = newMail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(newMail)) {
      /* ec */
      console.error('Error281735');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    const oldListedMailDoc = this.afs.firestore.doc('/msmp/' + oldMail);

    const options: GetOptions = {source: 'server'};
    oldListedMailDoc
      .get(options)
      .then(snap => {
        if (!snap.exists) {
          /* ec */
          console.error('Error356457');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          return;
        }

        const batch = this.afs.firestore.batch();

        batch.delete(oldListedMailDoc);

        const newListedMailDocRef = this.afs.firestore.doc('/msmp/' + newMail);
        batch.set(newListedMailDocRef, snap.data());

        batch
          .commit()
          .then(() => {
            succedCB();
          })
          .catch(err => {
            /* ec */
            console.error('Error109451' + ' | err: ' + err);
            errorCB(Ci18n.translate('E-Mail-Adresse konnte nicht aktualisiert werden'));
          });
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error148344');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  private deactivateListedMail(
    mail: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    mail = mail.toLowerCase(); // make mail lower case
    mail = mail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(mail)) {
      /* ec */
      console.error('Error925872');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    const doc = this.afs.firestore.doc('/msmp/' + mail);
    const options: GetOptions = {source: 'server'};

    doc
      .get(options)
      .then(snap => {
        if (!snap.exists) {
          /* ec */
          console.error('Error220908');
          errorCB(Ci18n.translate('E-Mail-Adresse ist nicht bekannt'));
          return;
        }

        const docData = snap.data();
        docData[Object.keys(docData)[0]] = false;

        doc
          .update(docData)
          .then(() => {
            succedCB();
          })
          .catch(err => {
            /* ec */
            console.error('Error891623');
            errorCB(Ci18n.translate('E-Mail-Adresse konnte nicht freigegebenen werden'));
          });
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error891623');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  private removeMailFromList(
    mail: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    mail = mail.toLowerCase(); // make mail lower case
    mail = mail.replace(/\s/g, ''); // delete whitespaces
    if (!this.emailFormatCheck(mail)) {
      /* ec */
      console.error('Error127045');
      errorCB(Ci18n.translate('E-Mail-Format ungültig'));
      return;
    }

    const doc = this.afs.firestore.doc('/msmp/' + mail);
    const options: GetOptions = {source: 'server'};
    doc
      .get(options)
      .then(snap => {
        if (!snap.exists) {
          /* ec */
          console.error('Error220908');
          errorCB(Ci18n.translate('E-Mail-Adresse ist nicht bekannt'));
          return;
        }

        doc
          .delete()
          .then(() => {
            succedCB();
          })
          .catch(err => {
            /* ec */
            console.error('Error891623');
            errorCB(Ci18n.translate('E-Mail-Adresse konnte nicht freigegebenen werden'));
          });
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error891623');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public getNewUserMailFromEmployeeName(
    name: string,
    errorCB: (err: string) => void,
    succedCB: (arg0?: string) => void
  ) {
    const tempI = this._employeeNames.indexOf(name);

    if (tempI === -1) {
      /* ec */
      console.error('Error087861');
      errorCB(Ci18n.translate('Mitarbeiter nicht bekannt'));
      return;
    }

    const empId = this._employeeNamesIds[tempI];

    if (!empId) {
      return;
    }

    this.getNewUserMailOfEmployee(empId, errorCB, succedCB);
  }

  private getNewUserMailOfEmployee(
    employeeId: string,
    errorCB: (err: string) => void,
    succedCB: (arg0?: string) => void
  ) {
    const surchQuery = this.clientId + '_' + employeeId;

    const newUsersCol = this.afs.collection('newUsers', ref =>
      ref.where(surchQuery, '==', true)
    );

    const tempSub = newUsersCol.stateChanges().subscribe({
      next: snaps => {
        tempSub.unsubscribe();

        if (snaps.length === 0) {
          succedCB();
          return;
        }

        const mail = snaps[0].payload.doc.id;
        succedCB(mail);
      },
      error: () => {
        tempSub.unsubscribe();
        /* ec */
        console.error('Error916251');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });
  }

  private getLatestHistoryObjOfAssignment(
    assignmentId: string,
    succedCB: (arg0: {}, arg1?: string) => void,
    errorCB: (err: string) => void
  ) {
    const assignmentsHistoryColl = this.afs.collection(
      '/clients/' + this.clientId + '/history/assignments/' + assignmentId,
      ref => ref.orderBy('changeTime', 'desc').limit(1)
    );

    const tempSub = assignmentsHistoryColl.stateChanges().subscribe({
      next: snaps => {
        tempSub.unsubscribe();

        if (snaps.length === 0) {
          succedCB(null, null);
          return;
        }

        const returnObj = snaps[0].payload.doc.data();
        const returnId = snaps[0].payload.doc.id;

        succedCB(returnObj, returnId);
      },
      error: err => {
        tempSub.unsubscribe();
        /* ec */
        console.error('Error109248');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });
  }

  private getLatestTwoHistoryObjOfAssignment(
    assignmentId: string,
    succedCB: (arg0: {}[], arg1?: string[]) => void,
    errorCB: (err: string) => void
  ) {
    const assignmentsHistoryColl = this.afs.collection(
      '/clients/' + this.clientId + '/history/assignments/' + assignmentId,
      ref => ref.orderBy('changeTime', 'desc').limit(2)
    );

    const tempSub = assignmentsHistoryColl.stateChanges().subscribe({
      next: snaps => {
        tempSub.unsubscribe();

        if (snaps.length < 2) {
          succedCB(null, null);
          return;
        }

        const returnObj0 = snaps[0].payload.doc.data();
        const returnId0 = snaps[0].payload.doc.id;
        const returnObj1 = snaps[1].payload.doc.data();
        const returnId1 = snaps[1].payload.doc.id;

        succedCB([returnObj0, returnObj1], [returnId0, returnId1]);
      },
      error: err => {
        tempSub.unsubscribe();
        /* ec */
        console.error('Error125370');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    });
  }

  private getLatestHistoryObjsOfAssignments(
    assignmentIds: string[],
    succedCB: (arg0: {}[], arg1?: string[]) => void,
    errorCB: (err: string) => void
  ) {
    let aborded = false;
    let succeded = false;
    const lastHOs: {}[] = [];
    const lastHIds: string[] = [];
    let cbCounter = 0;

    for (let i = 0; i < assignmentIds.length; i++) {
      const assignmentId = assignmentIds[i];
      this.getLatestHistoryObjOfAssignment(
        assignmentId,
        (lastHO, lastHId) => {
          if (aborded) {
            return;
          }
          lastHOs[i] = lastHO;
          lastHIds[i] = lastHId;
          cbCounter++;

          if (cbCounter >= assignmentIds.length) {
            if (succeded) {
              return;
            }
            succeded = true;

            succedCB(lastHOs, lastHIds);
          }
        },
        err => {
          if (aborded) {
            return;
          }
          aborded = true;
          /* ec */
          console.error('Error102835');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      );
    }
  }

  private getLatestTwoHistoryObjsOfAssignments(
    assignmentIds: string[],
    succedCB: (arg0: {}[][], arg1?: string[][]) => void,
    errorCB: (err: string) => void
  ) {
    let aborded = false;
    let succeded = false;
    const lastHOTable: {}[][] = [];
    const lastHIdTable: string[][] = [];
    let cbCounter = 0;

    for (let i = 0; i < assignmentIds.length; i++) {
      const assignmentId = assignmentIds[i];
      this.getLatestTwoHistoryObjOfAssignment(
        assignmentId,
        (lastHOs, lastHIds) => {
          if (aborded) {
            return;
          }

          if (!lastHOs || !lastHIds) {
            /* ec */
            console.warn('Error394762');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            return;
          }

          lastHOTable[i] = lastHOs;
          lastHIdTable[i] = lastHIds;
          cbCounter++;

          if (cbCounter >= assignmentIds.length) {
            if (succeded) {
              return;
            }
            succeded = true;

            succedCB(lastHOTable, lastHIdTable);
          }
        },
        err => {
          if (aborded) {
            return;
          }
          aborded = true;
          /* ec */
          console.error('Error102835');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      );
    }
  }

  public undoAssignment(
    changerId: string,
    objectId: string,
    historyId: string,
    changeTyp: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    switch (changeTyp) {
      case 'added':
        this.undoAssignmentAdd(
          changerId,
          objectId,
          historyId,
          succedCB,
          errorCB
        );
        return;

      case 'updated':
        this.undoAssignmentUpdate(
          changerId,
          objectId,
          historyId,
          succedCB,
          errorCB
        );
        return;

      case 'removed':
        this.undoAssignmentRemove(
          changerId,
          objectId,
          historyId,
          succedCB,
          errorCB
        );
        return;

      default:
        errorCB(Ci18n.translate('Fehler'));
        /* ec */
        console.error('Error255768');
        return;
    }
  }

  private undoAssignmentAdd(
    changerId: string,
    assignmentId: string,
    historyId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    this.getLatestHistoryObjOfAssignment(
      assignmentId,
      (lastHO, lastHId) => {
        if (historyId !== lastHId) {
          errorCB(Ci18n.translate('Eintrag ist nicht mehr aktuell'));
          return;
        }

        this.deleteSingleAssignment(assignmentId, errorCB, succedCB, lastHId);
      },
      err => {
        /* ec */
        console.error('Error238197');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    );
  }

  private undoAssignmentUpdate(
    changerId: string,
    assignmentId: string,
    historyId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    this.getLatestTwoHistoryObjOfAssignment(
      assignmentId,
      (lastHOs, lastHIds) => {
        if (!lastHOs || !lastHIds) {
          /* ec */
          console.warn('Errort295871');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          return;
        }

        if (historyId !== lastHIds[0]) {
          errorCB(Ci18n.translate('Eintrag ist nicht mehr aktuell'));
          return;
        }

        const assignmentObj = lastHOs[1]['object'];

        const employeeId = assignmentObj.employeeId;
        const projectId = assignmentObj.projectId;
        const startTS = assignmentObj.start;
        const endTS = assignmentObj.end;
        const note = assignmentObj.note;
        const reserved = assignmentObj.reserved;

        const employeeName = this.getEmployeeName(employeeId);
        const projectName = this.getProjectName(projectId);

        this.updateSingleAssignment(
          assignmentId,
          employeeName,
          projectName,
          startTS,
          endTS,
          note,
          reserved,
          errorCB,
          succedCB,
          historyId
        );
      },
      err => {
        /* ec */
        console.error('Error238197');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    );
  }

  private undoAssignmentRemove(
    changerId: string,
    assignmentId: string,
    historyId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    this.getLatestTwoHistoryObjOfAssignment(
      assignmentId,
      (lastHOs, lastHIds) => {
        if (!lastHOs || !lastHIds) {
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          /* ec */
          console.warn('Errort582937');
          return;
        }

        if (historyId !== lastHIds[0]) {
          errorCB(Ci18n.translate('Eintrag ist nicht mehr aktuell'));
          return;
        }

        const assignmentObj = lastHOs[1]['object'];

        const employeeId = assignmentObj.employeeId;
        const projectId = assignmentObj.projectId;
        const startTS = assignmentObj.start;
        const endTS = assignmentObj.end;
        const note = assignmentObj.note;
        const reserved = assignmentObj.reserved;

        const employeeName = this.getEmployeeName(employeeId);
        const projectName = this.getProjectName(projectId);

        this.setSingleAssignment(
          assignmentId,
          employeeName,
          projectName,
          startTS,
          endTS,
          note,
          reserved,
          errorCB,
          succedCB,
          historyId
        );
      },
      err => {
        /* ec */
        console.error('Error238197');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    );
  }

  public undoMultiAssignment(
    changerId: string,
    objectIds: string[],
    historyIds: string[],
    changeTyp: string,
    succedCB: (arg0?: string[], arg1?: any[], arg2?: string) => void,
    errorCB: (err: string) => void
  ) {
    switch (changeTyp) {
      case 'added':
        this.undoMultiAssignmentAdd(
          changerId,
          objectIds,
          historyIds,
          succedCB,
          errorCB
        );
        return;

      case 'updated':
        this.undoMultiAssignmentUpdate(
          changerId,
          objectIds,
          historyIds,
          succedCB,
          errorCB
        );
        return;

      case 'removed':
        this.undoMultiAssignmentRemove(
          changerId,
          objectIds,
          historyIds,
          succedCB,
          errorCB
        );
        break;

      default:
        errorCB(Ci18n.translate('Fehler'));
        return;
    }
  }

  private undoMultiAssignmentAdd(
    changerId: string,
    assignmentIds: string[],
    historyIds: string[],
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    this.getLatestHistoryObjsOfAssignments(
      assignmentIds,
      (lastHOs, lastHIds) => {
        if (JSON.stringify(lastHIds) !== JSON.stringify(historyIds)) {
          errorCB(Ci18n.translate('Einträge sind nicht mehr aktuell'));
          return;
        }

        this.deleteMultipleAssignments(
          assignmentIds,
          errorCB,
          succedCB,
          lastHIds
        );
      },
      err => {
        /* ec */
        console.error('Error183072');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    );
  }

  private undoMultiAssignmentUpdate(
    changerId: string,
    assignmentIds: string[],
    historyIds: string[],
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    this.getLatestTwoHistoryObjsOfAssignments(
      assignmentIds,
      (lastHOTable, lastHIdTable) => {
        if (lastHIdTable.length !== historyIds.length) {
          /* ec */
          console.error('Error230495');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }

        let employeeId: string;
        let projectId: string;
        const startTSEndTSArray: number[][] = [];
        const notes: string[] = [];
        const reserved: boolean[] = [];

        for (let i = 0; i < historyIds.length; i++) {
          if (lastHIdTable[i][0] !== historyIds[i]) {
            errorCB(Ci18n.translate('Einträge sind nicht mehr aktuell'));
            return;
          }

          const assignmentObj = lastHOTable[i][1]['object'];

          const tempEmployeeId = assignmentObj.employeeId;
          const tempProjectId = assignmentObj.projectId;
          const tempStartTS = assignmentObj.start;
          const tempEndTS = assignmentObj.end;
          const tempNote = assignmentObj.note;
          const tempReserved = assignmentObj.reserved;

          if (tempEmployeeId !== employeeId) {
            if (!employeeId) {
              employeeId = tempEmployeeId;
            } else {
              /* ec */
              console.error('Error204986');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          }

          if (tempProjectId !== projectId) {
            if (!projectId) {
              projectId = tempProjectId;
            } else {
              /* ec */
              console.error('Error209374');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          }

          startTSEndTSArray.push([tempStartTS, tempEndTS]);
          notes.push(tempNote);
          reserved.push(tempReserved);
        }

        this.updateMultipleAssignments(
          assignmentIds,
          employeeId,
          projectId,
          startTSEndTSArray,
          notes,
          reserved,
          errorCB,
          succedCB,
          historyIds
        );
      },
      err => {
        /* ec */
        console.error('Error324790');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    );
  }

  private undoMultiAssignmentRemove(
    changerId: string,
    assignmentIds: string[],
    historyIds: string[],
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    this.getLatestTwoHistoryObjsOfAssignments(
      assignmentIds,
      (lastHOTable, lastHIdTable) => {
        if (lastHIdTable.length !== historyIds.length) {
          /* ec */
          console.error('Error230495');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }

        let employeeId: string;
        let projectId: string;
        const startTSEndTSArray: number[][] = [];
        const notes: string[] = [];
        const reserved: boolean[] = [];

        for (let i = 0; i < historyIds.length; i++) {
          if (lastHIdTable[i][0] !== historyIds[i]) {
            errorCB(Ci18n.translate('Einträge sind nicht mehr aktuell'));
            return;
          }

          const assignmentObj = lastHOTable[i][1]['object'];

          const tempEmployeeId = assignmentObj.employeeId;
          const tempProjectId = assignmentObj.projectId;
          const tempStartTS = assignmentObj.start;
          const tempEndTS = assignmentObj.end;
          const tempNote = assignmentObj.note;
          const tempReserved = assignmentObj.reserved;

          if (tempEmployeeId !== employeeId) {
            if (!employeeId) {
              employeeId = tempEmployeeId;
            } else {
              /* ec */
              console.error('Error204986');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          }

          if (tempProjectId !== projectId) {
            if (!projectId) {
              projectId = tempProjectId;
            } else {
              /* ec */
              console.error('Error209374');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          }

          startTSEndTSArray.push([tempStartTS, tempEndTS]);
          notes.push(tempNote);
          reserved.push(tempReserved);
        }

        const employeeName = this.getEmployeeName(employeeId);
        const projectName = this.getProjectName(projectId);

        this.setMultipleAssignments(
          assignmentIds,
          employeeName,
          projectName,
          startTSEndTSArray,
          notes,
          reserved,
          errorCB,
          succedCB,
          historyIds
        );
      },
      err => {
        /* ec */
        console.error('Error324790');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    );
  }

  public redoAssignment(
    changerId: string,
    objectId: string,
    historyId: string,
    changeTyp: string,
    objects,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    switch (changeTyp) {
      case 'added':
        this.redoAssignmentAdd(
          changerId,
          objectId,
          historyId,
          objects,
          succedCB,
          errorCB
        );
        return;

      case 'updated':
        this.redoAssignmentUpdate(
          changerId,
          objectId,
          historyId,
          objects,
          succedCB,
          errorCB
        );
        return;

      case 'removed':
        this.redoAssignmentRemove(
          changerId,
          objectId,
          historyId,
          succedCB,
          errorCB
        );
        break;

      default:
        errorCB(Ci18n.translate('Fehler'));
        /* ec */
        console.error('Error255769');
        return;
    }
  }

  private redoAssignmentAdd(
    changerId: string,
    assignmentId: string,
    historyId: string,
    assignmentObj: Assignment,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const employeeId = assignmentObj.employeeId;
    const projectId = assignmentObj.projectId;
    const startTS = assignmentObj.start;
    const endTS = assignmentObj.end;
    const note = assignmentObj.note;
    const reserved = assignmentObj.reserved;

    const employeeName = this.getEmployeeName(employeeId);
    const projectName = this.getProjectName(projectId);

    this.setSingleAssignment(
      assignmentId,
      employeeName,
      projectName,
      startTS,
      endTS,
      note,
      reserved,
      errorCB,
      succedCB,
      null,
      historyId
    );
  }

  private redoAssignmentUpdate(
    changerId: string,
    assignmentId: string,
    historyId: string,
    assignmentObj: Assignment,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const employeeId = assignmentObj.employeeId;
    const projectId = assignmentObj.projectId;
    const startTS = assignmentObj.start;
    const endTS = assignmentObj.end;
    const note = assignmentObj.note;
    const reserved = assignmentObj.reserved;

    const employeeName = this.getEmployeeName(employeeId);
    const projectName = this.getProjectName(projectId);

    this.updateSingleAssignment(
      assignmentId,
      employeeName,
      projectName,
      startTS,
      endTS,
      note,
      reserved,
      errorCB,
      succedCB,
      null,
      historyId
    );
  }

  private redoAssignmentRemove(
    changerId: string,
    assignmentId: string,
    historyId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    this.deleteSingleAssignment(
      assignmentId,
      errorCB,
      succedCB,
      null,
      historyId
    );
  }

  public redoMultiAssignment(
    changerId: string,
    objectIds: string[],
    historyIds: string[],
    changeTyp: string,
    objects: Assignment[],
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    switch (changeTyp) {
      case 'added':
        this.redoMultiAssignmentAdd(
          changerId,
          objectIds,
          historyIds,
          objects,
          succedCB,
          errorCB
        );
        return;

      case 'updated':
        this.redoMultiAssignmentUpdate(
          changerId,
          objectIds,
          historyIds,
          objects,
          succedCB,
          errorCB
        );
        return;

      case 'removed':
        this.redoMultiAssignmentRemove(
          changerId,
          objectIds,
          historyIds,
          objects,
          succedCB,
          errorCB
        );
        break;

      default:
        errorCB(Ci18n.translate('Fehler'));
        return;
    }
  }

  private redoMultiAssignmentAdd(
    changerId: string,
    assignmentIds: string[],
    historyIds: string[],
    assignmentObjs: Assignment[],
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    let employeeId: string;
    let projectId: string;
    const startTSEndTSArray: number[][] = [];
    const notes: string[] = [];
    const reserved: boolean[] = [];

    for (let i = 0; i < historyIds.length; i++) {
      const assignmentObj = assignmentObjs[i];

      const tempEmployeeId = assignmentObj.employeeId;
      const tempProjectId = assignmentObj.projectId;
      const tempStartTS = assignmentObj.start;
      const tempEndTS = assignmentObj.end;
      const tempNote = assignmentObj.note;
      const tempReserved = assignmentObj.reserved;

      if (tempEmployeeId !== employeeId) {
        if (!employeeId) {
          employeeId = tempEmployeeId;
        } else {
          /* ec */
          console.error('Error204986');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      }

      if (tempProjectId !== projectId) {
        if (!projectId) {
          projectId = tempProjectId;
        } else {
          /* ec */
          console.error('Error209374');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      }

      startTSEndTSArray.push([tempStartTS, tempEndTS]);
      notes.push(tempNote);
      reserved.push(tempReserved);
    }

    const employeeName = this.getEmployeeName(employeeId);
    const projectName = this.getProjectName(projectId);

    this.setMultipleAssignments(
      assignmentIds,
      employeeName,
      projectName,
      startTSEndTSArray,
      notes,
      reserved,
      errorCB,
      succedCB,
      null,
      historyIds
    );
  }

  private redoMultiAssignmentUpdate(
    changerId: string,
    assignmentIds: string[],
    historyIds: string[],
    assignmentObjs: Assignment[],
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    let employeeId: string;
    let projectId: string;
    const startTSEndTSArray: number[][] = [];
    const notes: string[] = [];
    const reserved: boolean[] = [];

    for (let i = 0; i < historyIds.length; i++) {
      const assignmentObj = assignmentObjs[i];

      const tempEmployeeId = assignmentObj.employeeId;
      const tempProjectId = assignmentObj.projectId;
      const tempStartTS = assignmentObj.start;
      const tempEndTS = assignmentObj.end;
      const tempNote = assignmentObj.note;
      const tempReserved = assignmentObj.reserved;

      if (tempEmployeeId !== employeeId) {
        if (!employeeId) {
          employeeId = tempEmployeeId;
        } else {
          /* ec */
          console.error('Error204986');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      }

      if (tempProjectId !== projectId) {
        if (!projectId) {
          projectId = tempProjectId;
        } else {
          /* ec */
          console.error('Error209374');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      }

      startTSEndTSArray.push([tempStartTS, tempEndTS]);
      notes.push(tempNote);
      reserved.push(tempReserved);
    }

    this.updateMultipleAssignments(
      assignmentIds,
      employeeId,
      projectId,
      startTSEndTSArray,
      notes,
      reserved,
      errorCB,
      succedCB,
      null,
      historyIds
    );
  }

  private redoMultiAssignmentRemove(
    changerId: string,
    assignmentIds: string[],
    historyIds: string[],
    assignmentObjs: Assignment[],
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    this.deleteMultipleAssignments(
      assignmentIds,
      errorCB,
      succedCB,
      null,
      historyIds
    );
  }

  private evaluateBlockTS(blockTS: number): boolean {
    const tsNow = Date.now();

    if (!tsNow) {
      /* ec */
      console.error('Error332353');
      return;
    }

    const tsDelta = tsNow - blockTS;
    if (tsDelta >= this.blockTimeout) {
      return false;
    }
    return true;
  }

  private evaluateEmployeeBlockState(employeeId, employee: Employee): boolean {
    if (!employee.blockedAt) {
      return false;
    }
    if (this.isListedBlockedObj(employeeId)) {
      return false;
    }

    const returnValue = this.evaluateBlockTS(employee.blockedAt.toMillis());
    return returnValue;
  }

  public getEmployeeBlockedState(employeeName: string): boolean {
    const employee: Employee = this.getEmployeeByName(employeeName);
    if (!employee) {
      /* ec */
      console.warn('Error102875');
      return null;
    }

    const employeeId = this.getEmployeeId(employeeName);
    if (!employeeId) {
      /* ec */
      console.error('Error230847');
      return null;
    }

    return this.evaluateEmployeeBlockState(employeeId, employee);
  }

  public getEmployeeBlockStateByEmplo(employeeId: string): boolean {
    const employee: Employee = this.getEmployeeById(employeeId);

    if (!employee) {
      /* ec */
      console.error('Error106146');
      return null;
    }

    return this.evaluateEmployeeBlockState(employeeId, employee);
  }

  private isEmployeeBlocked(
    employeeId: string,
    succedCB: (arg0: boolean) => void,
    errorCB: (err: string) => void
  ) {
    this.getEmployeeMetaData(
      employeeId,
      employee => {
        if (!employee) {
          /* ec */
          console.error('Error384206');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }

        const returnValue = this.evaluateEmployeeBlockState(employeeId, employee);
        succedCB(returnValue);
      },
      err => {
        /* ec */
        console.error('Error012760');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    );
  }

  // private setEmployeeBlocked(employeeId: string, succedCB: () => void, errorCB: (err: string) => void) {

  // }

  // private setEmployeeUnblocked(employeeId: string, succedCB: () => void, errorCB: (err: string) => void) {

  // }

  public blockEmployeeByName(
    employeeName: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const employeeId = this.getEmployeeId(employeeName);

    if (!employeeId) {
      /* ec */
      console.error('Error314762');
      errorCB(Ci18n.translate('Mitarbeiter konnte nicht gefunden werden'));
      return;
    }

    this.blockEmployee(employeeId, succedCB, errorCB);
  }

  private blockEmployee(
    employeeId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const employeeDoc = this.afs.doc(
      '/clients/' + this.clientId + '/employees/' + employeeId
    );

    employeeDoc
      .update({blockedAt: FieldValue.serverTimestamp()})
      .then(() => {
        this.listBlockedObj(employeeId);
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error435312');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public unblockEmployeeByName(
    employeeName: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const employeeId = this.getEmployeeId(employeeName);

    if (!employeeId) {
      /* ec */
      console.error('Error314762');
      errorCB(Ci18n.translate('Mitarbeiter konnte nicht gefunden werden'));
      return;
    }

    this.unblockEmployee(employeeId, succedCB, errorCB);
  }

  private unblockEmployee(
    employeeId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const employeeDoc = this.afs.doc(
      '/clients/' + this.clientId + '/employees/' + employeeId
    );

    employeeDoc
      .update({blockedAt: FieldValue.delete()})
      .then(() => {
        this.unlistBlockedObj(employeeId);
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error276920');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  private evaluateProjectBlockState(projectId, project: Project): boolean {
    if (!project.blockedAt) {
      return false;
    }
    if (this.isListedBlockedObj(projectId)) {
      return false;
    }
    const returnValue = this.evaluateBlockTS(project.blockedAt.toMillis());
    return returnValue;
  }

  public getProjectBlockState(projectName: string): boolean {
    const project = this.getProjectByName(projectName);

    if (!project) {
      /* ec */
      console.error('Error823706');
      return null;
    }

    const projectId = this.getProjectId(projectName);

    if (!projectId) {
      /* ec */
      console.error('Error107745');
      return null;
    }

    return this.evaluateProjectBlockState(projectId, project);
  }

  private isProjectBlocked(
    projectId: string,
    succedCB: (arg0: boolean) => void,
    errorCB: (err: string) => void
  ) {
    this.getProject(
      projectId,
      project => {
        if (!project) {
          /* ec */
          console.error('Error108374');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }

        const returnValue = this.evaluateProjectBlockState(projectId, project);
        succedCB(returnValue);
      },
      err => {
        /* ec */
        console.error('Error130748');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      }
    );
  }

  // private setProjectBlocked(projectId: string, succedCB: () => void, errorCB: (err: string) => void) {
  // }

  // private setProjectUnblocked(projectId: string, succedCB: () => void, errorCB: (err: string) => void) {
  // }

  public blockProjectByName(
    projectName: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const projectId = this.getProjectId(projectName);

    if (!projectId) {
      /* ec */
      console.error('Error839742');
      errorCB(Ci18n.translate('Projekt nicht gefunden'));
      return;
    }

    this.blockProject(projectId, succedCB, errorCB);
  }

  private blockProject(
    projectId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const projectDoc = this.afs.doc(
      '/clients/' + this.clientId + '/projects/' + projectId
    );

    projectDoc
      .update({blockedAt: FieldValue.serverTimestamp()})
      .then(() => {
        this.listBlockedObj(projectId);
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error326012');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public unblockProjectByName(
    projectName: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const projectId = this.getProjectId(projectName);

    if (!projectId) {
      /* ec */
      console.error('Error839742');
      errorCB(Ci18n.translate('Projekt nicht gefunden'));
      return;
    }

    this.unblockProject(projectId, succedCB, errorCB);
  }

  private unblockProject(
    projectId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const projectDoc = this.afs.doc(
      '/clients/' + this.clientId + '/projects/' + projectId
    );

    projectDoc
      .update({blockedAt: FieldValue.delete()})
      .then(() => {
        this.unlistBlockedObj(projectId);
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error234689');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public evaluateAssignmentBlockState(
    assignmentId: string,
    assignment: Assignment
  ): boolean {
    if (!assignment.blockedAt) {
      return false;
    }
    if (this.isListedBlockedObj(assignmentId)) {
      return false;
    }
    const returnValue = this.evaluateBlockTS(assignment.blockedAt.toMillis());
    return returnValue;
  }

  public evaluateAssignmentBlockStateByIndex(
    assignmentI: number,
    assignment: Assignment
  ) {
    const assignmentId: string = this._assignmentIds[assignmentI];

    if (!assignmentId) {
      /* ec */
      console.error('Error686145');
      return null;
    }

    return this.evaluateAssignmentBlockState(assignmentId, assignment);
  }

  private isAssignmentBlocked(
    assignmentId: string,
    succedCB: (arg0: boolean) => void,
    errorCB: (err: string) => void
  ) {
    this.getAssignment(
      assignmentId,
      err => {
        /* ec */
        console.error('Error193637');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      },
      assignment => {
        if (!assignment) {
          /* ec */
          console.error('Error034986');
          errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          return;
        }

        const returnValue = this.evaluateAssignmentBlockState(
          assignmentId,
          assignment
        );
        succedCB(returnValue);
      }
    );
  }

  // private setAssignmentBlocked(assignmentId: string, succedCB: () => void, errorCB: (err: string) => void) {
  // }

  // private setAssignmentUnblocked(assignmentId: string, succedCB: () => void, errorCB: (err: string) => void) {
  // }

  public blockAssignmentByIndex(
    assignmentI: number,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const assignmentKey: string = this._assignmentIds[assignmentI];
    if (!assignmentKey) {
      errorCB(Ci18n.translate('Zuweisung nicht gefunden'));
      return;
    }

    this.blockAssignment(assignmentKey, succedCB, errorCB);
  }

  public getAssignmentKeyByIndex(assignmentIndex: number){
    const assignmentKey: string = this._assignmentIds[assignmentIndex];
    if (!assignmentKey) {
      return;
    }
    else return assignmentKey
  }
  private blockAssignment(
    assignmentId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const assignmentDoc = this.afs.doc(
      '/clients/' + this.clientId + '/assignments/' + assignmentId
    );

    assignmentDoc
      .update({blockedAt: FieldValue.serverTimestamp()})
      .then(() => {
        this.listBlockedObj(assignmentId);
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error219357');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public unblockAssignmentByIndex(
    assignmentI: number,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const assignmentKey: string = this._assignmentIds[assignmentI];

    if (!assignmentKey) {
      errorCB(Ci18n.translate('Zuweisung nicht gefunden'));
      return;
    }

    this.unblockAssignment(assignmentKey, succedCB, errorCB);
  }

  private unblockAssignment(
    assignmentId: string,
    succedCB: () => void,
    errorCB: (err: string) => void
  ) {
    const assignmentDoc = this.afs.doc(
      '/clients/' + this.clientId + '/assignments/' + assignmentId
    );

    assignmentDoc
      .update({blockedAt: FieldValue.delete()})
      .then(() => {
        this.unlistBlockedObj(assignmentId);
        succedCB();
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error219357');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  private isListedBlockedObj(id: string) {
    if (this.myEditRequest.indexOf(id) !== -1) {
      return true;
    }
    return false;
  }

  private listBlockedObj(id: string) {
    if (this.myEditRequest.indexOf(id) !== -1) {
      return;
    }
    this.myEditRequest.push(id);
  }

  private unlistBlockedObj(id: string) {
    const tempI = this.myEditRequest.indexOf(id);

    if (tempI === -1) {
      return;
    }
    this.myEditRequest.splice(tempI, 1);
  }

  public getLayout(
    layoutName: string,
    errorCB: (err: string) => void,
    succedCB: (arg0: any) => void
  ) {
    const tempI = this._layoutNames.indexOf(layoutName);
    if (tempI === -1) {
      errorCB(Ci18n.translate('Zuweisung nicht gefunden'));
      return;
    }

    const copiedLayout = this.copyObject(this._layouts[tempI]);

    succedCB(copiedLayout);
  }

  public deleteLayout(
    layoutName: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    const docPath = '/users/' + this._uId + '/layouts/' + layoutName;
    const layoutDoc = this.afs.firestore.doc(docPath);

    layoutDoc
      .get()
      .then(layoutSnap => {
        if (!layoutSnap.exists) {
          errorCB(Ci18n.translate('Zuweisung nicht gefunden'));
          return;
        }

        layoutDoc
          .delete()
          .then(() => {
            succedCB();
          })
          .catch(err => {
            errorCB(Ci18n.translate('Layout konnte nicht gelöscht werden'));
            return;
          });
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error928635');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public changeLayoutName(
    oldLayoutName: string,
    newLayoutName: string,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    if (!this.isIdValid(newLayoutName)) {
      errorCB(Ci18n.translate('Name ungültig'));
      return;
    }

    const oldDocPath = '/users/' + this._uId + '/layouts/' + oldLayoutName;
    const oldLayoutDoc = this.afs.firestore.doc(oldDocPath);

    const newDocPath = '/users/' + this._uId + '/layouts/' + newLayoutName;
    const newLayoutDoc = this.afs.firestore.doc(newDocPath);

    newLayoutDoc
      .get()
      .then(newLayoutSnap => {
        if (newLayoutSnap.exists) {
          errorCB(Ci18n.translate('Name bereits vergeben'));
          return;
        }

        oldLayoutDoc
          .get()
          .then(oldLayoutSnap => {
            if (!oldLayoutSnap.exists) {
              errorCB(Ci18n.translate('Layout nicht gefunden'));
              return;
            }

            const batch = this.afs.firestore.batch();
            batch.set(newLayoutDoc, oldLayoutSnap.data());
            batch.delete(oldLayoutDoc);

            batch
              .commit()
              .then(() => {
                succedCB();
              })
              .catch(err => {
                /* ec */
                console.error('Error192357');
                errorCB(Ci18n.translate('Layout konnte nicht gelöscht werden'));
                return;
              });
          })
          .catch(err => {
            /* ec */
            console.error('Error928636');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          });
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error928637');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public getLayoutNames(
    errorCB: (err: string) => void,
    succedCB: (arg0: string[]) => void
  ) {
    succedCB(this._layoutNames.slice(0));
  }

  public saveLayout(
    layoutName: string,
    weekview: boolean,
    teTd: number,
    startTime: number,
    endTime: number,
    weekDays: number,
    fontsize: number,
    employeesTD_names: string[],
    employeeCount: number,
    errorCB: (err: string) => void,
    succedCB: () => void
  ) {
    const docPath = '/users/' + this._uId + '/layouts/' + layoutName;
    const layoutDoc = this.afs.firestore.doc(docPath);

    layoutDoc
      .get()
      .then(layoutSnap => {
        if (layoutSnap.exists) {
          errorCB(Ci18n.translate('Name bereits vergeben'));
          return;
        }

        const employeesTD_Ids: string[] = [];

        employeesTD_names.forEach(employeeTD_name => {
          const tempI = this._employeeNames.indexOf(employeeTD_name);

          if (tempI < 0) {
            return; // foreach continue
          }

          const id = this._employeeNamesIds[tempI];

          if (id) {
            employeesTD_Ids.push(id);
          }
        });

        const layoutObj = {
          weekview: weekview,
          w_d_td: teTd,
          startTime: startTime,
          endTime: endTime,
          weekDays: weekDays,
          fontsize: fontsize,
          employeesTD: employeesTD_Ids,
          employeeCount: employeeCount
        };

        layoutDoc
          .set(layoutObj)
          .then(() => {
            succedCB();
          })
          .catch(
            err => {
              /* ec */
              console.error('Error498135');
              errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            }
          );
      })
      .catch(err => {
        console.error(err)
        /* ec */
        console.error('Error123980');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
  }

  public getArchivedProjects(): Promise<any> {
    return new Promise<any>((res, rej) => {
      const arcProjsCol = this.afs.firestore.collection('/clients/' + this.clientId + '/archivedProjects/');

      arcProjsCol.get().then(projectSnapList => {
        if (projectSnapList.size === 0) {
          res([[], []]);
          return;
        }

        const projects: Project[] = [];
        const projectIds: string[] = [];

        const projectCount = projectSnapList.size;
        let counter = 0;

        const getCreatedAtAndPushToList: (arg0: Project, arg1: string) => void = (tempProj, tempProjId) => {
          this.getProjectCreatedAt(tempProjId).then(ts => {
            tempProj['createdAt'] = ts;
            projects[counter] = tempProj;
            projectIds[counter] = tempProjId;

            counter++;
            if (counter === projectCount) {
              res([projects, projectIds]);
            }
          }).catch(() => {
            tempProj['createdAt'] = 0;
            projects[counter] = tempProj;
            projectIds[counter] = tempProjId;

            counter++;
            if (counter === projectCount) {
              res([projects, projectIds]);
            }
          });
        };

        for (let i = 0; i < projectSnapList.docs.length; i++) {
          const tempDoc = projectSnapList.docs[i];
          const tempProjId = tempDoc.id;
          const tempProj = this.convertDBObjToProject(tempDoc.data());

          if (!tempProj) {
            /* ec */
            console.error('Error341468');
            rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            return;
          }

          projects.push(undefined);
          projectIds.push(undefined);
          getCreatedAtAndPushToList(tempProj, tempProjId);
        }
      })
        .catch(err => {
          /* ec */
          console.error('Error743687');
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        });
    });
  }

  public getArchivedProject(projectId: string): Promise<any> {
    return new Promise<any>((res, rej) => {
      const arcProjDoc = this.afs.firestore.doc('/clients/' + this.clientId + '/archivedProjects/' + projectId);

      arcProjDoc.get().then(projectSnap => {
        if (!projectSnap.exists) {
          /* ec */
          console.error('Error617846');
          rej(Ci18n.translate('Projekt konnte nicht gefunden werden'));
          return;
        }

        const project = this.convertDBObjToProject(projectSnap.data());

        if (!project) {
          /* ec */
          console.error('Error421684');
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          return;
        }

        this.getProjectCreatedAt(projectId).then(ts => {
          project['createdAt'] = ts;
          res(project);
        }).catch(() => {
          /* ec */
          console.warn('Error539815');
          project['createdAt'] = Date.now();
          res(project);
        });
      }).catch(err => {
        console.error(err)
        /* ec */
        console.error('Error324015');
        rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
    });
  }

  public getArchivedAssignmentsOfProject(projectId: string): Promise<any> {
    return new Promise<any>((res, rej) => {
      const projectAssignmentsCollection = this.afs.firestore.collection('/clients/' + this.clientId + '/archivedAssignments/');
      const tempQuery = projectAssignmentsCollection.where('projectId', '==', projectId);
      tempQuery.get().then(assiSnapList => {
        if (assiSnapList.size === 0) {
          res([[], []]);
          return;
        }

        const returnAssignments: Assignment[] = [];
        const returnIds: string[] = [];

        for (let i = 0; i < assiSnapList.docs.length; i++) {
          const tempAssiDoc = assiSnapList.docs[i];
          const tempId = tempAssiDoc.id;
          const tempAssi = this.convertDBObjToAssignment(tempAssiDoc.data(), tempId);

          if (!tempAssi) {
            /* ec */
            console.error('Error750327');
            rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
            return;
          }

          returnAssignments.push(tempAssi);
          returnIds.push(tempId);
        }

        res([returnAssignments, returnIds]);
      }).catch(error => {
        /* ec */
        console.error('Error958365');
        rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      });
    });
  }

  public getFirstStepsAchievements() {
    return new Promise<any>((res, rej) => {
      const tempDocRef = this.afs.firestore.doc('/users/' + this._uId + '/userDocBox/firstStepsAchievements');

      tempDocRef.get().then(docSnap => {
        if (!docSnap.exists) {
          tempDocRef.set({}).then(() => {
            res({});
          }).catch(err => {
            rej('Error435493');
          });
          return;
        }
        res(docSnap.data());
      }).catch(err => {
        rej('Error434345');
      });
    });
  }

  public setFirstStepAchievementAssignmentAdded() {
    return new Promise<any>((res, rej) => {
      const tempDocRef = this.afs.firestore.doc('/users/' + this._uId + '/userDocBox/firstStepsAchievements');
      tempDocRef.update({fs00_assignmentAdded: true}).then(() => {
        res();
      }).catch(err => {
        rej('Error738543');
      });
    });
  }

  public setFirstStepAchievementAssignmentDropped() {
    return new Promise<any>((res, rej) => {
      const tempDocRef = this.afs.firestore.doc('/users/' + this._uId + '/userDocBox/firstStepsAchievements');
      tempDocRef.update({fs01_assignmentDropped: true}).then(() => {
        res();
      }).catch(err => {
        rej('Error278654');
      });
    });
  }

  public setFirstStepAchievementAssignmentDeleted() {
    return new Promise<any>((res, rej) => {
      const tempDocRef = this.afs.firestore.doc('/users/' + this._uId + '/userDocBox/firstStepsAchievements');
      tempDocRef.update({fs02_assignmentDeleted: true}).then(() => {
        res();
      }).catch(err => {
        rej('Error515343');
      });
    });
  }

  public setFirstStepAchievementAssignmentEdited() {
    return new Promise<any>((res, rej) => {
      const tempDocRef = this.afs.firestore.doc('/users/' + this._uId + '/userDocBox/firstStepsAchievements');
      tempDocRef.update({fs03_assignmentEdited: true}).then(() => {
        res();
      }).catch(err => {
        rej('Error482814');
      });
    });
  }

  public setFirstStepAchievementAssignmentblockAdded() {
    return new Promise<any>((res, rej) => {
      const tempDocRef = this.afs.firestore.doc('/users/' + this._uId + '/userDocBox/firstStepsAchievements');
      tempDocRef.update({fs04_assignmentblockAdded: true}).then(() => {
        res();
      }).catch(err => {
        rej('Error176391');
      });
    });
  }

  public setFirstStepAchievementAssignmentblockMoved() {
    return new Promise<any>((res, rej) => {
      const tempDocRef = this.afs.firestore.doc('/users/' + this._uId + '/userDocBox/firstStepsAchievements');
      tempDocRef.update({fs05_assignmentblockMoved: true}).then(() => {
        res();
      }).catch(err => {
        rej('Error351403');
      });
    });
  }

  public getWarnMailTS() {
    return new Promise((res, rej) => {
      const cjc_col = this.afs.firestore.collection('_cjc');
      const query = cjc_col.where('cid', '==', this.clientId).where('exeType', '==', 'sendWarnMail');

      query.get().then(snapList => {
        if (snapList.size === 0) {
          res(-1);
          return;
        }

        const exeTS = snapList.docs[0].data().exeTS;
        if (!exeTS) {
          rej('Error168439');
        }
        res(exeTS);
      }).catch(err => {
        rej(err);
      });
    });
  }

  public getDeactivateClientTS() {
    return new Promise((res, rej) => {
      const cjc_col = this.afs.firestore.collection('_cjc');
      const query = cjc_col.where('cid', '==', this.clientId).where('exeType', '==', 'deactivateClient');

      query.get().then(snapList => {
        if (snapList.size === 0) {
          res(-1);
          return;
        }

        const exeTS = snapList.docs[0].data().exeTS;
        if (!exeTS) {
          rej('Error643434');
        }
        res(exeTS);
      }).catch(err => {
        rej(err);
      });
    });
  }

  //#endregion

  //#region cloud functions

  public startSubscription(arg0: any) {
    return new Promise((res, rej) => {
      const cf_startPayedSubscription = this.aff.httpsCallable('startPayedSubscription');

      if (!cf_startPayedSubscription) {
        rej('"startSubscription" unknown');
        return;
      }

      cf_startPayedSubscription(arg0).pipe(take(1)).toPromise().then(result => {
        if (result === 400) {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        } else {
          res();
        }
      }).catch(err => {
        rej(err);
      });

    });
  }

  public archiveProject(projectId: string): Promise<any> {
    return new Promise<any>((res, rej) => {
      const cf_archiveProject = this.aff.httpsCallable('archiveProject');

      if (!cf_archiveProject) {
        rej('"cf_archiveProject" unknown');
        return;
      }

      cf_archiveProject({pid: projectId}).pipe(take(1)).toPromise().then(result => {
        if (result === 200) {
          res();
        } else if (result === 400) {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        } else {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      }).catch(err => {
        rej(err);
      });
    });
  }

  public dearchiveProject(projectId: string): Promise<any> {
    return new Promise<any>((res, rej) => {
      const cf_dearchiveProject = this.aff.httpsCallable('dearchiveProject');

      if (!cf_dearchiveProject) {
        rej('"cf_dearchiveProject" unknown');
        return;
      }

      cf_dearchiveProject({pid: projectId}).pipe(take(1)).toPromise().then(result => {
        if (result === 200) {
          res();
        } else if (result === 400) {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        } else {
          rej(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        }
      }).catch(err => {
        rej(err);
      });
    });
  }

  //#endregion

  //#region en- & decode func
  public getLSKey(): string {
    let returnValue: string;

    if (this._uId.length <= 6) {
      /* ec */
      console.error('Error885568');
      return undefined;
    }

    returnValue = this._uId.slice(this._uId.length - 5, this._uId.length - 2);
    return returnValue;
  }

  //#endregion

  //#region helper
  private emailFormatCheck(email: string): boolean {
    const result = this.regExpEMail.test(email);
    return result;
  }

  private isIdValid(text) {
    const regExpInvalidFSID = new RegExp('^__.*__$|/|^\\.$|^\\.\\.$');

    const result = !regExpInvalidFSID.test(text);
    return result;
  }

  private objectsAreEqual(obj1: Object, obj2: Object) {
    if (obj1 === obj2) {
      return true;
    }
    let p: string;
    for (p of Object.keys(obj1)) {
      if (!obj2.hasOwnProperty(p)) {
        return false;
      }
      if (obj1[p] !== obj2[p]) {
        return false;
      }
    }
    for (p of Object.keys(obj2)) {
      if (!obj1.hasOwnProperty(p)) {
        return false;
      }
      if (obj1[p] !== obj2[p]) {
        return false;
      }
    }
    return true;
  }

  private copyObject(obj: Object) {
    const newObj = {};

    let p;
    for (p of Object.keys(obj)) {
      newObj[p] = obj[p];
    }

    return newObj;
  }

  private copyProject(project: Project): Project {
    const returnValue: Project = new Project();

    returnValue.name = project.name;
    returnValue.number = project.number;
    returnValue.color = project.color;
    returnValue.duration = project.duration;
    returnValue.note = project.note;
    returnValue.cloudLink = project.cloudLink;
    returnValue.reserved = project.reserved;
    returnValue.marker = project.marker;
    returnValue.timeToAllocate = project.timeToAllocate;
    returnValue.blockedAt = project.blockedAt;
    returnValue.isConflicted = project.isConflicted;
    returnValue.finished = project.finished;

    if (!!project['createdAt'] && typeof project['createdAt'] === 'number') {
      returnValue['createdAt'] = project['createdAt'];
    }

    return returnValue;
  }

  private copyEmployee(employee: Employee): Employee {
    const returnValue: Employee = new Employee();

    returnValue.name = employee.name;
    returnValue.number = employee.number;
    returnValue.group = employee.group;
    returnValue.dept = employee.dept;
    returnValue.otherInformation = employee.otherInformation;
    returnValue.user = employee.user;
    returnValue.scheduler = employee.scheduler;
    returnValue.selfEdit = employee.selfEdit;
    returnValue.blockedAt = employee.blockedAt;

    if (!!employee['ca']) {
      returnValue['ca'] = employee['ca'];
    }

    returnValue.accessesTo = [];
    employee.accessesTo.forEach(accessTo => {
      returnValue.accessesTo.push(this.copyAccess(accessTo));
    });

    returnValue.accessesBy = [];
    employee.accessesBy.forEach(accessBy => {
      returnValue.accessesBy.push(this.copyAccess(accessBy));
    });

    return returnValue;
  }

  private copyEmployeeAssignments(
    employeeAssignments: EmployeeAssignments
  ): EmployeeAssignments {
    const newEmployeeAssignments = new EmployeeAssignments();

    newEmployeeAssignments.employeeId = employeeAssignments.employeeId;

    if (!!employeeAssignments.assignmentIds) {
      newEmployeeAssignments.assignmentIds = [];
      employeeAssignments.assignmentIds.forEach(elem => {
        newEmployeeAssignments.assignmentIds.push(elem);
      });
    }

    if (!!employeeAssignments.assignments) {
      newEmployeeAssignments.assignments = [];
      employeeAssignments.assignments.forEach(elem => {
        newEmployeeAssignments.assignments.push(this.helper.copyAssignment(elem));
      });
    }

    return newEmployeeAssignments;
  }

  private copyAccess(access: Access): Access {
    const returnValue: Access = new Access(access.key, access.write);

    if (!!access['name'] && typeof access['name'] === 'string') {
      returnValue['name'] = access['name'];
    }
    return returnValue;
  }

  private addDaysToDate(date: Date, dayCount: number): boolean {
    if (!date || !date.valueOf || date.valueOf() === NaN) {
      return false;
    }

    date.setDate(date.getDate() + dayCount);
    return true;
  }

  private subtractDaysOfDate(date: Date, dayCount: number): boolean {
    return this.addDaysToDate(date, dayCount * -1);
  }

  private nextDayOfDate(date: Date): boolean {
    return this.addDaysToDate(date, 1);
  }

  private lastDayOfDate(date: Date): boolean {
    return this.subtractDaysOfDate(date, 1);
  }

  private setDate0oclock(date: Date): boolean {
    if (!date || !date.valueOf || date.valueOf() === NaN) {
      return false;
    }
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    return true;
  }

  private get0oclockTS(dateTS: number): number {
    this.tempDate1280.setTime(dateTS);
    this.setDate0oclock(this.tempDate1280);

    return this.tempDate1280.valueOf();
  }

  private getMondayTS(dateTS: number) {
    const date: Date = new Date(this.get0oclockTS(dateTS));

    let temp = date.getDay();

    if (temp === 1) {
      return date.valueOf();
    }

    if (temp === 0) {
      temp = 7;
    }

    this.subtractDaysOfDate(date, (temp - 1));
    return date.valueOf();
  }

  private calcPreloadLimiter(): number {
    const tempDate = new Date();

    tempDate.setMonth(tempDate.getMonth() - 6);
    const limiterTS = this.getMondayTS(tempDate.valueOf());

    console.log('calcPreloadLimiter:' + (new Date(limiterTS)));

    return limiterTS;
  }

  private convertDbObjToTimeTracking(obj: {}, id:string): TimeTracking{
    const returnValue: TimeTracking = {
      employeeId: obj["employeeId"],
      assignmentId: obj["assignmentId"],
      startTime: obj["startTime"],
      endTime: obj["endTime"],
      isStopped: obj["isStopped"],
      latitudeStart: obj["latitudeStart"],
      longitudeStart: obj["longitudeStart"],
      projectId: obj["projectId"],
      isApproved: obj["isApproved"],
      latitudeEnd: obj["latitudeEnd"],
      longitudeEnd: obj["longitudeEnd"],
      id: id
    }
    return returnValue
  }

  public async getAllTimeTrackings(start: number){
    const timeTrackingCollection = this.afs.firestore.collection(`/clients/${this.clientId}/timetracking`);
    const query = timeTrackingCollection.where("startTime", ">=", start)
    const timeTrackings = await query.get()
    return timeTrackings.docs.map(d => this.convertDbObjToTimeTracking(d.data(), d.id))
  }

  public async getTimeTracking(assignmentKey: string, employeeKey: string){
    const timeTrackingCollection = this.afs.firestore.collection(`/clients/${this.clientId}/timetracking`);
    const query = timeTrackingCollection.where("employeeId", "==", employeeKey).where("assignmentId", "==", assignmentKey)
    const timeTrackings = await query.get()
    return timeTrackings.docs.map(d => this.convertDbObjToTimeTracking(d.data(), d.id))
  }

  public async saveTimeTracking(timeTracking: TimeTracking){
    try {
      const id = this.helper.generatePushId()
      timeTracking.id = id
      const departmentDoc = this.afs.firestore.doc(`/clients/${this.clientId}/timetracking/${id}`)
      const res = await departmentDoc.set(timeTracking)
    } catch (e) {
      console.error(e)
    }
  }

  public async updateTimeTracking(timeTracking: TimeTracking){
    try {
      if(timeTracking.isApproved === undefined){
        timeTracking.isApproved = false
      }
      timeTracking.longitudeEnd = timeTracking.longitudeEnd ? timeTracking.longitudeEnd : null
      timeTracking.latitudeEnd = timeTracking.latitudeEnd ? timeTracking.latitudeEnd : null
      const timeTrackingDoc = this.afs.firestore.doc(`/clients/${this.clientId}/timetracking/${timeTracking.id}`)
      const res = await timeTrackingDoc.set(timeTracking)
    } catch (e) {
      console.error(e)
    }
  }

  public async getTimeTrackingsForEmployee(employeeKey:string){
    const timeTrackingCollection = this.afs.firestore.collection(`/clients/${this.clientId}/timetracking`);
    const query = timeTrackingCollection.where("employeeId", "==", employeeKey)
    const test = await query.get()
    return test.docs.map(d => this.convertDbObjToTimeTracking(d.data(), d.id))
  }

  //#endregion
}
