import {Component, Input, OnInit} from '@angular/core';
import {Dbi2Service} from "../dbi2.service";
import {Department, Group} from "../model/departments.model";
import {Ci18n} from "../ci18n";
import {Employee} from "../model/employee.model";

enum ShowDialogState {
  NONE,
  ADD_DEPARTMENT,
  ADD_GROUP,
  EDIT_DEPARTMENT,
  EDIT_GROUP
}

@Component({
  selector: 'app-departments-and-groups',
  templateUrl: './departments-and-groups.component.html',
  styleUrls: ['./departments-and-groups.component.scss']
})
export class DepartmentsAndGroupsComponent implements OnInit {

  departments: Department[] = [];
  groups: Group[] = [];
  employees: Employee[] = [];
  @Input() isMobile: boolean;
  dialog: ShowDialogState = ShowDialogState.NONE
  showDialog: boolean = false;

  selectedName: string = "";
  selectedEmployees: Employee[] = [];
  filteredEmployees: Employee[] = [];
  oldSelectedEmployees: Employee[] = [];
  selectedId: string = "";


  constructor(private dbi: Dbi2Service) {
  }

  async ngOnInit() {
    this.departments = await this.dbi.getDepartments()
    this.groups = await this.dbi.getGroups()
    this.employees = await this.dbi.getAllEmployees()
  }

  async add() {
    const entry = {
      name: this.selectedName,
      id: this.selectedId
    }
    const oldEmployeeSet = new Set(this.oldSelectedEmployees)
    const newEmployeeSet = new Set(this.selectedEmployees)
    const removedEmployees = this.oldSelectedEmployees.filter(e => !newEmployeeSet.has(e))
    const addedEmployees = this.selectedEmployees.filter(e => !oldEmployeeSet.has(e))

    switch (this.dialog) {
      case ShowDialogState.ADD_DEPARTMENT:
        await this.dbi.addDepartment(entry)
        await this.dbi.updateDepartment(entry, removedEmployees, addedEmployees)
        this.departments = await this.dbi.getDepartments()
        break;
      case ShowDialogState.ADD_GROUP:
        await this.dbi.addGroup(entry)
        await this.dbi.updateGroup(entry, removedEmployees, addedEmployees)
        this.groups = await this.dbi.getGroups()
        break;
      case ShowDialogState.EDIT_DEPARTMENT:
        await this.dbi.updateDepartment(entry, removedEmployees, addedEmployees)
        this.departments = await this.dbi.getDepartments()
        break;
      case ShowDialogState.EDIT_GROUP:
        await this.dbi.updateGroup(entry, removedEmployees, addedEmployees)
        this.groups = await this.dbi.getGroups()
        break;
    }
    this.employees = await this.dbi.getAllEmployees();
    this.dialog = ShowDialogState.NONE
    this.showDialog = false
    this.closeDialog()
  }

  public translate(text: string): string {
    return Ci18n.translate(text);
  }

  public getDialogHeader() {
    switch (this.dialog) {
      case ShowDialogState.ADD_DEPARTMENT:
        return "Abteilung hinzufügen";
      case ShowDialogState.ADD_GROUP:
        return "Gruppe hinzufügen";
      case ShowDialogState.EDIT_DEPARTMENT:
        return "Abteilung bearbeiten";
      case ShowDialogState.EDIT_GROUP:
        return "Gruppe bearbeiten";
      default:
        return "";
    }
  }

  openDialog(type: ShowDialogState, data?: Department | Group) {
    this.selectedName = data?.name
    this.selectedId = data?.id
    if (type === ShowDialogState.EDIT_DEPARTMENT) {
      this.filteredEmployees = this.employees.map(e => e).filter(e =>!e.inactive && (!e.dept || (e.dept && !(e.dept === data.id ||
        (typeof e.dept !== "string" &&
          e.dept.find(e => e === data.id))))))
      this.selectedEmployees = this.employees.map(e => e).filter(e =>!e.inactive && ( e.dept && (e.dept === data.id ||
        (typeof e.dept !== "string" &&
          e.dept.find(e => e === data.id)))))
      this.oldSelectedEmployees = this.employees.map(e => e).filter(e =>!e.inactive && ( e.dept && (e.dept === data.id ||
        (typeof e.dept !== "string" &&
          e.dept.find(e => e === data.id)))))
    } else if (type === ShowDialogState.EDIT_GROUP) {
      this.filteredEmployees = this.employees.map(e => e).filter(e =>!e.inactive && (!e.group|| (e.group && !(e.group === data.id ||
        (typeof e.group !== "string" &&
          e.group.find(e => e === data.id))))))
      this.selectedEmployees = this.employees.map(e => e).filter(e =>!e.inactive && ( e.group && (e.group === data.id ||
        (typeof e.group !== "string" &&
          e.group.find(e => e === data.id)))))
      this.oldSelectedEmployees = this.employees.map(e => e).filter(e =>!e.inactive && ( e.group && (e.group === data.id ||
        (typeof e.group !== "string" &&
          e.group.find(e => e === data.id)))))
    } else {
      this.filteredEmployees = this.employees
      this.selectedEmployees = []
      this.oldSelectedEmployees = []
    }

    this.dialog = type;
    this.showDialog = true;
  }

  closeDialog() {
    this.selectedId = "";
    this.selectedEmployees = [];
    this.selectedName = "";
    this.dialog = ShowDialogState.NONE;
    this.showDialog = false;
  }

  async delete(type:"department"|"group", data:Department|Group ){
    if(type==="department"){
      await this.dbi.deleteDepartment(data)
      this.departments = await this.dbi.getDepartments()
    }
    else{
      await this.dbi.deleteGroup(data)
      this.groups = await this.dbi.getGroups()
    }
  }
}

