export class Access {

  key: string;
  write: boolean;
  constructor(key?: string, write?: boolean/*, name?: string */) {
    if (key === undefined || key === null || write === null || write === undefined) {
      return;
    }
    this.key = key;
    this.write = write;
    // this.name = name;
  }
  name: string;
}
