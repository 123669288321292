import { Injectable } from '@angular/core';
import { Ci18n } from '../ci18n';
import * as firebase from 'firebase/app';
import { HelperService } from './helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { CommitType, DeleteAssignmentConflict, DeleteAssignmentHelper } from '../model/assignment.model';
import { Project } from '../model/project.model';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: 'root',
})
export class DeleteAssignmentService {

  constructor(private afs: AngularFirestore, private helper: HelperService) {
  }

  async deleteMultipleAssignments(
    assignmentIdsToDelete: string[],
    assignmentsToDelete: DeleteAssignmentHelper[],
    errorCB: (arg0: string) => void,
    succedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void,
    msPerMinute: number,
    clientId: string,
    usersEmployeeId: string,
    usersEmployeeName: string,
    project: Project,
    undoHistoryIds?: string[],
    redoHistoryIds?: string[],
  ) {

    let allocatedTime = 0;
    let newTTA: number;

    const conflicts = await this.helper.loadAllConflictingAssignments(clientId, assignmentIdsToDelete);

    const projectId = assignmentsToDelete[0].assignment.projectId;
    const employeeId = assignmentsToDelete[0].assignment.employeeId;

    const assignmentsAndConflictsToDelete: DeleteAssignmentConflict[] =
      assignmentsToDelete.map((assignmentToDelete: DeleteAssignmentHelper) => {
        const assignment = assignmentToDelete.assignment;

        if (projectId !== assignment.projectId) {
          errorCB(
            Ci18n.translate('Nur Zuweisungen des selben Projekts können zusammen entfernet werden'),
          );
          /* ec */
          console.error('Error667887');
          return;
        }

        if (employeeId !== assignment.employeeId) {
          errorCB(
            Ci18n.translate('Nur Zuweisungen des selben Mitarbeiters können zusammen entfernet werden'),
          );
          /* ec */
          console.error('Error209013');
          return;
        }

        allocatedTime += (assignment.end - assignment.start) / msPerMinute;

        const conflictingData = conflicts.find(conflict => conflict.assignmentId === assignmentToDelete.assignmentId);
        if (!!conflictingData) {
          return {
            assignmentId: assignmentToDelete.assignmentId,
            assignment,
            assignmentConflictData: conflictingData.assignmentConflictData,
          };
        } else {
          return {
            assignmentId: assignmentToDelete.assignmentId,
            assignment,
            assignmentConflictData: [],
          };
        }
      });

    if (
      project.number !== '%23%241ckn3%24%24' &&
      project.number !== '%23V@c@710n'
    ) {
      if (allocatedTime !== 0) {
        newTTA = project.timeToAllocate + allocatedTime;
      }
    }

    try {
      // TODO 13.08.20 frederik : remove the side effects of this two variables, no time yet
      const returnHistoryIds: string[] = [];
      const returnHistoryObjs: {}[] = [];

      // With more data at once (even in the batch write limit of 500 operations) firebase returns an permission error
      const chunkAssignments = this.helper.getArrayChunks(assignmentsAndConflictsToDelete, 5);

      await Promise.all(chunkAssignments.map(async assignmentsAndConflicts => {
        await this.updateData(assignmentsAndConflicts, assignmentIdsToDelete, clientId, usersEmployeeId, usersEmployeeName,
          returnHistoryIds, returnHistoryObjs, errorCB, succedCB, redoHistoryIds, undoHistoryIds);

        const conflictedAssignmentIds: string[] = assignmentsAndConflicts
          .map(item => item.assignmentConflictData.filter(data => data.value === true).map(data => data.id))
          .reduce((previousValue, currentValue) => [
            ...previousValue,
            ...currentValue,
          ], []);

        await this.helper.updateConflictsForAssignments(clientId, conflictedAssignmentIds);
      }));

      if (!isNaN(newTTA)) {
        const projectDoc = this.afs.firestore
          .doc('/clients/' + clientId + '/projects/' + projectId);
        await projectDoc.update({timeToAllocate: newTTA});
      }

      succedCB(assignmentIdsToDelete, returnHistoryObjs, returnHistoryIds);
    } catch (error) {
      /* ec */
      console.error('Error792575', error);
      errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
    }
  }

  updateData(assignmentsAndConflictsToDelete: DeleteAssignmentConflict[], assignmentIdsToDelete: string[], clientId: string,
             usersEmployeeId: string, usersEmployeeName: string, returnHistoryIds: string[], returnHistoryObjs: any[],
             errorCB: (arg0: string) => void, succedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void, redoHistoryIds?: string[],
             undoHistoryIds?: string[]) {

    const commitType: CommitType = this.helper.getCommitType(assignmentIdsToDelete, errorCB,
      'Error091823', 'Error129523', undoHistoryIds, redoHistoryIds);

    const batch = this.afs.firestore.batch();

    assignmentsAndConflictsToDelete.forEach((assignmentAndConflict: DeleteAssignmentConflict, i) => {
      const assignmentId = assignmentAndConflict.assignmentId;

      const assignmentDoc = this.afs.firestore
        .doc('/clients/' + clientId + '/assignments/' + assignmentId);
      batch.delete(assignmentDoc);

      if (commitType !== CommitType.UNDO) {
        const historyObj: {} = {
          changerId: usersEmployeeId,
          changerName: usersEmployeeName,
          changeTyp: 'removed',
          changeTime: FieldValue.serverTimestamp(),
        };

        let historyId: string;
        if (commitType === CommitType.NORMAL) {
          historyId = this.helper.generatePushId();
        } else {
          historyId = redoHistoryIds[i];
        }

        returnHistoryIds.push(historyId);
        returnHistoryObjs.push(historyObj);

        const assignmentHistDoc = this.afs.firestore
          .doc(
            '/clients/' +
            clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId,
          );
        batch.set(assignmentHistDoc, historyObj);
      } else {
        const historyId = undoHistoryIds[i];

        returnHistoryIds.push(historyId);
        returnHistoryObjs.push(null);

        const assignmentHistDoc = this.afs.firestore
          .doc(
            '/clients/' +
            clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId,
          );
        batch.delete(assignmentHistDoc);
      }

      const assignmentConflictsDoc = this.afs.firestore
        .doc('/clients/' + clientId + '/conflicts/' + assignmentId);
      batch.delete(assignmentConflictsDoc);

      /***
       * Deletes the back reference from the other conflicted assignments
       * We update the "Assignment"-Document in "updateConflictsForAssignments"
       */
      const conflictingAssignmentIds = assignmentAndConflict.assignmentConflictData
        .map(item => item.id)
        // filter already delete assignment Ids -> can happen with one project set multiple times on one day
        .filter(conflictAssignmentId => !assignmentIdsToDelete.includes(conflictAssignmentId));

      conflictingAssignmentIds.forEach(conflictAssignmentId => {
        const tempConfDoc = this.afs.firestore
          .doc('/clients/' + clientId + '/conflicts/' + conflictAssignmentId);
        batch.update(tempConfDoc, assignmentId, firebase.firestore.FieldValue.delete());
      });
    });

    return batch.commit();
  }
}
