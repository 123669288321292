import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export enum SCREEN_SIZE {
  XS,
  SM,
  MD,
  LG,
  XL,
}

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  get onResize$(): Observable<SCREEN_SIZE> {
    return this.resizeSubject.pipe(distinctUntilChanged());
  }

  get isPortrait$(): Observable<boolean> {
    return this.portraitSubject.pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<SCREEN_SIZE>;
  private portraitSubject: Subject<boolean>;

  constructor() {
    this.resizeSubject = new ReplaySubject(1);
    this.portraitSubject = new ReplaySubject(1);
  }

  onResize(size: SCREEN_SIZE) {
    this.resizeSubject.next(size);
  }

  isMobile(): Observable<boolean> {
    return this.onResize$.pipe(
      map(value => {
        switch (value) {
          case SCREEN_SIZE.XS:
          case SCREEN_SIZE.SM:
          case SCREEN_SIZE.MD:
            return true;
          default:
            return false;
        }
      }),
    );
  }

  calculatePortrait(width: number, height: number) {
    const isPortrait: boolean = (height >= width);
    this.portraitSubject.next(isPortrait);
  }
}
