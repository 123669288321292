const fsTestDB = false;

export const environment = {
  production: true,
  fs_conf:
    {
      apiKey:            (!fsTestDB) ? 'AIzaSyC-U-6Yv9AFKGEaWuBNF_IyKcs-IFAMluA'  : 'AIzaSyAGe323_6lCH_uolfh-MikFrnlEL_xyHmg',
      authDomain:        (!fsTestDB) ? 'filitime-7f7d6.firebaseapp.com'           : 'filitime-tdb-16f0b.firebaseapp.com',
      databaseURL:       (!fsTestDB) ? 'https://filitime-7f7d6.firebaseio.com'    : 'https://filitime-tdb-16f0b.firebaseio.com',
      projectId:         (!fsTestDB) ? 'filitime-7f7d6'                           : 'filitime-tdb-16f0b',
      storageBucket:     (!fsTestDB) ? 'filitime-7f7d6.appspot.com'               : 'filitime-tdb-16f0b.appspot.com',
      messagingSenderId: (!fsTestDB) ? '451434445923'                             : '564627884886',
    }
};
