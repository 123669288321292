import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SizeDetectorComponent } from './size-detector.component';


@NgModule({
  declarations: [SizeDetectorComponent],
  imports: [
    CommonModule,
  ],
  exports: [SizeDetectorComponent],
})
export class SizeDetectorModule { }
