import { Injectable } from '@angular/core';
import { Ci18n } from '../ci18n';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { HelperService } from './helper.service';
import * as firebase from 'firebase/app';
import { Project } from '../model/project.model';
import {
  Assignment,
  AssignmentAndHistory,
  AssignmentHistory,
  BatchResult,
  CommitType,
  SetAssignmentHelper,
} from '../model/assignment.model';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: 'root',
})
export class SetAssignmentsService {

  constructor(private afs: AngularFirestore, private helper: HelperService) {
  }

  async setMultipleAssignments(
    assignmentIds: string[],
    employeeName: string,
    projectName: string,
    startTSEndTSArray: number[][],
    notes: string[],
    reserved: boolean[],
    errorCB: (err: string) => void,
    succedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void,
    employeeId: string,
    projectId: string,
    project: Project,
    msPerMinute: number,
    usersEmployeeId: string,
    usersEmployeeName: string,
    msPerHour: number,
    clientId: string,
    undoHistoryIds?: string[],
    redoHistoryIds?: string[],
  ) {

    if (!employeeId) {
      /* ec */
      console.error('Error291332');
      return;
    }

    if (!project) {
      /* ec */
      console.error('Error731756');
      errorCB(Ci18n.translate('Projekt nicht gefunden'));
      return;
    }

    if (!projectId) {
      /* ec */
      console.error('Error792552');
      return;
    }

    const commitType: CommitType = this.helper.getCommitType(startTSEndTSArray, errorCB,
      'Error061291', 'Error160239', undoHistoryIds, redoHistoryIds);

    /***
     * Wenn es kein Normaler Commit ist, weil sich Undo oder Redo History IDs geändert haben,
     * dann werden die assignmentIds kopiert
     * ansonsten gelöscht
     */
    if (commitType === CommitType.NORMAL) {
      assignmentIds = [];
    } else if (assignmentIds.length !== startTSEndTSArray.length) {
      /* ec */
      console.error('Error031846');
      errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      return;
    }

    /***
     * Was passiert hier?
     * IF
     * -----
     * Wenn das Notes Array "null" oder "undefined" ist, dann wird ein leeres Array erzeugt
     * Danach wird für jeden Eintrag in "startTSEndTSArray" ein neuer Eintrag in die Notes gepushed
     * Alternative: notes = startTSEndTSArray.map(() => null);
     *
     * Else
     * -----
     * Wenn es nicht soviel Notes wie StartEnd Zeitpunkte gibt -> wird ein Fehler geworfen
     */
    if (!notes) {
      notes = [];
      startTSEndTSArray.forEach(() => {
        notes.push(null);
      });
    } else if (startTSEndTSArray.length !== notes.length) {
      /* ec */
      console.error('Error976872');
      errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      return;
    }

    if (!reserved) {
      reserved = [];
      startTSEndTSArray.forEach(() => {
        reserved.push(null);
      });
    } else if (startTSEndTSArray.length !== reserved.length) {
      /* ec */
      console.error('Error976872');
      errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
      return;
    }

    const assignments: SetAssignmentHelper[] = startTSEndTSArray.map((item, index) => {
      const assignmentId = commitType === CommitType.NORMAL ? this.helper.generatePushId() : assignmentIds[index];

      const undoHistoryId = !!undoHistoryIds && undoHistoryIds.length > index ? undoHistoryIds[index] : null;
      const redoHistoryId = !!redoHistoryIds && redoHistoryIds.length > index ? redoHistoryIds[index] : null;

      return {
        startTS: item[0],
        endTS: item[1],
        assignmentId,
        note: notes[index],
        cloudLink: notes[index],
        reserved: reserved[index],
        undoHistoryId,
        redoHistoryId,
      };
    });

    const chunkAssignments = this.helper.getArrayChunks(assignments, 5);

    const firstStart = this.helper.getLowestTimestamp(startTSEndTSArray);
    const lastEnd = this.helper.getHighestTimestamp(startTSEndTSArray);

    let allocatedTime = 0;

    const assignmentSnaps: DocumentChangeAction<Assignment>[] = await this.afs.collection<Assignment>(
      '/clients/' + clientId + '/assignments/',
      ref =>
        ref
          .where(
            'employeeId_start',
            '>=',
            employeeId + '_' + (firstStart - 24 * msPerHour),
          )
          .where(
            'employeeId_start',
            '<=',
            employeeId + '_' + (lastEnd + 24 * msPerHour),
          ),
    ).stateChanges().pipe(take(1)).toPromise();

    const allPromises: Promise<BatchResult>[] = chunkAssignments.map(async (assignmentChunk) => {

      /***
       * Es wird gerpüft, dass sich zwei aufeinander folgende Assignments nicht überschneiden
       */
      for (let i1 = 0; i1 < assignmentChunk.length - 1; i1++) {
        const element1 = assignmentChunk[i1];
        const startTS1 = element1.startTS;
        const endTS1 = element1.endTS;

        for (let i2 = i1 + 1; i2 < assignmentChunk.length; i2++) {
          if (i2 !== i1) {
            const element2 = assignmentChunk[i2];
            const startTS2 = element2.startTS;
            const endTS2 = element2.endTS;

            if (
              (startTS1 >= startTS2 && startTS1 < endTS2) ||
              (endTS1 > startTS2 && endTS1 <= endTS2) ||
              (startTS2 >= startTS1 && startTS2 < endTS1) ||
              (endTS2 > startTS1 && endTS2 <= endTS1)
            ) {
              /* ec */
              console.error('Error457986');
              errorCB(Ci18n.translate('Eingabe konnte nicht verarbeitet werden'));
              return;
            }
          }
        }
      }

      const result: AssignmentAndHistory[] = assignmentChunk.map(assignment => {
        const note = assignment.note;
        const reserved = assignment.reserved;
        let startTS = assignment.startTS;
        let endTS = assignment.endTS;

        if (startTS % msPerMinute !== 0) {
          startTS = Math.floor(startTS / msPerMinute) * msPerMinute;
          console.warn('startTS fixed');

          if (startTS % msPerMinute !== 0) {
            errorCB(Ci18n.translate('Start ungültig'));
            return;
          }
        }

        if (endTS % msPerMinute !== 0) {
          endTS = Math.floor(endTS / msPerMinute) * msPerMinute;
          console.warn('endTS fixed');

          if (endTS % msPerMinute !== 0) {
            errorCB(Ci18n.translate('Ende ungültig'));
            return;
          }
        }

        allocatedTime += endTS - startTS;

        const employeeId_projectId = employeeId + '_' + projectId;
        const employeeId_start = employeeId + '_' + startTS;
        const employeeId_end = employeeId + '_' + endTS;
        const projectId_start = projectId + '_' + startTS;
        const projectId_end = projectId + '_' + endTS;

        const assignmentObj = {
          employeeId,
          projectId,
          start: startTS,
          end: endTS,
          note: !note ? null : note,
          reserved: !reserved ? false : reserved,
          isConflicted: false,
          employeeId_projectId,
          employeeId_start,
          employeeId_end,
          projectId_start,
          projectId_end,
        };

        const historyObj = {
          changerId: usersEmployeeId,
          changerName: usersEmployeeName,
          changeTyp: 'added',
          changeTime: FieldValue.serverTimestamp(),
          object: assignmentObj,
        };

        return {
          assignment: assignmentObj,
          history: historyObj,
        };
      });

      const conflictedAssignmentIds: string[][] = this.checkConflicts(assignmentSnaps, result, errorCB);

      const assignmentObjs = result.map(item => item.assignment);
      const historyObjs = result.map(item => item.history);
      const assignmentIdsForChunk: string[] = assignmentChunk.map(item => item.assignmentId);
      const undoHistoryIdsForChunk: string[] = assignmentChunk.map(item => item.undoHistoryId);
      const redoHistoryIdsForChunk: string[] = assignmentChunk.map(item => item.redoHistoryId);

      return this.updateData(assignmentIdsForChunk, conflictedAssignmentIds, assignmentObjs, historyObjs, clientId,
        commitType, redoHistoryIdsForChunk, undoHistoryIdsForChunk, errorCB);

    });

    Promise.all(allPromises).then(async value => {

      const newTTA = project.number !== '%23%241ckn3%24%24' && project.number !== '%23V@c@710n'
        ? project.timeToAllocate - allocatedTime / msPerMinute : NaN;

      if (!isNaN(newTTA)) {
        const projectDoc = this.afs.firestore
          .doc('/clients/' + clientId + '/projects/' + projectId);
        await projectDoc.update({timeToAllocate: newTTA});
      }

      const returnAssignmentIds: string[] = value.map(item => item.assignmentIds).reduce((previousValue, currentValue) => ([
        ...currentValue,
        ...previousValue,
      ]), []);
      const returnHistoryObjs: AssignmentHistory[] = value.map(item => item.returnHistoryObjs).reduce((previousValue, currentValue) => ([
        ...currentValue,
        ...previousValue,
      ]), []);
      const returnHistoryIds: string[] = value.map(item => item.returnHistoryIds).reduce((previousValue, currentValue) => ([
        ...currentValue,
        ...previousValue,
      ]), []);

      succedCB(returnAssignmentIds, returnHistoryObjs, returnHistoryIds);
    });
  }

  private checkConflicts(assignmentSnaps: DocumentChangeAction<Assignment>[], assignmentAndHistories: AssignmentAndHistory[], errorCB: (err: string) => void): string[][] {
    return assignmentAndHistories.map((item: AssignmentAndHistory) => {
      if (assignmentSnaps.length === 0) {
        return [];
      } else {
        const conflictedWith: string[] = [];

        assignmentSnaps.forEach(assignmentSnap => {
          const tempAssignmentId: string = assignmentSnap.payload.doc.id;
          const tempAssignmentObj = assignmentSnap.payload.doc.data();

          if (!tempAssignmentObj.start) {
            /* ec */
            console.error('Error050529');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }

          if (!tempAssignmentObj.end) {
            /* ec */
            console.error('Error052851');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }

          const conflictFound = this.helper.checkIfAssignmentsAreConflictedByTS(
            item.assignment.start,
            item.assignment.end,
            tempAssignmentObj.start,
            tempAssignmentObj.end,
          );
          if (conflictFound) {
            conflictedWith.push(tempAssignmentId);
          }
        });

        return conflictedWith;
      }
    });
  }

  /***
   * hier werden die daten als "batch" geschrieben
   * also die 5 aus dieser Bearbeitungsrunde?
   */
  private updateData(assignmentIds: string[], conflictedAssignmentIds, assignmentObjs: Assignment[],
                     historyObjs: AssignmentHistory[], clientId, commitType: CommitType, redoHistoryIds,
                     undoHistoryIds, errorCB: (err: string) => void,
  ): Promise<BatchResult> {

    // *~§~*console.log('updateData');
    const returnHistoryIds: string[] = [];
    const returnHistoryObjs: AssignmentHistory[] = [];

    const batch = this.afs.firestore.batch();

    for (let i = 0; i < assignmentIds.length; i++) {
      const assignmentId = assignmentIds[i];
      const assignmentObj: Assignment = assignmentObjs[i];
      const historyObj: AssignmentHistory = historyObjs[i];
      const conflictedWith: string[] = conflictedAssignmentIds[i];

      const newConflictsObj: {} = {};

      if (conflictedWith.length > 0) {
        assignmentObj.isConflicted = true;
        historyObj.object.isConflicted = true;
      }

      const assignmentDoc = this.afs
        .firestore
        .doc('/clients/' + clientId + '/assignments/' + assignmentId);
      batch.set(assignmentDoc, assignmentObj);

      if (commitType !== CommitType.UNDO) {
        let historyId: string;
        if (commitType === CommitType.NORMAL) {
          historyId = this.helper.generatePushId();
        } else {
          historyId = redoHistoryIds[i];
        }
        returnHistoryIds.push(historyId);
        returnHistoryObjs.push(historyObj);

        const assignmentHistDoc = this.afs.firestore
          .doc(
            '/clients/' +
            clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId,
          );
        batch.set(assignmentHistDoc, historyObj);
      } else {
        const historyId = undoHistoryIds[i];
        const assignmentHistDoc = this.afs.firestore
          .doc(
            '/clients/' +
            clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId,
          );
        batch.delete(assignmentHistDoc);

        returnHistoryIds.push(historyId);
        returnHistoryObjs.push(null);
      }

      conflictedWith.forEach(tempAssId => {
        newConflictsObj[tempAssId] = true;

        const tempDoc1 = this.afs.firestore
          .doc('/clients/' + clientId + '/assignments/' + tempAssId);
        batch.update(tempDoc1, {isConflicted: true});

        const tempDoc2 = this.afs.firestore
          .doc('/clients/' + clientId + '/conflicts/' + tempAssId);
        const tempObj: {} = {};
        tempObj[assignmentId] = true;
        batch.update(tempDoc2, tempObj);
      });

      const assignmentConflictsDoc = this.afs.firestore
        .doc('/clients/' + clientId + '/conflicts/' + assignmentId);
      batch.set(assignmentConflictsDoc, newConflictsObj);
    }

    return batch
      .commit()
      .then(() => ({assignmentIds, returnHistoryObjs, returnHistoryIds}))
      .catch((error) => {
        console.error('Error525834' + ' | err: ' + error);
        errorCB(Ci18n.translate('Zuweisungen konnte nicht angelegt werden'));
        // TODO 29.07.20 frederik : evtl. müssen wir das anders lösen
        return {assignmentIds: [], returnHistoryObjs: [], returnHistoryIds: []};
      });
  }
}
