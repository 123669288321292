import { Access } from './access.model';

export class Employee {
  name: string;
  id?: string;
  number: string;
  dept: string|string[];
  group: string|string[];
  user: boolean;
  scheduler: boolean;
  selfEdit: boolean;
  blockedAt;
  accessesTo: Access[];
  accessesBy: Access[];
  otherInformation: string;
  ca?: boolean;
  inactive?: boolean;
}
