import { History } from './history.model';

export class Assignment {
  employeeId: string;
  projectId: string;
  start: number;
  end: number;
  note: string;
  reserved: boolean;
  employeeId_projectId: string;
  employeeId_start: string;
  employeeId_end: string;
  projectId_start: string;
  projectId_end: string;
  isConflicted: boolean;
  blockedAt?;
  fontColor?: string;
  backgroundColor?: string;
  reservedState?: boolean;
  marker?: string;
  numberOfProject?: string;
  projectName?: string;
  assignmentLeft?: number;
  assignmentWidth?: number;
  stateByIndex?: boolean;
  id?: string;
}

export interface SetAssignmentHelper {
  startTS: number;
  endTS: number;
  assignmentId: string;
  note: string;
  reserved: boolean;
  undoHistoryId?: string;
  redoHistoryId?: string;
}

export interface UpdateAssignmentHelper extends ConflictingAssignment {
  assignmentId: string;
  startTS: number;
  endTS: number;
  note: string;
  reserved: boolean;
  oldAssignment: Assignment;
  newConflictIds: string[];
  undoHistoryId?: string;
  redoHistoryId?: string;
}

export interface DeleteAssignmentHelper {
  assignmentId: string;
  assignment: Assignment;
}

export interface AssignmentAndHistory {
  assignment: Assignment;
  history: AssignmentHistory;
}

export interface BatchResult {
  assignmentIds: string[];
  returnHistoryObjs: AssignmentHistory[];
  returnHistoryIds: string[];
}

export interface AssignmentHistory extends History {
  object: Assignment;
}

export enum CommitType {
  NORMAL, UNDO, REDO,
}

export interface ConflictingAssignment {
  assignmentId: string;
  assignmentConflictData: { id: string, value: boolean }[];
}

export interface DeleteAssignmentConflict extends DeleteAssignmentHelper, ConflictingAssignment {

}
