import { Injectable } from '@angular/core';
import { Assignment, CommitType, ConflictingAssignment } from '../model/assignment.model';
import { Project } from '../model/project.model';
import { Ci18n } from '../ci18n';
import * as firebase from 'firebase/app';
import { take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class HelperService {

  constructor(private afs: AngularFirestore) { }

  getArrayChunks<T>(array: T[], chunkSize: number = 5): T[][] {
    return new Array(Math.ceil(array.length / chunkSize))
      .fill([])
      .map((value, index) => {
        const start = index * chunkSize;
        return array.slice(start, start + chunkSize);
      });
  }

  generatePushId(): string {
    let pushId = '';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 20; i++) {
      pushId += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    /*******************************
     chars.length = 62
     pushId.length = 20
     62^20 = 7.04423425547*10^35
     ******************************/

    return pushId;
  }

  checkIfAssignmentsAreConflictedByTS(
    startTS1: number,
    endTS1: number,
    startTS2: number,
    endTS2: number,
  ): boolean {
    return (startTS1 >= startTS2 && startTS1 < endTS2)
      || (endTS1 > startTS2 && endTS1 <= endTS2)
      || (startTS2 >= startTS1 && startTS2 < endTS1)
      || (endTS2 > startTS1 && endTS2 <= endTS1);
  }

  getAssignment(assignmentIds: string[], assignments: Assignment[], assignmentId: string): Assignment {

    const tempI = assignmentIds.indexOf(assignmentId);

    if (tempI === -1) {
      return null;
    }
    const assignment = assignments[tempI];

    if (!assignment) {
      return null;
    }

    return assignment;
  }

  copyAssignment(assignment: Assignment): Assignment {
    return {...assignment};
  }

  public getProject(projectIds: string[], projects: Project[], projectId: string): Project {
    const i = projectIds.indexOf(projectId);

    if (i !== -1) {
      return projects[i];
    }

    return null;
  }

  getCommitType<T>(inputArray: T[],
                   errorCB: (err: string) => void,
                   undoFailureCode: string,
                   redoFailureCode: string,
                   undoHistoryIds?: string[],
                   redoHistoryIds?: string[],
  ): CommitType {
    /***
     *  Prüfen, ob UndoHistoryIDs oder redoHistoryIds falls gesetzt, die gleiche Menge wie die Assignments sind
     */
    if (!!undoHistoryIds) {
      if (undoHistoryIds.length !== inputArray.length) {
        /* ec */
        console.error(undoFailureCode);
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      return CommitType.UNDO;
    } else if (!!redoHistoryIds) {
      if (redoHistoryIds.length !== inputArray.length) {
        /* ec */
        console.error(redoFailureCode);
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      return CommitType.REDO;
    } else {
      return CommitType.NORMAL;
    }

  }

  async loadAllConflictingAssignments(clientId: string, assignmentIds: string[]): Promise<ConflictingAssignment[]> {
    const idChunkOf10 = this.getArrayChunks(assignmentIds, 10);

    const allChunks: Promise<ConflictingAssignment[]>[] = idChunkOf10.map(async ids => {
      const result = await this.afs.collection<{ [key: string]: boolean }>(
        '/clients/' + clientId + '/conflicts/',
        ref => ref.where(firebase.firestore.FieldPath.documentId(), 'in', ids),
      ).snapshotChanges().pipe(take(1)).toPromise();

      const deleteConflictSnaps: ConflictingAssignment[] = result.map(snap => {

        const data = snap.payload.doc.data();
        const conflictingData: { id: string; value: boolean }[] = Object.keys(data).map(key => ({
          id: key,
          value: data[key],
        }));

        return {
          assignmentId: snap.payload.doc.id,
          assignmentConflictData: conflictingData,
        };
      });

      return deleteConflictSnaps;
    });

    return (await Promise.all(allChunks)).reduce((previousValue, currentValue) => [
      ...previousValue,
      ...currentValue,
    ], []);
  }

  async updateConflictsForAssignments(clientId: string, conflictedAssignmentIds: string[]) {
    const conflictingAssignments = await this.loadAllConflictingAssignments(clientId, conflictedAssignmentIds);

    const batch = this.afs.firestore.batch();

    await conflictingAssignments.filter(conflict => conflict.assignmentConflictData.length === 0).map(async conflict => {
      /***
       * this assignments have no conflict any more, so "isConflicted" in Assignment has to be set to false
       */
      const assignemntDoc = this.afs.firestore.doc('/clients/' + clientId + '/assignments/' + conflict.assignmentId);
      batch.update(assignemntDoc, {isConflicted: false});
    });

    await batch.commit();
  }

  getLowestTimestamp(startTsEndTsArray: number[][]) {
    const flattenTimestamps: number[] = startTsEndTsArray.reduce((previousValue, currentValue) => [
      ...previousValue,
      ...currentValue,
    ], []);
    return Math.min(...flattenTimestamps);
  }

  getHighestTimestamp(startTsEndTsArray: number[][]) {
    const flattenTimestamps: number[] = startTsEndTsArray.reduce((previousValue, currentValue) => [
      ...previousValue,
      ...currentValue,
    ], []);
    return Math.max(...flattenTimestamps);
  }

  validateCloudLink(cloudLink: string) {
    if(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(cloudLink) || cloudLink === '' || cloudLink == null){
      return true;
    } else {
      return false;
    }
  }

}
