import { History } from './history.model';

export class Project {
  number: string;
  name: string;
  duration: number;
  timeToAllocate: number;
  isConflicted: boolean;
  color: string;
  marker: string;
  note: string;
  cloudLink: string;
  reserved: boolean;
  blockedAt;
  finished: boolean;
  fontColor?: string;
  createdAt?: number;
  i?: number;
}

export interface ProjectHistory extends History {
  object: Project;
}
