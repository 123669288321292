import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {Dbi2Service} from './dbi2.service';
import {detect} from 'detect-browser';
import {Ci18n} from './ci18n';
import {VERSION} from '../environments/version';
import {MessageService} from 'primeng/api';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ResizeService} from './common/size-detector/resize.service';
import {UserAuthService} from './services/user-auth.service';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements OnInit, AfterViewInit {

  showNavBarReports = false;
  tabIndex = 0;
  menuEntries: { label: string, route: string[] }[] = [
    {label: Ci18n.translate('Plantafel'), route: ['']},
    {label: Ci18n.translate('Profil'), route: ['profile']},
    {label: Ci18n.translate("Abteilungen und Gruppen"), route: ["groups"]},
    {label: Ci18n.translate('Report'), route: ['report']},
  ];
  showMobileNavMenu = false;

  deviceIsMobile$: Observable<boolean>;

  showIsLoading = false;

  isLoggingIn = false;
  loginAborded = false;

  isLoggedIn = false;
  registerNewClient = false;
  resetPassword = false;
  errorMsg = '';
  emailValid = false;
  firstLogin = false;
  email = '';
  password = '';
  password2 = '';
  companyName = '';
  phoneNumber = '';
  registrant = '';
  private _browser = detect();
  private readonly msPerDay: number = 86400000;
  private reportMenuEntries: { label: string, route: any }[] = [
    // {label: Ci18n.translate('Zeit'), route: ['report/time']},
    {label: Ci18n.translate('Detaillierte Zeit'), route: ['']},
  ];

  constructor(private dbi: Dbi2Service, private messageService: MessageService, private router: Router,
              private resizeService: ResizeService, public userAuthService: UserAuthService) {
    console.log(`Application version is: version (from package.json)=${VERSION.version}, git-tag=${VERSION.tag}, git-hash=${VERSION.hash}`);
    this.dbi.loginStart.subscribe(() => {
      this.showIsLoading = true;
      this.isLoggingIn = true;
    });

    this.dbi.autoLogin.subscribe((mail) => {
      this.email = mail;
      this.password = '******';
    });

    this.dbi.loginSucced.subscribe(() => {
      this.showIsLoading = false;
      this.isLoggingIn = false;
      this.isLoggedIn = true;

      if(this.dbi.isUsersEmployeeAdmin() || this.dbi.isUsersEmployeeScheduler()){
        this.menuEntries = [
          {label: Ci18n.translate('Plantafel'), route: ['']},
          {label: Ci18n.translate('Profil'), route: ['profile']},
          {label: Ci18n.translate("Abteilungen und Gruppen"), route: ["groups"]},
          {label: Ci18n.translate('Report'), route: ['report']},
        ];
      }
      else this.menuEntries = [
        {label: Ci18n.translate('Plantafel'), route: ['']},
        {label: Ci18n.translate('Profil'), route: ['profile']},
        {label: Ci18n.translate('Report'), route: ['report']},
      ];

      this.checkLicense();
    });

    this.dbi.loginFailed.subscribe((msg) => {
      this.errorMsg = msg;
      this.isLoggingIn = false;
      this.showIsLoading = false;
    });

    this.dbi.logoutSucced.subscribe(() => {
      this.reset();
      if (this.loginAborded === true) {
        location.reload();
      }
    });
  }

  public isActive(base: string): boolean {
    if (base === '' && this.router.url === '/') {
      return true;
    } else if (base !== '') {
      return this.router.url.includes(`/${base}`);
    }
  }

  public translate(text: string): string {
    return Ci18n.translate(text);
  }

  public getTextByIndex(textI: number): string {
    return Ci18n.getTextByIndex(textI);
  }

  ngOnInit() {
    this.deviceIsMobile$ = this.resizeService.isMobile().pipe(startWith(false));
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.showNavBarReports = e.url.includes('report');
      }
    });
  }

  ngAfterViewInit() {
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event) {
    const keyboardEvent: KeyboardEvent = event;

    switch (keyboardEvent.keyCode) {
      case 27: // esc-key
        this.abordLoggin();
        break;
    }
  }

  logOut() {
    this.dbi.logout(
      () => {
      },
      (error) => {
        this.errorMsg = error; /*error.message;*/
      },
    );
  }

  continue() {
    if (this.registerNewClient) {
      this.register();
      return;
    }

    if (this.showIsLoading === true) {
      return;
    }
    this.showIsLoading = true;

    this.errorMsg = '';

    this.email = this.email.toLowerCase();
    this.email = this.email.replace(/\s/g, ''); // delete whitespaces

    if (this.email === undefined || this.email === null || this.email === '') {
      this.errorMsg = Ci18n.translate('Bitte gegeben Sie Ihre E-Mail-Adresse an');
      this.showIsLoading = false;
      return;
    }

    if (!this.firstLogin) {
      this.dbi.isEmailNewUser(
        this.email,
        (isNewUser) => {
          if (!isNewUser) {
            this.dbi.login(
              this.email,
              this.password,
            );
          } else {
            this.firstLogin = true;
            this.showIsLoading = false;
          }
        },
        error => {
          this.errorMsg = error;
          this.showIsLoading = false;
        });
    } else { // firstLogin === true
      if (!this.password || this.password === '' || !this.password2 || this.password2 === '') {
        this.errorMsg = Ci18n.translate('Bitte füllen Sie alle Felder aus');
        this.showIsLoading = false;
        return;
      }

      if (this.password.length < 6) {
        this.errorMsg = Ci18n.translate('Passwort muss mindestens 6 Zeichen enthalten');
        this.showIsLoading = false;
        return;
      }

      if (this.password !== this.password2) {
        this.errorMsg = this.translate('Passwörter stimmen nicht überein');
        this.showIsLoading = false;
        return;
      }

      this.dbi.setUpFirstUser(
        this.email,
        this.password,
        () => {
          this.showIsLoading = false;
        },
        (error) => {
          this.errorMsg = error;
          this.showIsLoading = false;
        });
    }
  }

  switchToRegisterUser() {
    this.registerNewClient = true;
    this.errorMsg = '';
  }

  switchToRegisterEmployee() {
    this.errorMsg = '';

    this.email = this.email.toLowerCase();
    this.email = this.email.replace(/\s/g, ''); // delete whitespaces

    if (this.email === undefined || this.email === null || this.email === '') {
      this.errorMsg = Ci18n.translate('Bitte gegeben Sie eine E-Mail-Adresse an, die einem Mitarbeiter zugewiesen wurde');
      return;
    }

    this.dbi.isEmailNewUser(
      this.email,
      (isNewUser) => {
        if (!isNewUser) {
          this.dbi.isMailListed(this.email, mailExists => {
            if (mailExists) {
              this.errorMsg = Ci18n.translate('E-Mail-Adresse ist bereits vergeben');
              return;
            }
            this.errorMsg = Ci18n.translate('Die angegebene E-Mail-Adresse wurde keinem Mitarbeiter zugewiesen');
          }, err => {
            this.errorMsg = err;
          });
        } else {
          this.firstLogin = true;
        }
      },
      error => {
        this.errorMsg = error;
      });
  }

  switchToResetPassword() {
    this.resetPassword = true;
    this.errorMsg = '';
  }

  switchToNormalLogin() {
    this.resetPassword = false;
    this.registerNewClient = false;
    this.firstLogin = false;
    this.errorMsg = '';
  }

  resetPW() {
    if (this.showIsLoading === true) {
      return;
    }
    this.showIsLoading = true;

    this.errorMsg = '';

    this.email = this.email.toLowerCase();
    this.email = this.email.replace(/\s/g, ''); // delete whitespaces

    if (this.email === undefined || this.email === null || this.email === '') {
      this.errorMsg = Ci18n.translate('Bitte gegeben Sie Ihre E-Mail-Adresse an');
      this.showIsLoading = false;
      return;
    }

    this.dbi.resetPassword(
      this.email,
      () => {
        this.errorMsg =
          Ci18n.translate('Das Rücksetzungsformular wurde an Ihre E-Mail-Adresse versendet. Bitte überprüfen Sie Ihr E-Mail-Postfach.');
        this.showIsLoading = false;
        return;
      },
      error => {
        if (error.code === 'auth/user-not-found') {
          this.errorMsg = Ci18n.translate('Unter der angegeben E-Mail-Adresse konnte kein Account gefunden werden.');
        } else if (error.code === 'auth/invalid-email') {
          this.errorMsg = Ci18n.translate('Das ist keine gültige E-Mail-Adresse');
        } else {
          this.errorMsg = Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten');
        }

        this.showIsLoading = false;
        return;
      });
  }

  getTabName(tabNo: number): string {
    if (tabNo >= this.menuEntries.length) {
      return '';
    }
    return this.menuEntries[tabNo].label;
  }

  async changeTab(tabNo: number, tab: { label: string, route: string[] }) {
    this.tabIndex = tabNo;
    this.showMobileNavMenu = false;
    await this.router.navigate(tab.route);
  }

  async goToProfileTab() {
    await this.changeTab(1, this.menuEntries[1]);
  }

  openMobileNavMenu() {
    this.showMobileNavMenu = true;
  }

  closeMobileNavMenu() {
    this.showMobileNavMenu = false;
  }

  public print() {
    window.print();
  }

  private reset() {
    this.isLoggingIn = false;
    this.isLoggedIn = false;
    this.registerNewClient = false;
    this.resetPassword = false;
    this.errorMsg = '';
    this.emailValid = false;
    this.firstLogin = false;
    this.email = '';
    this.password = '';
    this.password2 = '';
    this.companyName = '';
    this.phoneNumber = '';
    this.registrant = '';

    this.showIsLoading = false;
  }

  private abordLoggin() {
    if (this.isLoggingIn !== true) {
      return;
    }

    this.loginAborded = true;
    this.logOut();
  }

  private register() {
    if (this.showIsLoading === true) {
      return;
    }
    this.showIsLoading = true;

    this.errorMsg = '';

    this.email = this.email.toLowerCase();      // make mail lower case
    this.email = this.email.replace(/\s/g, ''); // delete whitespaces

    if (this.email === undefined || this.email === null || this.email === '') {
      this.errorMsg = Ci18n.translate('Bitte gegeben Sie Ihre E-Mail-Adresse an');
      this.showIsLoading = false;
      return;
    }

    if (this.password === undefined || this.password === null || this.password === ''
      || this.password2 === undefined || this.password2 === null || this.password2 === ''
      || this.companyName === undefined || this.companyName === null || this.companyName === ''
      || this.phoneNumber === undefined || this.phoneNumber === null || this.phoneNumber === ''
      || this.registrant === undefined || this.registrant === null || this.registrant === '') {
      this.errorMsg = Ci18n.translate('Bitte füllen Sie alle Felder aus');
      this.showIsLoading = false;
      return;
    }

    if (this.password.length < 6) {
      this.errorMsg = Ci18n.translate('Passwort muss mindestens 6 Zeichen enthalten');
      this.showIsLoading = false;
      return;
    }

    if (this.password !== this.password2) {
      this.errorMsg = Ci18n.translate('Passwörter stimmen nicht überein');
      this.showIsLoading = false;
      return;
    }

    this.dbi.setUpNewClient(
      this.email,
      this.password,
      this.companyName,
      this.phoneNumber,
      this.registrant,
      () => {
        this.isLoggedIn = true;
        this.showIsLoading = false;
      },
      error => {
        this.errorMsg = error;
        this.showIsLoading = false;
      });
  }

  private async checkLicense() {
    try {
      const sudoDocData = await this.dbi.getSudoDocData();
      const isCustomer = (sudoDocData.isCustomer === true || sudoDocData.isCustomer === 'soon');
      const clientInactive = sudoDocData.active !== true;

      if (isCustomer && !clientInactive) {
        return;
      } else if (clientInactive) {
        return this.goToProfileTab();
      }

      const warnTS = await this.dbi.getWarnMailTS();
      if (typeof warnTS === 'number' && warnTS !== -1) {
        const deltaTS = warnTS - Date.now();
        const deltaDays = Math.floor(deltaTS / this.msPerDay) + 6;
        const title = Ci18n.translate('Testversion');
        const detail = Ci18n.translate('Ihre Testversion ist noch ') + deltaDays + Ci18n.translate(' Tage gültig.');
        this.messageService.add({
          severity: 'warn',
          summary: title,
          detail,
        });
        return;
      }

      const deactivateTS = await this.dbi.getDeactivateClientTS();
      if (typeof deactivateTS !== 'number' || deactivateTS === -1) {
        return;
      } else {
        return this.goToProfileTab();
      }
    } catch (e) {
      setTimeout(() => {
        this.checkLicense();
      }, 3000);
    }
  }
}
