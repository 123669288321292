import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DepartmentsAndGroupsComponent} from "./departments-and-groups/departments-and-groups.component";

const routes: Routes = [
  {path: '', loadChildren: () => import('./project-calendar/project-calendar.module').then(m => m.ProjectCalendarModule)},
  {path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)},
  {path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule)},
  {path: 'groups', component: DepartmentsAndGroupsComponent},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
