import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {

  isAdmin$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(public afs: AngularFirestore) {
    firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser?.uid) {
        const userDoc = this.afs.collection('users').doc(currentUser.uid);
        const user = await userDoc.get().toPromise();
        const userEmployeeProfileDoc = this.afs.firestore.doc(user.data()["employeeProfiles"][0]);
        const snap = (await userEmployeeProfileDoc.get()).data();
        this.isAdmin$.next(snap.ca);
      }
    });
  }

  isUserAdmin(): ReplaySubject<boolean> {
    return this.isAdmin$;
  }
}
