import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { HelperService } from './helper.service';
import { Ci18n } from '../ci18n';
import { Assignment, CommitType, ConflictingAssignment, UpdateAssignmentHelper } from '../model/assignment.model';
import * as firebase from 'firebase/app';
import { Project } from '../model/project.model';
import { take } from 'rxjs/operators';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: 'root',
})
export class UpdateAssignmentService {

  constructor(private afs: AngularFirestore, private helper: HelperService) {
  }

  async updateMultipleAssignments(
    assignmentIds: string[],
    employeeId: string,
    projectId: string,
    startTSEndTSArray: number[][],
    notes: string[],
    reserved: boolean[],
    errorCB: (err: string) => void,
    succeedCB: (arg0?: string[], arg1?: any[], arg2?: string[]) => void,
    clientId: string,
    employeeName: string,
    project: Project,
    allAssignmentIds: string[],
    allAssignments: Assignment[],
    msPerMinute: number,
    msPerHour: number,
    usersEmployeeId: string,
    usersEmployeeName: string,
    undoHistoryIds?: string[],
    redoHistoryIds?: string[],
  ) {
    if (
      startTSEndTSArray.length !== assignmentIds.length ||
      notes.length !== assignmentIds.length ||
      reserved.length !== assignmentIds.length
    ) {
      errorCB(Ci18n.translate('fehlerhafte Eingabe'));
      return;
    }

    if (!employeeName) {
      /* ec */ console.error('Error442816');
               errorCB(Ci18n.translate('Mitarbeiter nicht gefunden'));
               return;
    }

    if (!project) {
      /* ec */ console.error('Error392896');
               errorCB(Ci18n.translate('Projekt nicht gefunden'));
               return;
    }

    for (let i1 = 0; i1 < startTSEndTSArray.length - 1; i1++) {
      const element1 = startTSEndTSArray[i1];
      const startTS1 = element1[0];
      const endTS1 = element1[1];

      for (let i2 = i1 + 1; i2 < startTSEndTSArray.length; i2++) {
        if (i2 !== i1) {
          const element2 = startTSEndTSArray[i2];
          const startTS2 = element2[0];
          const endTS2 = element2[1];

          if (
            this.helper.checkIfAssignmentsAreConflictedByTS(
              startTS1,
              endTS1,
              startTS2,
              endTS2,
            )
          ) {
            /* ec */
            console.error('Error977652');
            errorCB(Ci18n.translate('Zuweisungen dürfen sich nicht überschneiden'));
            return;
          }
        }
      }
    }

    const oldConflictAssignments: ConflictingAssignment[] = await this.helper.loadAllConflictingAssignments(clientId, assignmentIds);

    const firstStart = this.helper.getLowestTimestamp(startTSEndTSArray);
    const lastEnd = this.helper.getHighestTimestamp(startTSEndTSArray);

    const newConflictingAssignmentSnaps: DocumentChangeAction<Assignment>[] =
      await this.newConflictAssignments(clientId, employeeId, firstStart, lastEnd, msPerHour);

    const updateAssignments: UpdateAssignmentHelper[] = assignmentIds.map((assignmentId, index) => {
      const assignment = this.helper.getAssignment(allAssignmentIds, allAssignments, assignmentId);

      if (!assignment) {
        /* ec */
        console.error('Error968295');
        errorCB(Ci18n.translate('Zuweisung konnte nicht gefunden werden'));
        return;
      }

      if (assignment.projectId !== projectId) {
        /* ec */
        console.error('Error968295');
        errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
        return;
      }

      const startTS = startTSEndTSArray[index][0];
      const endTS = startTSEndTSArray[index][1];

      const oldConflictAssignment = oldConflictAssignments.find(item => item.assignmentId === assignmentId);
      const assignmentConflictData = !!oldConflictAssignment ? oldConflictAssignment.assignmentConflictData : [];
      const newConflictIds = this.calculateNewConflictIds(assignmentIds, startTS, endTS, newConflictingAssignmentSnaps, errorCB);

      const undoHistoryId = !!undoHistoryIds && undoHistoryIds.length > index ? undoHistoryIds[index] : null;
      const redoHistoryId = !!redoHistoryIds && redoHistoryIds.length > index ? redoHistoryIds[index] : null;

      return {
        assignmentId,
        startTS,
        endTS,
        note: notes[index],
        reserved: reserved[index],
        oldAssignment: assignment,
        assignmentConflictData,
        newConflictIds,
        undoHistoryId,
        redoHistoryId,
      };
    });

    const returnHistoryIds: string[] = [];
    const returnHistoryObjs: {}[] = [];

    let newTTA: number;

    if (
      project.number !== '%23%241ckn3%24%24' &&
      project.number !== '%23V@c@710n'
    ) {
      let durationDiff = 0;

      updateAssignments.forEach(updateAssignment => {
        const oldAssignment = updateAssignment.oldAssignment;
        let startTS = updateAssignment.startTS;
        let endTS = updateAssignment.endTS;

        if (startTS % msPerMinute !== 0) {
          startTS = Math.floor(startTS / msPerMinute) * msPerMinute;
          console.warn('startTS fixed');

          if (startTS % msPerMinute !== 0) {
            errorCB(Ci18n.translate('Start ungültig'));
            return;
          }
        }

        if (endTS % msPerMinute !== 0) {
          endTS = Math.floor(endTS / msPerMinute) * msPerMinute;
          console.warn('endTS fixed');

          if (endTS % msPerMinute !== 0) {
            errorCB(Ci18n.translate('Ende ungültig'));
            return;
          }
        }

        const assignmentDurationOld: number =
          oldAssignment.end - oldAssignment.start;
        const assignmentDurationNew: number = endTS - startTS;
        durationDiff += assignmentDurationOld - assignmentDurationNew;
      });

      if (durationDiff !== 0) {
        newTTA = project.timeToAllocate + durationDiff / msPerMinute;

        if (newTTA % 1 !== 0) {
          newTTA = Math.round(newTTA);
        }
      }
    }

    try {
      const commitType: CommitType = this.helper.getCommitType(startTSEndTSArray, errorCB,
        'Error026351', 'Error012659', undoHistoryIds, redoHistoryIds);

      const chunkUpdateAssignments = this.helper.getArrayChunks(updateAssignments, 5);

      await Promise.all(chunkUpdateAssignments.map(async assignmentAndConflicts => {
        await this.updateData(assignmentAndConflicts, assignmentIds, employeeId, projectId, usersEmployeeId, usersEmployeeName,
          clientId, commitType, returnHistoryIds, returnHistoryObjs);

        const conflictedAssignmentIds: string[] = assignmentAndConflicts
          .map(item => item.assignmentConflictData.filter(data => data.value === true).map(data => data.id))
          .reduce((previousValue, currentValue) => [
            ...previousValue,
            ...currentValue,
          ], []);

        await this.helper.updateConflictsForAssignments(clientId, conflictedAssignmentIds);
      }));

      if (newTTA !== undefined) {
        const projectDoc = firebase
          .firestore()
          .doc('/clients/' + clientId + '/projects/' + projectId);

        await projectDoc.update({
          timeToAllocate: newTTA,
        });
      }


      succeedCB(assignmentIds, returnHistoryObjs, returnHistoryIds);
    } catch (e) {
      console.log(e);
      errorCB(Ci18n.translate('Zuweisungen konnten nicht aktualisiert werden'));
    }
  }

  // siehe delete-assignment.service.ts
  private updateData(updateAssignments: UpdateAssignmentHelper[], assignmentIds: string[], employeeId, projectId,
                     usersEmployeeId, usersEmployeeName, clientId, commitType, returnHistoryIds, returnHistoryObjs) {
    const batch = this.afs.firestore.batch();

    const conflictsToUpdateIds: string[] = [];
    const conflictsToUpdateObj: {}[] = [];
    const assignmentsToUpdateObj: {
      isConflicted: boolean;
    }[] = [];

    updateAssignments.forEach(updateAssignment => {
      const assignmentId = updateAssignment.assignmentId;
      const newConfAssIds = updateAssignment.newConflictIds;
      const startTS: number = updateAssignment.startTS;
      const endTS: number = updateAssignment.endTS;
      const isConflicted = !!newConfAssIds ? newConfAssIds.length > 0 : false;

      let note = updateAssignment.note;
      let reserved = updateAssignment.reserved;
      note = !note ? null : note;
      reserved = !reserved ? false : reserved;

      const assignmentObject = {
        employeeId,
        projectId,
        start: startTS,
        end: endTS,
        note,
        reserved,
        isConflicted,
        employeeId_projectId: employeeId + '_' + projectId,
        employeeId_start: employeeId + '_' + startTS,
        employeeId_end: employeeId + '_' + endTS,
        projectId_start: projectId + '_' + startTS,
        projectId_end: projectId + '_' + endTS,
      };

      const historyObj: {} = {
        changerId: usersEmployeeId,
        changerName: usersEmployeeName,
        changeTyp: 'updated',
        changeTime: FieldValue.serverTimestamp(),
        object: assignmentObject,
      };

      // const updatedConflictObj: {} = {};
      let historyId;

      const assignmentDoc = firebase
        .firestore()
        .doc('/clients/' + clientId + '/assignments/' + assignmentId);
      batch.set(assignmentDoc, assignmentObject);

      if (commitType !== CommitType.UNDO) {
        if (commitType === CommitType.NORMAL) {
          historyId = this.helper.generatePushId();
        } else {
          historyId = updateAssignment.redoHistoryId;
        }
        returnHistoryIds.push(historyId);
        returnHistoryObjs.push(historyObj);

        const assignmentHistDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId,
          );
        batch.set(assignmentHistDoc, historyObj);
      } else {
        historyId = updateAssignment.undoHistoryId;
        const assignmentHistDoc = firebase
          .firestore()
          .doc(
            '/clients/' +
            clientId +
            '/history/assignments/' +
            assignmentId +
            '/' +
            historyId,
          );
        batch.delete(assignmentHistDoc);

        returnHistoryIds.push(historyId);
        returnHistoryObjs.push(null);
      }

      /***
       * Deletes the back reference from the other conflicted assignments
       * We update the "Assignment"-Document in "updateConflictsForAssignments"
       */
      const oldConflictingAssignmentIds = updateAssignment.assignmentConflictData
        .map(item => item.id)
        // filter already delete assignment Ids -> can happen with one project set multiple times on one day
        .filter(conflictAssignmentId => !assignmentIds.includes(conflictAssignmentId));

      oldConflictingAssignmentIds.forEach(conflictAssignmentId => {
        // remove conflict from other assignment
        const oldConflictDoc = this.afs.firestore
          .doc('/clients/' + clientId + '/conflicts/' + conflictAssignmentId);
        batch.update(oldConflictDoc, assignmentId, firebase.firestore.FieldValue.delete());

        // remove conflict from this assignment
        const assignmentConfDoc = this.afs.firestore
          .doc('/clients/' + clientId + '/conflicts/' + assignmentId);
        batch.update(assignmentConfDoc, conflictAssignmentId, firebase.firestore.FieldValue.delete());
      });

      newConfAssIds.forEach(newConfAssId => {

        // add new conflict to this assignment
        const assignmentConfDoc = this.afs.firestore
          .doc('/clients/' + clientId + '/conflicts/' + assignmentId);
        batch.update(assignmentConfDoc, newConfAssId, true);

        // add new conflict to the other assignment
        if (conflictsToUpdateIds.indexOf(newConfAssId) === -1) {
          const tempObj = {};
          tempObj[assignmentId] = true;

          conflictsToUpdateIds.push(newConfAssId);
          conflictsToUpdateObj.push(tempObj);
          assignmentsToUpdateObj.push({isConflicted: true});
        } else {
          const tempI = conflictsToUpdateIds.indexOf(newConfAssId);
          conflictsToUpdateObj[tempI][assignmentId] = true;
          assignmentsToUpdateObj[tempI].isConflicted = true;
        }
      });
    });


    for (let i = 0; i < conflictsToUpdateIds.length; i++) {
      const tempAssId = conflictsToUpdateIds[i];
      const tempConfObj = conflictsToUpdateObj[i];
      const tempAssObj = assignmentsToUpdateObj[i];

      const assignmentDoc = firebase
        .firestore()
        .doc('/clients/' + clientId + '/assignments/' + tempAssId);
      batch.update(assignmentDoc, tempAssObj);

      const assignmentConflictsDoc = firebase
        .firestore()
        .doc('/clients/' + clientId + '/conflicts/' + tempAssId);
      batch.update(assignmentConflictsDoc, tempConfObj);
    }

    return batch.commit();
  }

  private async newConflictAssignments(clientId, employeeId, firstStart, lastEnd, msPerHour): Promise<DocumentChangeAction<Assignment>[]> {
    return await this.afs.collection<Assignment>(
      '/clients/' + clientId + '/assignments/',
      ref =>
        ref
          .where(
            'employeeId_start',
            '>=',
            employeeId + '_' + (firstStart - 24 * msPerHour),
          )
          .where(
            'employeeId_start',
            '<=',
            employeeId + '_' + (lastEnd + 24 * msPerHour),
          ),
    ).stateChanges().pipe(take(1)).toPromise();
  }

  private calculateNewConflictIds(allAssignmentIds: string[], startTS: number, endTS: number,
                                  assignmentSnaps: DocumentChangeAction<Assignment>[], errorCB: (err: string) => void): string[] {

    if (assignmentSnaps.length === 0) {
      return [];
    } else {

      const newConfAssIds: string[] = [];
      assignmentSnaps.forEach(assignmentSnap => {
        const tempAssignmentId: string = assignmentSnap.payload.doc.id;
        const tempAssignmentObj = assignmentSnap.payload.doc.data();

        if (allAssignmentIds.includes(tempAssignmentId) === false) {
          // this assignment is part of the assignments to update...
          // if not ignored false conflicts can be created, because of comparing old and new TSs

          if (!tempAssignmentObj.start) {
            /* ec */
            console.error('Error985546');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }

          if (!tempAssignmentObj.end) {
            /* ec */
            console.error('Error477853');
            errorCB(Ci18n.translate('Ein unerwarteter Fehler ist aufgetreten'));
          }

          const conflictFound = this.helper.checkIfAssignmentsAreConflictedByTS(
            startTS,
            endTS,
            tempAssignmentObj.start,
            tempAssignmentObj.end,
          );
          if (conflictFound) {
            newConfAssIds.push(tempAssignmentId);
          }
        }
      });
      return newConfAssIds;
    }
  }
}
