import { AfterViewInit, Component, ElementRef, HostListener } from '@angular/core';
import { ResizeService, SCREEN_SIZE } from './resize.service';

// https://www.digitalocean.com/community/tutorials/detect-responsive-screen-sizes-in-angular

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.css'],
})
export class SizeDetectorComponent implements AfterViewInit {

  prefix = 'is-';
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', css: `d-block d-sm-none`,
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', css: `d-none d-sm-block d-md-none`,
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', css: `d-none d-md-block d-lg-none`,
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', css: `d-none d-lg-block d-xl-none`,
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl', css: `d-none d-xl-block`,
    },
  ];

  constructor(private elementRef: ElementRef, private resizeService: ResizeService) { }

  ngAfterViewInit() {
    this.detectScreenSize();
    this.calculatePortrait(window);
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.detectScreenSize();
    const target = event.target;
    this.calculatePortrait(target);
  }

  private detectScreenSize() {
    const currentSize = this.sizes.find(x => {
      // get the HTML element
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);

      // check its display property value
      return window.getComputedStyle(el).display !== 'none';
    });

    this.resizeService.onResize(currentSize.id);
  }

  private calculatePortrait(window: Window) {
    const height = window.innerWidth > window.innerHeight ? document.documentElement.clientHeight : window.innerHeight;
    this.resizeService.calculatePortrait(window.innerWidth, height);

    // if iphone calculate new height after change to portrait mode
    if (window.innerWidth < window.innerHeight && this.isIOS()) {
      setTimeout(() => {
        document.getElementById('ll1_body')?.setAttribute('style', 'height:100vh');
        document.getElementById('ll2_calendar')?.setAttribute('style', 'height: calc(100% - 3em)');
        document.getElementsByTagName('body')[0]?.setAttribute('style', 'height:100vh');
        document.getElementsByTagName('html')[0]?.setAttribute('style', 'height:100vh');
      }, 500);
    }
  }

  private isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
}
